var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": _vm.isWithoutMarginBottom ? '0' : ['16px', '24px'],
      "is-read-only": _vm.isReadOnly,
      "w": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [_c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isDisabled ? '#888' : _vm.isInvalid ? '#D32737' : _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "opacity": _vm.isDisabled ? '1' : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1), _c('vue-timepicker', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'input.display-time': {
          p: '1rem !important',
          height: _vm.getSize.vueTimepicker.height,
          fontSize: _vm.getSize.vueTimepicker.fontSize,
          fontFamily: 'Roboto',
          fontWeight: '500',
          borderRadius: '8px',
          border: '1px solid #888',
          boxShadow: '0 0 0 1px #888'
        },
        '.controls>*': {
          cursor: _vm.isDisabled ? 'not-allowed !important' : 'pointer',
          userSelect: _vm.isDisabled ? 'none !important' : 'all'
        },
        'input:disabled': {
          backgroundColor: '#F2F2F2 !important',
          cursor: 'not-allowed !important'
        }
      },
      expression: "{\n      'input.display-time': {\n        p: '1rem !important',\n        height: getSize.vueTimepicker.height,\n        fontSize: getSize.vueTimepicker.fontSize,\n        fontFamily: 'Roboto',\n        fontWeight: '500',\n        borderRadius: '8px',\n        border: '1px solid #888',\n        boxShadow: '0 0 0 1px #888',\n      },\n      '.controls>*': {\n        cursor: isDisabled ? 'not-allowed !important' : 'pointer',\n        userSelect: isDisabled ? 'none !important' : 'all',\n      },\n      'input:disabled': {\n        backgroundColor: '#F2F2F2 !important',\n        cursor: 'not-allowed !important',\n      },\n    }"
    }],
    ref: "formTime",
    class: _vm.isDisabled ? 'disabled' : '',
    attrs: {
      "format": "HH:mm",
      "minute-interval": 1,
      "hour-label": "Jam",
      "minute-label": "Menit",
      "input-width": "100%",
      "close-on-complete": true,
      "hide-clear-button": false,
      "drop-direction": "up",
      "container-id": "formTime",
      "disabled": _vm.isDisabled,
      "input-class": ['skip-error-style', _vm.isInvalid ? 'invalid' : ''],
      "color": _vm.isDisabled ? '#888888' : _vm.modelValue ? '#008C81' : '#555555',
      "background-color": _vm.isDisabled ? 'neutral.superLightGray' : 'white',
      "fixed-dropdown-button": "",
      "lazy": ""
    },
    on: {
      "blur": _vm.onBlur
    },
    scopedSlots: _vm._u([{
      key: "dropdownButton",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "align": "center"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-clock.svg'),
            "height": _vm.getSize.trailingIcon.height,
            "width": _vm.getSize.trailingIcon.width,
            "fill": "#888"
          }
        })], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.helperTextColor
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": _vm.hasHelperTextClick ? 'pointer' : 'auto'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('helper-text-click');
      }
    }
  }, [_vm.helperTextLogo ? _c('c-box', {
    attrs: {
      "min-height": ['13px', '16px'],
      "min-width": ['13px', '16px'],
      "height": ['13px', '16px'],
      "width": ['13px', '16px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.helperTextLogo,
      "height": "100%",
      "width": "100%",
      "fill": _vm.helperTextColor
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.helperText) + " ")], 1)], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey,
      attrs: {
        "d": "flex",
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-height": ['13px', '16px'],
        "min-width": ['13px', '16px'],
        "height": ['13px', '16px'],
        "width": ['13px', '16px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-warn.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#D32737"
      }
    })], 1), _vm._v(" " + _vm._s(invalidTextValue) + " ")], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }