<template>
  <c-form-control
    :width="width"
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :mb="isWithoutMarginBottom ? '0' : ['12px', '16px']"
  >
    <c-form-label
      v-show="!isWithoutLabel"
      :font-size="['14px', '16px']"
      :color="isDisabled ? '#888' : (isInvalid ? '#D32737' : (value ? '#008C81' : '#555'))"
      font-weight="400"
      font-family="Roboto"
      :opacity="isDisabled ? '1' : ''"
    >
      {{ label }}
    </c-form-label>
    <c-select
      v-model="modelValue"
      :height="getSize.input.height"
      :font-size="getSize.input.fontSize"
      :placeholder="placeholder ? placeholder : label"
      :color="value ? (isInvalid ? '#D32737' : '#008C81') : '#555'"
      :font-weight="value ? '500' : '400'"
      border-radius="8px"
    >
      <option
        v-if="options.length === 0 && modelValue"
        :key="modelValue"
        :value="modelValue"
      >
        {{ modelValue }}
      </option>
      <option
        v-for="option in options"
        :key="option.value"
        :value="option.value"
      >
        {{ option.label }}
      </option>
    </c-select>
    <c-form-control
      v-if="isOther && (modelValue == otherOption)"
      :is-invalid="otherValue ? false : true"
      :mt="['12px', '16px']"
    >
      <c-input
        v-model="otherValue"
        type="text"
        :height="getSize.input.height"
        :font-size="getSize.input.fontSize"
        :color="otherValue ? '#008C81' : '#555'"
        :font-weight="otherValue ? '500' : '400'"
        placeholder="Lainnnya"
        mb="8px"
        border-radius="8px"
      />
    </c-form-control>
    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      d="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
        d="flex"
        align-items="center"
        gap="8px"
      >
        <c-box
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-circle-warn.svg')"
            height="100%"
            width="100%"
            fill="#D32737"
          />
        </c-box>
        {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'FormSelect2',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      required: true,
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      type: [String, Number],
      default: '',
    },
    options: {
      type: Array,
      default: () => ([]),
    },
    isOther: {
      type: Boolean,
      default: false,
    },
    other: {
      type: [String, null],
      default: null,
    },
    otherOption: {
      type: [String, null],
      default: null,
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isWithoutLabel: {
      type: Boolean,
      default: false,
    },
    width: {
      type: [String, Array],
      default: '',
    },
    size: {
      type: String,
      default: 'lg',
    },
  },
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    getSize() {
      switch (this.size) {
        case 'md':
          return {
            input: {
              height: ['36px', '50px'],
              fontSize: ['14px', '16px'],
            },
          }
        case 'lg':
        default:
          return {
            input: {
              height: ['48px', '62px'],
              fontSize: ['14px', '18px'],
            },
          }
      }
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        this.$emit('blur')
      },
    },
    otherValue: {
      get() {
        return this.other
      },
      set(val) {
        this.$emit('updateOther', val)
      },
    },
  },
  methods: {
    isDataEmpty,
    isIncludesValue(value) {
      return this.options.some((it) => it.value == value)
    },
  }, 
}
</script>

<style scoped>
::v-deep select {
  box-shadow: 0 0 0 1px #888;
  border-color: #888;
}

::v-deep select[disabled] {
  opacity: 1 !important;
  background-color: #F2F2F2 !important;
  color: #888 !important;
  border-color: #888 !important;
  box-shadow: 0 0 0 1px #888 !important;
  cursor: not-allowed;
}
</style>