<template>
  <c-form-control
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="isWithoutMarginBottom ? '0' : ['16px', '24px']"
    :is-read-only="isReadOnly"
    :width="fullWidth ? '100%' : 'inherit'"
  >
    <c-form-label
      v-if="!!label"
      :font-size="['14px', '16px']"
      :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
      font-weight="400"
      font-family="Roboto"
      padding-left="8px"
    >
      {{ label }}
      <c-box
        v-if="isRequired !== null && !isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>

    <c-input-group>
      <c-input-left-addon
        v-if="hasInputLeftAddon"
        border-top-left-radius="8px"
        border-bottom-left-radius="8px"
        :height="['48px', '62px']"
        background-color="#F2F2F2"
        :font-size="['14px', '18px']"
        :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
        font-weight="500"
        :opacity="isDisabled ? '0.4' : '1'"
      >
        {{ inputLeftAddon }}
      </c-input-left-addon>
      <c-box
        position="relative"
        :width="fullWidth ? '100%' : 'inherit'"
      >
        <c-input-left-element
          v-if="hasInputLeftIcon"
          v-chakra="{
            height: ['48px', '62px'],
            fontSize: ['14px', '18px'],
            color: value ? '#008C81' : '#555',
            fontWeight: value ? '500' : '400',
          }"
        >
          <slot name="input-left-icon-element" />
          {{ iconLeftElement ? "" : leftElement }}
          <c-box
            v-if="iconLeftElement"
            :height="['20px', '25px']"
            :width="['20px', '25px']"
            :min-height="['20px', '25px']"
            :min-width="['20px', '25px']"
          >
            <inline-svg
              :src="iconLeftElement"
              height="100%"
              width="100%"
              :fill="iconLeftElementColor"
            />
          </c-box>
        </c-input-left-element>
        <c-input
          v-model="modelValue"
          type="text"
          :height="['48px', '62px']"
          :font-size="['14px', '18px']"
          :color="isInvalid ? 'danger.400' : 'primary.400'"
          font-weight="500"
          :placeholder="placeholder ? placeholder : label"
          :border-top-left-radius="hasInputLeftAddon ? '0px' : '8px'"
          :border-bottom-left-radius="hasInputLeftAddon ? '0px' : '8px'"
          :border-top-right-radius="hasInputRightAddon ? '0px' : '8px'"
          :border-bottom-right-radius="hasInputRightAddon ? '0px' : '8px'"
          focus-border-color="primary.400"
          padding-block="0"
          :padding-left="hasInputLeftIcon ? '50px' : '20px'"
          :padding-right="hasInputRightIcon ? '50px' : '20px'"
          :text-align="isCentered ? 'center' : 'left'"
          @blur="onBlur"
          @focus="$emit('focus', $event)"
        />
        <c-input-right-element
          v-if="hasInputRightIcon"
          v-chakra="{
            height: ['48px', '62px'],
            fontSize: ['14px', '18px'],
            color: value ? '#008C81' : '#555',
            fontWeight: value ? '500' : '400',
          }"
        >
          <slot name="input-right-icon-element" />
          {{ iconRightElement ? "" : rightElement }}
          <c-text
            v-if="iconRightElement"
            as="span"
            :height="['20px', '25px']"
            :width="['20px', '25px']"
            :min-height="['20px', '25px']"
            :min-width="['20px', '25px']"
          >
            <inline-svg
              v-if="iconRightElement"
              :src="iconRightElement"
              height="100%"
              width="100%"
              fill="#323232"
            />
          </c-text>
        </c-input-right-element>
      </c-box>
      <c-input-right-addon
        v-if="hasInputRightAddon"
        border-top-right-radius="8px"
        border-bottom-right-radius="8px"
        :height="['48px', '62px']"
        background-color="#F2F2F2"
        :font-size="['14px', '18px']"
        :color="isInvalid ? 'danger.400' : value ? 'primary.400' : '#555'"
        font-weight="500"
        :opacity="isDisabled ? '0.4' : '1'"
        :padding="inputRightAddonWithoutMargin ? '0' : '0 1rem'"
      >
        <slot name="input-right-addon-element" />
        {{ inputRightAddon }}
      </c-input-right-addon>
    </c-input-group>

    <c-form-helper-text
      v-if="isHelper"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      gap="8px"
      align-items="center"
      :color="helperTextColor"
    >
      <c-box
        display="flex"
        gap="8px"
        align-items="center"
        :cursor="hasHelperTextClick ? 'pointer' : 'auto'"
        @click="$emit('helper-text-click')"
      >
        <c-box
          v-if="helperTextLogo"
          :min-height="['13px', '16px']"
          :min-width="['13px', '16px']"
          :height="['13px', '16px']"
          :width="['13px', '16px']"
        >
          <inline-svg
            :src="helperTextLogo"
            height="100%"
            width="100%"
            :fill="helperTextColor"
          />
        </c-box>
        {{ helperText }}
      </c-box>
    </c-form-helper-text>

    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { formatDateV2 } from '@/utils/format-date'
import { isDataEmpty } from '@/utils/is-data-empty'

export default {
  name: 'BaseInputText',
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: [Boolean, null],
      default: null,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '',
    },
    value: {
      required: true,
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
    leftElement: {
      type: String,
      default: '',
    },
    rightElement: {
      type: String,
      default: '',
    },
    iconLeftElement: {
      type: String,
      default: '',
    },
    iconLeftElementColor: {
      type: String,
      default: 'black',
    },
    iconRightElement: {
      type: String,
      default: '',
    },
    isWithoutMarginBottom: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    fullWidth: {
      type: Boolean,
      default: false,
    },
    helperTextType: {
      type: String,
      default: '',
    },
    helperTextColor: {
      type: String,
      default: '',
    },
    inputLeftAddon: {
      type: String,
      default: '',
    },
    inputRightAddon: {
      type: String,
      default: '',
    },
    inputRightAddonWithoutMargin: {
      type: Boolean,
      default: false,
    },
    isCentered: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    hasInputLeftIcon() {
      return !!this.leftElement || !!this.iconLeftElement || Boolean(this.$slots['input-left-icon-element'])
    },
    hasInputRightIcon() {
      return !!this.rightElement || !!this.iconRightElement || Boolean(this.$slots['input-right-icon-element'])
    },
    hasInputLeftAddon() {
      return !!this.inputLeftAddon
    },
    hasInputRightAddon() {
      return !!this.inputRightAddon || Boolean(this.$slots['input-right-addon-element'])
    },
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
    helperTextLogo() {
      if (this.helperTextType === 'warn') {
        return require('@/assets/icons/icon-circle-warn.svg')
      }
      if (this.helperTextType === 'questionmark') {
        return require('@/assets/icons/icon-circle-questionmark.svg')
      }
      return ''
    },
    hasHelperTextClick() {
      return this.$listeners['helper-text-click']
    },
    modelValue: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
        this.$emit('trigger-change', val)
        this.$emit('blur')
      },
    },
  },
  methods: {
    isDataEmpty,
    formatDateV2,
    onBlur() {
      this.$emit('blur')
    },
  },
}
</script>
