var render = function () {
  var _vm$fetchDetailHistor, _vm$fetchDetailHistor2, _vm$fetchDetailHistor3, _vm$fetchDetailHistor4, _vm$fetchDetailHistor5, _vm$fetchDetailHistor6, _vm$fetchDetailHistor7, _vm$fetchDetailHistor8, _vm$fetchDetailHistor9, _vm$fetchDetailHistor10, _vm$fetchDetailHistor11, _vm$fetchDetailHistor12, _vm$fetchDetailHistor13;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('CBox', {
    attrs: {
      "font-family": "Roboto",
      "color": "#111",
      "w": "100%",
      "px": ['0px', '120px'],
      "py": ['16px', '30px'],
      "bg": ['#F2F2F2', 'white'],
      "h": ['100%', '100%'],
      "border": ['none', '1px solid #f2f2f2'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '16px'],
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: {
          name: 'client.index'
        }
      }, {
        label: 'Akun',
        href: {
          name: 'client.profile'
        }
      }, {
        label: 'Riwayat Transaksi',
        href: {
          name: 'client.profile.historyTransaction'
        }
      }, {
        label: 'Detail Riwayat Transaksi',
        isCurrent: true
      }]
    }
  })], 1), _c('CHeading', {
    attrs: {
      "as": "h1",
      "font-weight": "700",
      "font-size": "28px",
      "d": ['none', 'block'],
      "mx": ['16px', '0px'],
      "font-family": "Roboto"
    }
  }, [_vm._v(" Detail Riwayat Transaksi ")]), !!((_vm$fetchDetailHistor = _vm.fetchDetailHistoryTransactionsQuery.data.value) !== null && _vm$fetchDetailHistor !== void 0 && _vm$fetchDetailHistor.name) ? _c('CBox', {
    attrs: {
      "px": ['16px', '16px'],
      "mt": ['0px', '16px'],
      "mb": ['16px', '0px']
    }
  }, [_c('CBox', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':not(:last-child)': {
          marginBottom: '30px'
        }
      },
      expression: "{\n        ':not(:last-child)': {\n          marginBottom: '30px',\n        }\n      }"
    }],
    attrs: {
      "p": ['16px', '32px'],
      "rounded": "8px",
      "box-shadow": "0px 5px 15px rgb(0 0 0 / 20%)",
      "bg": "white",
      "display": "flex",
      "gap": "16px",
      "cursor": "pointer"
    }
  }, [_c('CBox', {
    attrs: {
      "width": "100%"
    }
  }, [_c('CBox', {
    staticStyle: {
      "border-collapse": "separate",
      "border-spacing": "0px 8px"
    },
    attrs: {
      "as": "table",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "width": "100%"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "display": "inline-block",
      "margin-right": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" Status ")]), _c('CBox', {
    attrs: {
      "as": "td"
    }
  }, [_c('LabelTransactionStatus', {
    attrs: {
      "status": _vm.fetchDetailHistoryTransactionsQuery.data.value.status
    }
  })], 1)], 1), _c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "display": "inline-block",
      "margin-right": "16px",
      "font-weight": "400",
      "text-align": "start"
    }
  }, [_vm._v(" Tanggal Transaksi ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatDateV2(_vm.fetchDetailHistoryTransactionsQuery.data.value.date)) + " ")])], 1), _c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "display": "inline-block",
      "margin-right": "16px",
      "font-weight": "400"
    }
  }, [_vm._v(" Invoice ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.fetchDetailHistoryTransactionsQuery.data.value.invoice) + " ")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), !!((_vm$fetchDetailHistor2 = _vm.fetchDetailHistoryTransactionsQuery.data.value) !== null && _vm$fetchDetailHistor2 !== void 0 && _vm$fetchDetailHistor2.name) ? _c('CBox', {
    attrs: {
      "px": ['16px', '16px'],
      "mt": ['0px', '16px'],
      "mb": ['16px', '0px']
    }
  }, [_c('CBox', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':not(:last-child)': {
          marginBottom: '30px'
        }
      },
      expression: "{\n        ':not(:last-child)': {\n          marginBottom: '30px',\n        }\n      }"
    }],
    attrs: {
      "p": ['16px', '32px'],
      "rounded": "8px",
      "box-shadow": "0px 5px 15px rgb(0 0 0 / 20%)",
      "bg": "white",
      "display": "flex",
      "gap": "16px",
      "cursor": "pointer"
    }
  }, [_c('CImage', {
    attrs: {
      "object-fit": "cover",
      "h": "230.35px",
      "w": "180px",
      "src": _vm.fetchDetailHistoryTransactionsQuery.data.value.image,
      "alt": "Image",
      "rounded": "6px",
      "display": ['none', 'block']
    }
  }), _c('CBox', {
    attrs: {
      "width": "100%",
      "display": "flex",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [_c('CBox', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "justify-content": "space-between",
      "gap": "8px"
    }
  }, [_c('CBox', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center"
    }
  }, [_c('CImage', {
    attrs: {
      "object-fit": "cover",
      "h": "50px",
      "w": "50px",
      "src": _vm.fetchDetailHistoryTransactionsQuery.data.value.image,
      "alt": "Image",
      "rounded": "6px",
      "display": ['block', 'none']
    }
  }), _c('CText', {
    attrs: {
      "font-size": ['16px', '28px'],
      "font-weight": ['600', '700'],
      "d": "flex",
      "align-items": "center",
      "gap": "8px",
      "flex-wrap": "wrap",
      "font-family": "Roboto"
    }
  }, [_vm._v(" " + _vm._s(((_vm$fetchDetailHistor3 = _vm.fetchDetailHistoryTransactionsQuery.data.value) === null || _vm$fetchDetailHistor3 === void 0 ? void 0 : _vm$fetchDetailHistor3.productName) || '-') + " "), _c('CText', {
    attrs: {
      "font-size": ['16px', '20px'],
      "font-weight": ['600', '700']
    }
  }, [_vm._v(" (" + _vm._s(((_vm$fetchDetailHistor4 = _vm.fetchDetailHistoryTransactionsQuery.data.value) === null || _vm$fetchDetailHistor4 === void 0 ? void 0 : _vm$fetchDetailHistor4.name) || '-') + ") ")])], 1)], 1), _c('LabelProgramStatus', {
    attrs: {
      "status": _vm.statusProgram
    }
  })], 1), _c('CDivider', {
    attrs: {
      "border-color": "lightGray"
    }
  }), _c('CBox', {
    staticStyle: {
      "border-collapse": "separate",
      "border-spacing": "0px 8px"
    },
    attrs: {
      "as": "table",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "width": "100%"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "tr",
      "display": ['flex', 'table-row'],
      "justify-content": "space-between"
    }
  }, [_c('CBox', {
    staticStyle: {
      "display": "inline-block",
      "margin-right": "16px"
    },
    attrs: {
      "as": "th",
      "font-weight": "400"
    }
  }, [_vm._v(" Durasi ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.fetchDetailHistoryTransactionsQuery.data.value.duration) + " hari ")])], 1), _c('CBox', {
    attrs: {
      "as": "tr",
      "display": ['flex', 'table-row'],
      "justify-content": "space-between"
    }
  }, [_c('CBox', {
    staticStyle: {
      "display": "inline-block",
      "margin-right": "16px"
    },
    attrs: {
      "as": "th",
      "font-weight": "400"
    }
  }, [_vm._v(" Harga ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.fetchDetailHistoryTransactionsQuery.data.value.price)) + " ")])], 1)], 1)], 1)], 1)], 1) : _vm._e(), !!((_vm$fetchDetailHistor5 = _vm.fetchDetailHistoryTransactionsQuery.data.value) !== null && _vm$fetchDetailHistor5 !== void 0 && _vm$fetchDetailHistor5.name) ? _c('CBox', {
    attrs: {
      "px": ['16px', '16px'],
      "mt": ['0px', '16px'],
      "mb": ['16px', '0px']
    }
  }, [_c('CBox', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        ':not(:last-child)': {
          marginBottom: '30px'
        }
      },
      expression: "{\n        ':not(:last-child)': {\n          marginBottom: '30px',\n        }\n      }"
    }],
    attrs: {
      "p": ['16px', '32px'],
      "rounded": "8px",
      "box-shadow": "0px 5px 15px rgb(0 0 0 / 20%)",
      "bg": "white",
      "display": "flex",
      "gap": "16px",
      "cursor": "pointer"
    }
  }, [_c('CBox', {
    attrs: {
      "width": "100%"
    }
  }, [_c('CBox', {
    attrs: {
      "d": "flex",
      "align-items": "center",
      "justify-content": "center",
      "gap": "8px"
    }
  }, [_c('CText', {
    attrs: {
      "font-size": ['14px', '28px'],
      "font-weight": ['400', '700'],
      "d": "flex",
      "align-items": "center",
      "gap": "8px",
      "flex-wrap": "wrap",
      "font-family": "Roboto"
    }
  }, [_vm._v(" Detail Pembayaran ")])], 1), _c('CDivider', {
    attrs: {
      "border-color": "lightGray",
      "display": ['none', 'block']
    }
  }), _c('CBox', {
    staticStyle: {
      "border-collapse": "separate",
      "border-spacing": "0px 8px"
    },
    attrs: {
      "as": "table",
      "font-size": ['12px', '16px'],
      "font-weight": "400",
      "font-family": "Roboto",
      "width": "100%"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "font-weight": "400",
      "display": "inline-block",
      "margin-right": "16px"
    }
  }, [_vm._v(" Metode Pembayaran ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#333333"
    }
  }, [_vm._v(" " + _vm._s(_vm.fetchDetailHistoryTransactionsQuery.data.value.paymentMethod) + " ")])], 1), _c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "font-weight": "400",
      "display": "inline-block",
      "margin-right": "16px"
    }
  }, [_vm._v(" Total Harga ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#555"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.fetchDetailHistoryTransactionsQuery.data.value.price)) + " ")])], 1), _c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "font-weight": "400",
      "display": "inline-block",
      "margin-right": "16px"
    }
  }, [_vm._v(" Diskon "), (_vm$fetchDetailHistor6 = _vm.fetchDetailHistoryTransactionsQuery.data) !== null && _vm$fetchDetailHistor6 !== void 0 && (_vm$fetchDetailHistor7 = _vm$fetchDetailHistor6.value) !== null && _vm$fetchDetailHistor7 !== void 0 && _vm$fetchDetailHistor7.coupon ? _c('c-box', {
    attrs: {
      "as": "span",
      "color": "primary.400"
    }
  }, [_vm._v(" (" + _vm._s((_vm$fetchDetailHistor8 = _vm.fetchDetailHistoryTransactionsQuery.data) === null || _vm$fetchDetailHistor8 === void 0 ? void 0 : (_vm$fetchDetailHistor9 = _vm$fetchDetailHistor8.value) === null || _vm$fetchDetailHistor9 === void 0 ? void 0 : _vm$fetchDetailHistor9.coupon) + ") ")]) : _vm._e()], 1), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#555"
    }
  }, [_vm._v(" " + _vm._s(((_vm$fetchDetailHistor10 = _vm.fetchDetailHistoryTransactionsQuery.data) === null || _vm$fetchDetailHistor10 === void 0 ? void 0 : (_vm$fetchDetailHistor11 = _vm$fetchDetailHistor10.value) === null || _vm$fetchDetailHistor11 === void 0 ? void 0 : _vm$fetchDetailHistor11.totalDiscount) > 0 ? '-' : '') + " " + _vm._s(_vm.formatCurrency(((_vm$fetchDetailHistor12 = _vm.fetchDetailHistoryTransactionsQuery.data) === null || _vm$fetchDetailHistor12 === void 0 ? void 0 : (_vm$fetchDetailHistor13 = _vm$fetchDetailHistor12.value) === null || _vm$fetchDetailHistor13 === void 0 ? void 0 : _vm$fetchDetailHistor13.totalDiscount) || 0)) + " ")])], 1), _c('CBox', {
    attrs: {
      "as": "tr"
    }
  }, [_c('CBox', {
    attrs: {
      "as": "th",
      "font-weight": "400",
      "display": "inline-block",
      "margin-right": "16px"
    }
  }, [_vm._v(" Total Pembayaran ")]), _c('CBox', {
    attrs: {
      "as": "td",
      "color": "#555"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatCurrency(_vm.fetchDetailHistoryTransactionsQuery.data.value.totalPayment)) + " ")])], 1)], 1)], 1)], 1), _c('CBox', {
    attrs: {
      "pt": "32px",
      "mt": "auto",
      "px": "16px"
    }
  }, [_c('FloatingButton', {
    attrs: {
      "transaction-id": _vm.route.params.transactionId,
      "status": _vm.fetchDetailHistoryTransactionsQuery.data.value.status,
      "invoice": _vm.fetchDetailHistoryTransactionsQuery.data.value.invoice,
      "product-service-id": _vm.fetchDetailHistoryTransactionsQuery.data.value.productServiceId,
      "quantity": _vm.fetchDetailHistoryTransactionsQuery.data.value.quantity,
      "flag-route": _vm.fetchDetailHistoryTransactionsQuery.data.value.flagRoute
    }
  })], 1)], 1) : _c('EmptyCard', {
    attrs: {
      "label": "Riwayat Transaksi tidak tersedia"
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }