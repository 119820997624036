var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "mt": "1rem",
      "pb": "20px",
      "paths": [{
        label: 'Manajemen Panduan Diet',
        href: {
          name: 'admin.coaching-challenge'
        }
      }, {
        label: "".concat(_vm.id ? 'Ubah' : 'Tambah', " ").concat(_vm._getContentType),
        isCurrent: true
      }]
    }
  }), _c('c-box', {
    attrs: {
      "position": "relative",
      "mx": "auto",
      "mb": ['0', '16px'],
      "p": ['1rem', '2rem 4rem'],
      "border": "1px solid #f2f2f2",
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify": "space-between",
      "align": "center",
      "mb": "30px"
    }
  }, [_c('c-heading', {
    attrs: {
      "as": "h3",
      "font-family": "Roboto",
      "font-weight": "bold",
      "font-size": "24px",
      "line-height": "36px"
    }
  }, [_vm._v(" " + _vm._s(_vm.id ? "Edit" : "Tambah") + " Konten ")]), _vm.id ? _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "red",
      "border-radius": "60px",
      "min-w": "114px",
      "h": "auto",
      "py": "13px",
      "px": "18px",
      "mr": "20px",
      "font-size": "16px",
      "font-weight": "500",
      "line-height": "24px"
    },
    on: {
      "click": _vm.onOpenModalConfirmDelete
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/icon-trash.svg'),
      "alt": "file program",
      "mr": "10px",
      "border-radius": "6px"
    }
  }), _vm._v(" Hapus ")], 1) : _vm._e()], 1), _c('BaseInputSelect', {
    attrs: {
      "label": "Tipe Konten",
      "placeholder": "Pilih Tipe Konten",
      "is-required": "",
      "is-disabled": !!_vm.id,
      "full-width": "",
      "options": _vm.types_
    },
    model: {
      value: _vm.type,
      callback: function callback($$v) {
        _vm.type = $$v;
      },
      expression: "type"
    }
  }), _c('BaseInputText', {
    attrs: {
      "label": "Penulis",
      "placeholder": "Masukkan Penulis",
      "is-required": _vm.type == 'tips_gizi' ? true : false
    },
    model: {
      value: _vm.creator,
      callback: function callback($$v) {
        _vm.creator = $$v;
      },
      expression: "creator"
    }
  }), _c('BaseLabel', {
    attrs: {
      "value": _vm.categories.length > 0 ? true : false,
      "label": "Kategori",
      "is-required": ""
    }
  }, [_c('c-form-control', {
    attrs: {
      "pos": "relative"
    }
  }, [_c('c-input-group', [_c('c-input', {
    attrs: {
      "value": _vm.categoriesText,
      "type": "text",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": "primary.400",
      "font-weight": "500",
      "placeholder": "Pilih Filter",
      "border-top-right-radius": "8px",
      "border-bottom-right-radius": "8px",
      "border-top-left-radius": "8px",
      "border-bottom-left-radius": "8px",
      "focus-border-color": "primary.400",
      "padding": "0 20px",
      "readonly": ""
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onOpenFilter.apply(null, arguments);
      }
    }
  }), _c('c-flex', {
    attrs: {
      "pos": "absolute",
      "right": "0",
      "height": "100%",
      "width": "fit-content",
      "color": "#008C81",
      "justify-content": "center",
      "align-items": "center",
      "margin-right": "1rem",
      "margin-left": "1rem",
      "cursor": "pointer",
      "z-index": "2"
    }
  }, [_c('c-box', {
    attrs: {
      "transform": "rotate(90deg)"
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.onOpenFilter.apply(null, arguments);
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icon-chevron-right.svg'),
      "height": "24px",
      "width": "24px"
    }
  })], 1)], 1)], 1)], 1)], 1), _vm.type && _vm.type != 'tips_gizi' ? _c('BaseLabel', {
    attrs: {
      "value": _vm.showOnDay && _vm.monthGroup,
      "label": "Tampilkan Hari Ke",
      "is-required": ""
    }
  }, [_c('SelectDateContent', {
    attrs: {
      "day": _vm.showOnDay,
      "month": _vm.monthGroup,
      "attributes": _vm.listUsedContent,
      "content-id": _vm.id
    },
    on: {
      "update:day": function updateDay(value) {
        return _vm.showOnDay = value;
      },
      "update:month": function updateMonth(value) {
        return _vm.monthGroup = value;
      }
    }
  })], 1) : _vm._e(), _c('BaseInputText', {
    attrs: {
      "label": "Judul Konten",
      "placeholder": "Masukkan Judul Konten",
      "is-required": ""
    },
    model: {
      value: _vm.title,
      callback: function callback($$v) {
        _vm.title = $$v;
      },
      expression: "title"
    }
  }), _vm.type == 'target_harian' ? _c('BaseLabel', {
    attrs: {
      "value": _vm.isRestDay,
      "label": "Rest Day",
      "is-required": ""
    }
  }, [_c('c-radio-group', {
    attrs: {
      "is-inline": "",
      "mt": "8px",
      "spacing": "20"
    },
    model: {
      value: _vm.isRestDay,
      callback: function callback($$v) {
        _vm.isRestDay = $$v;
      },
      expression: "isRestDay"
    }
  }, [_c('c-radio', {
    attrs: {
      "size": "lg",
      "value": "1",
      "variant-color": "primary"
    }
  }, [_vm._v(" Ya ")]), _c('c-radio', {
    attrs: {
      "size": "lg",
      "value": "0",
      "variant-color": "primary"
    }
  }, [_vm._v(" Tidak ")])], 1)], 1) : _vm._e(), _c('BaseLabel', {
    attrs: {
      "value": _vm.coverImage,
      "label": "Preview Cover Program",
      "is-required": ""
    }
  }, [_c('label', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        bg: 'white',
        cursor: 'pointer',
        border: '1px solid #C4C4C4',
        boxSizing: 'border-box',
        borderRadius: '6px',
        w: '180px',
        h: '100%',
        minHeight: '180px',
        d: 'flex',
        p: '0',
        alignItems: 'center',
        justifyContent: 'center',
        overflow: 'hidden'
      },
      expression: "{\n          bg: 'white',\n          cursor: 'pointer',\n          border: '1px solid #C4C4C4',\n          boxSizing: 'border-box',\n          borderRadius: '6px',\n          w: '180px',\n          h: '100%',\n          minHeight: '180px',\n          d: 'flex',\n          p: '0',\n          alignItems: 'center',\n          justifyContent: 'center',\n          overflow: 'hidden',\n        }"
    }],
    attrs: {
      "for": "img-preview"
    }
  }, [_vm.coverImage ? _c('c-image', {
    attrs: {
      "src": _vm.getPreviewImage(_vm.coverImage),
      "h": !_vm.getPreviewImage(_vm.coverImage).includes('icon-photo') ? '100%' : '94px',
      "alt": "img program",
      "mx": "auto",
      "border-radius": "6px"
    }
  }) : _c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-image-preview.svg'),
      "height": "100px",
      "width": "100px",
      "fill": "#008C81"
    }
  })], 1), _c('c-input', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "img-preview",
      "type": "file",
      "accept": ".jpg, .jpeg, .png"
    },
    on: {
      "change": function change($event) {
        return _vm.onChangeCoverImage($event);
      }
    }
  })], 1), _c('c-box', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.contentSection.length > 0,
      expression: "contentSection.length > 0"
    }],
    attrs: {
      "border": "1px solid #C4C4C4",
      "border-radius": "8px",
      "mb": "20px"
    }
  }, [_c('draggable', _vm._b({
    attrs: {
      "tag": "transition-group",
      "component-data": {
        tag: 'ul',
        type: 'transition-group',
        name: !_vm.drag ? 'flip-list' : null
      }
    },
    on: {
      "start": function start($event) {
        _vm.drag = true;
      },
      "end": function end($event) {
        _vm.drag = false;
      }
    },
    model: {
      value: _vm.contentSection,
      callback: function callback($$v) {
        _vm.contentSection = $$v;
      },
      expression: "contentSection"
    }
  }, 'draggable', _vm.dragOptions, false), _vm._l(_vm.contentSection, function (section, index) {
    var _section$contentSecti;

    return _c('c-box', {
      key: "section-".concat(index),
      attrs: {
        "p": "1rem",
        "border-bottom": _vm.contentSection.length != index + 1 ? '1px solid #C4C4C4' : 'none'
      }
    }, [_c('c-flex', {
      attrs: {
        "align-items": "center",
        "justify-content": "space-between"
      }
    }, [_c('c-flex', {
      attrs: {
        "align-items": "center",
        "justify-content": "center",
        "gap": "8px"
      }
    }, [_c('c-flex', {
      attrs: {
        "d": "flex",
        "align-items": "center",
        "justify-content": "center",
        "w": "40px",
        "h": "40px",
        "border-radius": "8px",
        "cursor": "move"
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icon-hamburger.svg'),
        "height": "24px",
        "width": "24px"
      }
    })], 1), _c('c-heading', {
      attrs: {
        "as": "h4",
        "font-family": "Roboto",
        "font-weight": "bold",
        "font-size": "18px",
        "line-height": "27px",
        "color": "#008C81"
      }
    }, [_vm._v(" Section " + _vm._s(index + 1) + " (" + _vm._s(section === null || section === void 0 ? void 0 : (_section$contentSecti = section.contentSectionType) === null || _section$contentSecti === void 0 ? void 0 : _section$contentSecti.sectionName) + ") ")])], 1), _c('c-flex', {
      attrs: {
        "align-items": "center",
        "gap": "1rem"
      }
    }, [_c('BaseMenu', {
      attrs: {
        "trigger": "manual",
        "wrapper-styles": {
          display: 'flex',
          justifyContent: 'center'
        },
        "content-styles": {
          minWidth: 'inherit'
        },
        "button-styles": {
          width: '60%'
        },
        "placement": "left-end"
      },
      scopedSlots: _vm._u([{
        key: "button",
        fn: function fn(_ref) {
          var toggle = _ref.toggle;
          return [_c('c-button', {
            attrs: {
              "variant": "outline",
              "p": "0"
            },
            on: {
              "click": function click($event) {
                $event.stopPropagation();
                return toggle.apply(null, arguments);
              }
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-option.svg'),
              "height": "16",
              "width": "16",
              "fill": "#008C81"
            }
          })], 1)];
        }
      }, {
        key: "menu",
        fn: function fn(_ref2) {
          var close = _ref2.close;
          return [_c('c-box', {
            attrs: {
              "z-index": "10"
            }
          }, [_c('c-flex', {
            attrs: {
              "direction": "column"
            }
          }, [_vm._l(_vm.contentSections, function (contentSection_) {
            return _c('c-button', {
              key: contentSection_.id,
              attrs: {
                "variant-color": "primary",
                "variant": "ghost",
                "size": "lg"
              },
              on: {
                "click": function click() {
                  close();

                  _vm.updateSection(contentSection_, index);
                }
              }
            }, [_vm._v(" " + _vm._s(contentSection_ === null || contentSection_ === void 0 ? void 0 : contentSection_.sectionName) + " ")]);
          }), _c('c-divider', {
            attrs: {
              "m": "0",
              "color": "#C4C4C4"
            }
          }), _c('c-button', {
            attrs: {
              "variant-color": "danger",
              "variant": "ghost",
              "size": "lg",
              "justify-content": "left"
            },
            on: {
              "click": function click() {
                close();

                _vm.deleteSection(index);
              }
            }
          }, [_vm._v(" Hapus Section ")])], 2)], 1)];
        }
      }], null, true)
    }), _c('c-button', {
      attrs: {
        "variant": "outline",
        "variant-color": "primary",
        "p": "0"
      },
      on: {
        "click": function click($event) {
          return _vm.onToggleSection(index);
        }
      }
    }, [_c('inline-svg', {
      style: {
        transform: _vm.listOpenSection.includes(index) ? 'rotate(-90deg)' : 'rotate(90deg)'
      },
      attrs: {
        "src": require('@/assets/icon-chevron-right.svg'),
        "height": "24px",
        "width": "24px"
      }
    })], 1)], 1)], 1), _c('c-divider', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.listOpenSection.includes(index),
        expression: "listOpenSection.includes(index)"
      }],
      attrs: {
        "mt": "10px",
        "mb": "22px",
        "color": "#C4C4C4"
      }
    }), _c('c-box', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.listOpenSection.includes(index),
        expression: "listOpenSection.includes(index)"
      }]
    }, [section.contentSectionType.sectionName === 'YouTube' ? _c('SectionYoutube', {
      attrs: {
        "description": section.description,
        "url": section.url
      },
      on: {
        "change-description": function changeDescription(newValue) {
          return section.description = newValue;
        },
        "change-url": function changeUrl(newValue) {
          return section.url = newValue;
        }
      }
    }) : section.contentSectionType.sectionName === 'Description' ? _c('SectionDescription', {
      attrs: {
        "idx": index
      },
      model: {
        value: section.description,
        callback: function callback($$v) {
          _vm.$set(section, "description", $$v);
        },
        expression: "section.description"
      }
    }) : section.contentSectionType.sectionName === 'Gambar' ? _c('SectionImage', {
      attrs: {
        "idx": index,
        "description": section.description,
        "image": section.url
      },
      on: {
        "change-description": function changeDescription(newValue) {
          return section.description = newValue;
        },
        "change-image": function changeImage(newValue) {
          return _vm.testt(newValue, index);
        }
      }
    }) : section.contentSectionType.sectionName === 'Carousel' ? _c('SectionCarousel', {
      attrs: {
        "description": section.description,
        "photos": section.contentCarouselImage
      },
      on: {
        "change-description": function changeDescription(newValue) {
          return section.description = newValue;
        },
        "change-photos": function changePhotos(newValue) {
          return section.contentCarouselImage = newValue;
        }
      }
    }) : section.contentSectionType.sectionName === 'Document/File' ? _c('SectionUploadFile', {
      attrs: {
        "idx": index,
        "description": section.description,
        "image": section.url,
        "max-size": 20
      },
      on: {
        "change-description": function changeDescription(newValue) {
          return section.description = newValue;
        },
        "change-image": function changeImage(newValue) {
          return section.url = newValue;
        }
      }
    }) : _vm._e()], 1)], 1);
  }), 1)], 1), _c('BaseMenu', {
    attrs: {
      "trigger": "manual",
      "wrapper-styles": {
        display: 'flex',
        width: '100%',
        justifyContent: 'center'
      },
      "content-styles": {
        minWidth: 'inherit'
      },
      "button-styles": {
        width: '60%'
      }
    },
    scopedSlots: _vm._u([{
      key: "button",
      fn: function fn(_ref3) {
        var toggle = _ref3.toggle;
        return [_c('BaseButton', {
          attrs: {
            "width": "100%",
            "size": "medium",
            "border-radius": "1000px",
            "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined"
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return toggle.apply(null, arguments);
            }
          }
        }, [_vm._v(" Tambah Section ")])];
      }
    }, {
      key: "menu",
      fn: function fn(_ref4) {
        var close = _ref4.close;
        return [_c('c-box', {
          attrs: {
            "z-index": "10"
          }
        }, [_c('c-flex', {
          attrs: {
            "direction": "column"
          }
        }, _vm._l(_vm.contentSections, function (section) {
          return _c('c-button', {
            key: section.id,
            attrs: {
              "variant-color": "primary",
              "variant": "ghost",
              "size": "lg"
            },
            on: {
              "click": function click() {
                close();

                _vm.addSection(section);
              }
            }
          }, [_vm._v(" " + _vm._s(section === null || section === void 0 ? void 0 : section.sectionName) + " ")]);
        }), 1)], 1)];
      }
    }])
  }), _c('c-flex', {
    attrs: {
      "margin-top": "28px",
      "padding": "0 40px",
      "justify-content": "space-between",
      "gap": "20px"
    }
  }, [_c('BaseButton', {
    attrs: {
      "size": "medium",
      "width": "100%",
      "variant": "outlined"
    },
    on: {
      "click": _vm.onCancel
    }
  }, [_vm._v(" Batal ")]), _c('BaseButton', {
    attrs: {
      "size": "medium",
      "width": "100%",
      "disabled": _vm.isDisabledSubmit
    },
    on: {
      "click": _vm.onOpenModalConfirmSave
    }
  }, [_vm._v(" Simpan ")])], 1)], 1), _vm.isUploading ? _c('c-alert', {
    attrs: {
      "position": "fixed",
      "top": "5px",
      "left": "50%",
      "transform": "translateX(-50%)",
      "border-radius": "3px",
      "w": "200px",
      "status": "info",
      "font-size": "14px",
      "z-index": "9999"
    }
  }, [_c('c-alert-icon'), _vm._v(" Uploading... ")], 1) : _vm._e(), _vm.isOpenFilter ? _c('ModalFilter', {
    attrs: {
      "is-open": _vm.isOpenFilter,
      "data": _vm.categoriesContent,
      "selected": _vm.selectedCategories,
      "categories": _vm.categories,
      "sub-categories": _vm.subCategories
    },
    on: {
      "close": _vm.onCloseFilter,
      "save": _vm.onSaveFilter,
      "update:categories": function updateCategories(value) {
        return _vm.categories = value;
      },
      "update:subCategories": function updateSubCategories(value) {
        return _vm.subCategories = value;
      }
    }
  }) : _vm._e(), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalConfirmSave,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('c-image', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/icon-confirm-update.svg')
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "justify-content": "center",
            "padding": "30px 24px",
            "text-align": "center"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "18px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" " + _vm._s(_vm.id ? 'Apakah anda yakin ingin mengubah konten?' : 'Apakah anda yakin ingin melakukan penambahan konten?') + " ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "variant": "outlined",
            "rounded": "1000px",
            "width": "100%",
            "disabled": _vm.isLoading
          },
          on: {
            "click": _vm.onCloseModalConfirmSave
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-confirm.svg'),
            "right-svg-icon-color": "white",
            "color": "primary.400",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoading
          },
          on: {
            "click": _vm.onSubmit
          }
        }, [_vm._v(" Simpan ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }