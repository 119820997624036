var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "margin-bottom": _vm.isWithoutMarginBottom ? '0' : ['16px', '24px'],
      "is-read-only": _vm.isReadOnly,
      "width": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [!!_vm.label ? _c('c-form-label', {
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "padding-left": "8px"
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " "), _vm.isRequired !== null && !_vm.isRequired ? _c('c-box', {
    attrs: {
      "as": "span",
      "font-family": "Roboto",
      "font-weight": "400",
      "color": "#C4C4C4",
      "font-size": ['12px', '14px']
    }
  }, [_vm._v(" (Optional) ")]) : _vm._e()], 1) : _vm._e(), _c('c-input-group', [_vm.hasInputLeftAddon ? _c('c-input-left-addon', {
    attrs: {
      "border-top-left-radius": "8px",
      "border-bottom-left-radius": "8px",
      "height": ['48px', '62px'],
      "background-color": "#F2F2F2",
      "font-size": ['14px', '18px'],
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "500",
      "opacity": _vm.isDisabled ? '0.4' : '1'
    }
  }, [_vm._v(" " + _vm._s(_vm.inputLeftAddon) + " ")]) : _vm._e(), _c('c-box', {
    attrs: {
      "position": "relative",
      "width": _vm.fullWidth ? '100%' : 'inherit'
    }
  }, [_vm.hasInputLeftIcon ? _c('c-input-left-element', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        height: ['48px', '62px'],
        fontSize: ['14px', '18px'],
        color: _vm.value ? '#008C81' : '#555',
        fontWeight: _vm.value ? '500' : '400'
      },
      expression: "{\n          height: ['48px', '62px'],\n          fontSize: ['14px', '18px'],\n          color: value ? '#008C81' : '#555',\n          fontWeight: value ? '500' : '400',\n        }"
    }]
  }, [_vm._t("input-left-icon-element"), _vm._v(" " + _vm._s(_vm.iconLeftElement ? "" : _vm.leftElement) + " "), _vm.iconLeftElement ? _c('c-box', {
    attrs: {
      "height": ['20px', '25px'],
      "width": ['20px', '25px'],
      "min-height": ['20px', '25px'],
      "min-width": ['20px', '25px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconLeftElement,
      "height": "100%",
      "width": "100%",
      "fill": _vm.iconLeftElementColor
    }
  })], 1) : _vm._e()], 2) : _vm._e(), _c('c-input', {
    attrs: {
      "type": "text",
      "height": ['48px', '62px'],
      "font-size": ['14px', '18px'],
      "color": _vm.isInvalid ? 'danger.400' : 'primary.400',
      "font-weight": "500",
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.label,
      "border-top-left-radius": _vm.hasInputLeftAddon ? '0px' : '8px',
      "border-bottom-left-radius": _vm.hasInputLeftAddon ? '0px' : '8px',
      "border-top-right-radius": _vm.hasInputRightAddon ? '0px' : '8px',
      "border-bottom-right-radius": _vm.hasInputRightAddon ? '0px' : '8px',
      "focus-border-color": "primary.400",
      "padding-block": "0",
      "padding-left": _vm.hasInputLeftIcon ? '50px' : '20px',
      "padding-right": _vm.hasInputRightIcon ? '50px' : '20px',
      "text-align": _vm.isCentered ? 'center' : 'left'
    },
    on: {
      "blur": _vm.onBlur,
      "focus": function focus($event) {
        return _vm.$emit('focus', $event);
      }
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }), _vm.hasInputRightIcon ? _c('c-input-right-element', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        height: ['48px', '62px'],
        fontSize: ['14px', '18px'],
        color: _vm.value ? '#008C81' : '#555',
        fontWeight: _vm.value ? '500' : '400'
      },
      expression: "{\n          height: ['48px', '62px'],\n          fontSize: ['14px', '18px'],\n          color: value ? '#008C81' : '#555',\n          fontWeight: value ? '500' : '400',\n        }"
    }]
  }, [_vm._t("input-right-icon-element"), _vm._v(" " + _vm._s(_vm.iconRightElement ? "" : _vm.rightElement) + " "), _vm.iconRightElement ? _c('c-text', {
    attrs: {
      "as": "span",
      "height": ['20px', '25px'],
      "width": ['20px', '25px'],
      "min-height": ['20px', '25px'],
      "min-width": ['20px', '25px']
    }
  }, [_vm.iconRightElement ? _c('inline-svg', {
    attrs: {
      "src": _vm.iconRightElement,
      "height": "100%",
      "width": "100%",
      "fill": "#323232"
    }
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e()], 1), _vm.hasInputRightAddon ? _c('c-input-right-addon', {
    attrs: {
      "border-top-right-radius": "8px",
      "border-bottom-right-radius": "8px",
      "height": ['48px', '62px'],
      "background-color": "#F2F2F2",
      "font-size": ['14px', '18px'],
      "color": _vm.isInvalid ? 'danger.400' : _vm.value ? 'primary.400' : '#555',
      "font-weight": "500",
      "opacity": _vm.isDisabled ? '0.4' : '1',
      "padding": _vm.inputRightAddonWithoutMargin ? '0' : '0 1rem'
    }
  }, [_vm._t("input-right-addon-element"), _vm._v(" " + _vm._s(_vm.inputRightAddon) + " ")], 2) : _vm._e()], 1), _vm.isHelper ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "color": _vm.helperTextColor
    }
  }, [_c('c-box', {
    attrs: {
      "display": "flex",
      "gap": "8px",
      "align-items": "center",
      "cursor": _vm.hasHelperTextClick ? 'pointer' : 'auto'
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('helper-text-click');
      }
    }
  }, [_vm.helperTextLogo ? _c('c-box', {
    attrs: {
      "min-height": ['13px', '16px'],
      "min-width": ['13px', '16px'],
      "height": ['13px', '16px'],
      "width": ['13px', '16px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.helperTextLogo,
      "height": "100%",
      "width": "100%",
      "fill": _vm.helperTextColor
    }
  })], 1) : _vm._e(), _vm._v(" " + _vm._s(_vm.helperText) + " ")], 1)], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "display": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey
    }, [_vm._v(" ● " + _vm._s(invalidTextValue) + " ")]);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }