import { render, staticRenderFns } from "./preview-meal-plan-schedule.vue?vue&type=template&id=69c61a24&"
import script from "./preview-meal-plan-schedule.vue?vue&type=script&lang=js&"
export * from "./preview-meal-plan-schedule.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CImage: require('@chakra-ui/vue').CImage, CGrid: require('@chakra-ui/vue').CGrid, CButton: require('@chakra-ui/vue').CButton})
