var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "border-left": "1px solid #C4C4C4",
      "padding-left": "8px",
      "gap": "2px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['14px', '16px']
    }
  }, [_vm._v(" " + _vm._s(_vm.title) + " ")]), _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "position": "relative",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "color": "neutral.darkGray",
      "font-size": ['14px', '16px'],
      "position": "relative",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "align-items": "center",
      "gap": "6px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "min-width": "16px",
      "height": ['16px', '20px'],
      "border-top-right-radius": ['4px', '6px'],
      "border-bottom-right-radius": ['4px', '6px'],
      "background-color": "#F2F2F2",
      "transition-duration": "500ms"
    }
  }), _c('c-text', {
    attrs: {
      "flex-shrink": "0",
      "font-size": ['10px', '12px']
    }
  }, [_vm._v(" " + _vm._s(_vm.requirementParse) + " " + _vm._s(_vm.unit) + " ")])], 1)], 1), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "8px",
      "color": "neutral.darkGray",
      "font-size": ['14px', '16px'],
      "position": "absolute",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "align-items": "center",
      "gap": "6px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%"
    }
  }, [_c('c-box', {
    attrs: {
      "width": (_vm.currentPercent >= 100 ? 100 : _vm.currentPercent) + '%',
      "min-width": "16px",
      "height": ['16px', '20px'],
      "border-top-right-radius": ['4px', '6px'],
      "border-bottom-right-radius": ['4px', '6px'],
      "background-color": _vm.getColor.backgroundColorCurrentBar,
      "transition-duration": "500ms"
    }
  })], 1), _c('c-box', {
    attrs: {
      "position": "absolute",
      "left": "10px",
      "font-size": ['10px', '12px'],
      "flex-shrink": "0"
    }
  }, [_vm._v(" " + _vm._s(_vm.currentParse) + " " + _vm._s(_vm.unit) + " ")]), _c('c-text', {
    attrs: {
      "opacity": "0",
      "font-size": ['10px', '12px'],
      "flex-shrink": "0",
      "background-color": "blue"
    }
  }, [_vm._v(" " + _vm._s(_vm.requirementParse) + " " + _vm._s(_vm.unit) + " ")])], 1)], 1)], 1), _c('c-text', {
    attrs: {
      "font-size": ['12px', '14px'],
      "color": _vm.getColor.colorSubText
    }
  }, [_vm._v(" " + _vm._s(_vm.getSubText) + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }