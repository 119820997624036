var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('BaseList', {
    attrs: {
      "type": _vm.type,
      "pos": "outside",
      "ml": "24px",
      "font-size": ['12px', '16px'],
      "color": "neutral.superDarkGray"
    }
  }, _vm._l(_vm.items, function (item, i) {
    var _item$menus, _item$menus2, _item$menus3, _item$menus4, _item$menus5, _item$menus6;

    return _c('BaseListItem', {
      key: i
    }, [(item === null || item === void 0 ? void 0 : item.menuType) === 'multipleEntry' ? _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.menuName) + " "), _c('ListMenuRecommendation', {
      attrs: {
        "type": "disc",
        "items": item === null || item === void 0 ? void 0 : item.menus
      }
    })], 1) : (item === null || item === void 0 ? void 0 : item.menuType) === 'singleEntry' ? _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : (_item$menus = item.menus) === null || _item$menus === void 0 ? void 0 : _item$menus.servingSize) + " " + _vm._s((item === null || item === void 0 ? void 0 : (_item$menus2 = item.menus) === null || _item$menus2 === void 0 ? void 0 : _item$menus2.servingSizeUnit) === 'Lainnya' ? item === null || item === void 0 ? void 0 : (_item$menus3 = item.menus) === null || _item$menus3 === void 0 ? void 0 : _item$menus3.servingSizeUnitOther : item === null || item === void 0 ? void 0 : (_item$menus4 = item.menus) === null || _item$menus4 === void 0 ? void 0 : _item$menus4.servingSizeUnit) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.menuName) + " " + _vm._s(item !== null && item !== void 0 && (_item$menus5 = item.menus) !== null && _item$menus5 !== void 0 && _item$menus5.servingSizeOtherPerGram ? "(".concat(item === null || item === void 0 ? void 0 : (_item$menus6 = item.menus) === null || _item$menus6 === void 0 ? void 0 : _item$menus6.servingSizeOtherPerGram, " gram)") : "") + " ")]) : _c('c-box', [_vm._v(" " + _vm._s(item === null || item === void 0 ? void 0 : item.servingSize) + " " + _vm._s((item === null || item === void 0 ? void 0 : item.servingSizeUnit) === 'Lainnya' ? item === null || item === void 0 ? void 0 : item.servingSizeUnitOther : item === null || item === void 0 ? void 0 : item.servingSizeUnit) + " " + _vm._s(item === null || item === void 0 ? void 0 : item.foodName) + " " + _vm._s(item !== null && item !== void 0 && item.servingSizeOtherPerGram ? "(".concat(item === null || item === void 0 ? void 0 : item.servingSizeOtherPerGram, " gram)") : "") + " ")])], 1);
  }), 1);
}
var staticRenderFns = []

export { render, staticRenderFns }