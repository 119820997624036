var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%",
      "h": "100%",
      "pb": [_vm.isAuthenticated && _vm.isShowBottomNavbar ? '64px' : '0px', '0px']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "header"
    }
  }, [_c('HeaderComp', {
    attrs: {
      "back-title": _vm.headerTitle,
      "back": _vm.headerBack,
      "with-back": _vm.headerWithBack,
      "notification": _vm.isShowNotification,
      "cart": _vm.isShowCart,
      "side-menu": _vm.isSideMenu,
      "list-menu-program": _vm.listProgram
    },
    on: {
      "toggle": _vm.toggleSideMenu
    }
  })], 1), _c('c-box', {
    attrs: {
      "d": _vm.isAuthenticated ? 'flex' : 'block',
      "flex-direction": "row",
      "mx": "auto",
      "max-width": _vm.isAuthenticated ? '1440px' : 'unset'
    }
  }, [_vm.isAuthenticated && _vm.isSideMenu ? _c('SideMenu', {
    attrs: {
      "open": _vm.isOpenSideMenu
    },
    on: {
      "updateOpen": _vm.updateSideMenu
    }
  }) : _vm._e(), _c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": _vm.isAuthenticated ? _vm.isSideMenu ? _vm.isOpenSideMenu ? '1050px' : '1200px' : '1440px' : 'unset',
      "transition-duration": "300ms",
      "pb": [_vm.isRouteProfile && _vm.isShowAlert ? '80px' : '0px', '0px']
    }
  }, [_c('portal-target', {
    attrs: {
      "name": "breadcrumb"
    }
  }), _c('c-box', {
    attrs: {
      "d": ['block', _vm.isAuthenticated ? 'flex' : 'block'],
      "flex-grow": ['unset', '1'],
      "my": ['0px', _vm.isAuthenticated ? '1rem' : '0px']
    }
  }, [_c('transition', {
    attrs: {
      "name": "route",
      "mode": "out-in"
    }
  }, [_c('router-view')], 1)], 1)], 1)], 1), !_vm.isAuthenticated || _vm.isShowFloatingChat ? _c('FloatingChat') : _vm._e(), _vm.isShowFooter ? _c('FooterComp') : _vm._e(), _vm.isAuthenticated && _vm.userRole === 'client' && _vm.isShowBottomNavbar ? _c('NavBottom') : _vm._e(), _vm.isAuthenticated && _vm.isShowAlert && (_vm.isHavingPendingNutritionist || _vm.isHavingPendingQuisionary) ? _c('c-box', {
    attrs: {
      "pos": "fixed",
      "bottom": ['60px', '20px'],
      "right": [null, '20px']
    }
  }, [_c('c-alert', {
    attrs: {
      "status": "warning"
    }
  }, [_c('c-alert-icon'), _c('c-alert-description', [_vm._v(" " + _vm._s(_vm.isHavingPendingNutritionist ? 'Kamu masih belum memilih ahli gizi, yuk pilih ahli gizi agar dapat memulai program.' : 'Kamu punya formulir gizi yang belum selesai, mohon untuk melengkapinya') + " "), _vm.isHavingPendingNutritionist ? _c('c-button', {
    attrs: {
      "size": "sm",
      "variant": "ghost",
      "variant-color": "primary.400",
      "as": "router-link",
      "to": {
        name: 'client.nutritionists'
      }
    }
  }, [_vm._v(" Pilih Ahli Gizi ")]) : _c('c-button', {
    attrs: {
      "size": "sm",
      "variant": "ghost",
      "variant-color": "primary.400",
      "as": "router-link",
      "to": {
        name: 'client.kuisioner',
        params: {
          programId: _vm.latestProgramId
        }
      }
    }
  }, [_vm._v(" Lengkapi ")])], 1)], 1)], 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }