<template>
  <c-flex
    flex-direction="column"
    width="100%"
    border-left="1px solid #C4C4C4"
    padding-left="8px"
    gap="2px"
  >
    <c-text
      :font-size="['14px', '16px']"
    >
      {{ title }}
    </c-text>
    <c-flex
      flex-direction="column"
      position="relative"
      width="100%"
    >
      <c-flex
        align-items="center"
        gap="8px"
        color="neutral.darkGray"
        :font-size="['14px', '16px']"
        position="relative"
        width="100%"
      >
        <c-flex
          flex-grow="1"
          align-items="center"
          gap="6px"
        >
          <c-box
            width="100%"
            min-width="16px"
            :height="['16px', '20px']"
            :border-top-right-radius="['4px', '6px']"
            :border-bottom-right-radius="['4px', '6px']"
            background-color="#F2F2F2"
            transition-duration="500ms"
          />
          <c-text
            flex-shrink="0"
            :font-size="['10px', '12px']"
          >
            {{ requirementParse }} {{ unit }}
          </c-text>
        </c-flex>
      </c-flex>
      <c-flex
        align-items="center"
        gap="8px"
        color="neutral.darkGray"
        :font-size="['14px', '16px']"
        position="absolute"
        width="100%"
      >
        <c-flex
          flex-grow="1"
          align-items="center"
          gap="6px"
        >
          <c-box width="100%">
            <c-box
              :width="(currentPercent >= 100 ? 100 : currentPercent) + '%'"
              min-width="16px"
              :height="['16px', '20px']"
              :border-top-right-radius="['4px', '6px']"
              :border-bottom-right-radius="['4px', '6px']"
              :background-color="getColor.backgroundColorCurrentBar"
              transition-duration="500ms"
            />
          </c-box>
          <c-box
            position="absolute"
            left="10px"
            :font-size="['10px', '12px']"
            flex-shrink="0"
          >
            {{ currentParse }} {{ unit }}
          </c-box>
          <c-text
            opacity="0"
            :font-size="['10px', '12px']"
            flex-shrink="0"
            background-color="blue"
          >
            {{ requirementParse }} {{ unit }}
          </c-text>
        </c-flex>
      </c-flex>
    </c-flex>
    <!-- sub text -->
    <c-text
      :font-size="['12px', '14px']"
      :color="getColor.colorSubText"
    >
      {{ getSubText }}
    </c-text>
  </c-flex>
</template>

<script>

import Fraction from 'fraction.js'

export default {
  name: 'CardInfoSimpleNutritionalProperty',
  props: {
    title: {
      type: String,
      default: 'Energi',
    },
    current: {
      type: Number,
      default: 0,
    },
    requirement: {
      type: Number,
      default: 0,
    },
    unit: {
      type: String,
      default: 'Kalori',
    },
    parseFrom: {
      type: Object,
      default: () => ({}),
    },
  },
  computed: {
    currentParse() {
      if (this.current) return Number(this.current.toFixed(0))
      return 0
    },
    requirementParse() {
      if (this.requirement) return Number(this.requirement.toFixed(0))
      return 0
    },
    currentPercent() {
      return this.currentParse / this.requirementParse * 100
    },
    getColor() {
      if (this.currentPercent < 80) {
        return {
          backgroundColorCurrentBar: 'danger.100',
          colorSubText: 'danger.400',
        }
      }
      if (this.currentPercent <= 99) {
        return {
          backgroundColorCurrentBar: 'warning.100',
          colorSubText: 'warning.400',
        }
      }

      if (this.currentPercent <= 110) {
        return {
          backgroundColorCurrentBar: 'primary.100',
          colorSubText: 'primary.400',
        }
      }
      return {
        backgroundColorCurrentBar: 'danger.100',
        colorSubText: 'danger.400',
      }
    },
    getSubText() {
      const diff = (new Fraction(this.currentParse)).sub(this.requirementParse).abs()

      if (this.current < this.requirement) {
        return 'Kurang: ' + diff + ' ' + this.unit
      }
      if (this.current > this.requirement) {
        return 'Lebih: ' + diff + ' ' + this.unit
      }
      return 'Cukup'
    },
  },
}
</script>
