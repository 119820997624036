<template>
  <c-box
    id="landingpage"
    :background-color="['#F2F2F2', (isAuthenticated ? '#FFFFFF' : '#F2F2F2')]"
    :py="['0px', isAuthenticated ? '1rem' : '0px']"
    :border-radius="isAuthenticated ? ['0px', '16px'] : '0px'"
    :box-shadow="isAuthenticated ? ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'] : ''"
  >
    <Banner />
    <MiniDashboard v-if="!isAuthenticated" />
    <c-box
      position="relative"
      :background-image="['none', require('@/assets/bg-vector.png')]"
      background-size="cover"
      background-position="right bottom"
      background-repeat="no-repeat"
      z-index="2"
    >
      <c-box
        position="absolute"
        max-width="1200px"
        mx="auto"
        width="100%"
        :height="['600px', '860px']"
        :background-image="isAuthenticated ? 'unset' : ([require('@/assets/images/background-program-mobile.svg'), require('@/assets/images/background-program.svg')])"
        background-size="100%"
        :background-position="['center 20px', 'center 150px']"
        background-repeat="no-repeat"
        transform="translateX(-50%)"
        left="50%"
        z-index="2"
      />
      <c-box
        pos="relative"
        max-width="1200px"
        mx="auto"
        :background="isAuthenticated ? 'unset' : 'linear-gradient(180deg, rgba(0, 140, 130, 0.9) 0%, rgba(255, 255, 255, 0.18) 82.96%)'"
        :rounded-top-left="['25px', '150px']"
      >
        <StartProgram v-if="!isAuthenticated" />
        <c-box
          v-if="isAuthenticated"
        >
          <c-box
            width="100%"
            :height="['170px']"
            position="relative"
            background-color="#00A68C"
            :border-bottom-left-radius="['12px', '0px']"
            :border-bottom-right-radius="['12px', '0px']"
            overflow="hidden"
          >
            <c-image
              :display="['block', 'none']"
              :src="require('@/assets/images/decoration-program-active.png')"
              position="absolute"
              height="100%"
              width="auto"
              left="0"
            />
            <c-image
              :display="['block', 'none']"
              :src="require('@/assets/images/decoration-program-active.png')"
              position="absolute"
              height="100%"
              width="auto"
              right="0"
              transform="scaleX(-1)"
            />
            <c-box
              :display="['none', 'block']"
              position="absolute"
              bottom="-270px"
              right="-100px"
              width="450px"
              height="450px"
              border-radius="450px"
              background-color="#008C81"
            />
          </c-box>
          <c-box
            pt="1rem"
            px="1rem"
            margin-top="-140px"
          >
            <ActiveProgram />
          </c-box>
          <SncakingGuidance />
        </c-box>
        <MainMenu v-if="isAuthenticated && userRole === 'client'" />
        <Program />
      </c-box>
      <portal-target 
        name="program-recommendation"
        :transition="fadeTransition"
      />
      <DietelaQuiz v-if="isAuthenticated && userRole === 'client'" />
      <Testimonial v-if="!isAuthenticated" />
      <PromoHighlight />
    </c-box>
    <c-box
      :background-image="[isAuthenticated ? 'unset' : require('@/assets/images/background-mobile.svg'), isAuthenticated ? 'unset' : require('@/assets/images/background.svg')]"
      background-size="contain"
      :background-position="['center 89%', 'center 17%']"
      background-repeat="no-repeat"
      z-index="10"
    >
      <Advantages v-if="!isAuthenticated" />
      <CoachingCourse />
      <KonsultasiAdmin v-if="isAuthenticated && userRole === 'client'" />
      <PilihDietela v-if="!isAuthenticated" />
      <ServiceStage v-if="!isAuthenticated" />
    </c-box>
    <Artikel />
    <FAQ v-if="!isAuthenticated" />
  </c-box>
</template>

<script>
import Banner from '@/components/Banner.vue'
import MiniDashboard from '@/components/mini-dashboard.vue'
import MainMenu from '@/components/main-menu.vue'
import Program from '@/components/program/index.vue'
import CoachingCourse from '@/components/coaching-course.vue'
import KonsultasiAdmin from '@/components/konsultasi-admin.vue'
import PromoHighlight from '@/components/promo-highlight.vue'
import Advantages from '@/components/advantages.vue'
import Artikel from '@/components/artikel/index.vue'
import Testimonial from '@/components/testimonial.vue'
import PilihDietela from '@/components/pilih-dietela.vue'
import ServiceStage from '@/components/service-stage.vue'
import ActiveProgram from '@/components/homepage/active-program.vue'
import FAQ from '@/components/faq.vue'
import DietelaQuiz from '@/components/dietela-quiz.vue'
import StartProgram from '@/components/start-program.vue'
import { mapActions, mapGetters, mapState, mapMutations } from 'vuex'
// import DetailProducts from '@/views/client/detail-products.vue'
import generalMixin from '@/utils/general-mixins'
import SncakingGuidance from '@/components/snacking-guidance.vue'

export default {
  name: 'LandingPage',
  components: {
    Banner,
    // DetailProducts,
    MiniDashboard,
    MainMenu,
    Program,
    CoachingCourse,
    KonsultasiAdmin,
    PromoHighlight,
    Advantages,
    Artikel,
    Testimonial,
    PilihDietela,
    ServiceStage,
    FAQ,
    DietelaQuiz,
    ActiveProgram,
    StartProgram,
    SncakingGuidance,
  },
  mixins: [generalMixin],
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      if (vm.userRole === 'nutritionist') {
        vm.$router.replace({ name: 'nutrisionist.managementClient' })
      } else {
        return true
      }
    })
  },
  data() {
    return {
      itemIdModal: null,
      isDetailProduct: false,
    }
  },
  computed: {
    ...mapGetters({
      products: 'clients/products',
      product: 'clients/product',
      isOpen: 'clients/isOpen',
      isAuthenticated: 'auth/isAuthenticated',
      redirect: 'auth/redirect',
    }),
    ...mapState({
      userRole: (s) => s.auth?.user?.role,
    }),
    productId() {
      if (this.product) {
        return this.product.id
      }
      return null
    },
    fadeTransition() {
      return {
        functional: true,
        render(h, context) {
          return h('transition', { props: { name: 'fade', mode: 'out-in' } }, context.children)
        }, 
      }
    },
    googleToken() {
      let search = new URLSearchParams(location.search)
      const googleToken = this.$route?.query?.code || search.get('code')
      return googleToken
    },
  },
  watch: {
    isOpen(val) {
      if (val === false) {
        this.itemIdModal = null
      }
    },
  },
  created() {
    if (!this.googleToken && !this.isAuthenticated) {
      this.$router.push({ name: 'auth' })
    }
  },
  async mounted() {
    this.setHeaderConfig({
      type: 'homepage',
    })

    await this.verifyGoogleToken()

    if (this.userRole === 'super_admin') {
      return this.$router.push({ name: 'superadmin' })
    }
    if (this.userRole === 'admin') {
      return this.$router.push({ name: 'admin' })
    }

    // Get Old Program
    // this.listProducts()
    this.onOpenModal(false)
  },
  methods: {
    ...mapActions({
      listProducts: 'clients/getProducts',
      detailProduct: 'clients/getProductById',
      onOpenModal: 'clients/onToggleModal',
      triggerUpdateUserType: 'userType/triggerUpdateUserType',
    }),
    ...mapMutations({
      setHeaderConfig: 'general/setHeaderConfig',
      setRedirect: 'auth/setRedirect',
    }),
    async verifyGoogleToken() {
      if (this.googleToken) {
        try {
          // remove google token from url
          window.history.replaceState({}, null, location.origin + '/')
          const r = await this.axios.post('/v1/auth/google-oauth', { code: this.googleToken })
          // DATA r?.data?.data === "not_registered":
          // {
          //   "authStatus": "not_registered",
          //     "firstName": "Oxwazz",
          //     "lastName": "xxx",
          //     "birthDate": {
          //       "year": 1996,
          //       "month": 11,
          //       "day": 17
          // },
          //   "email": "oxwazz.xxx@gmail.com",
          //     "token": "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJlbWFpbCI6Im94d2F6ei5tYWNoaW5ldmlzaW9uQGdtYWlsLmNvbSIsImlhdCI6MTcyNjQ3MjYxMCwiZXhwIjoxNzI2NTU5MDEwfQ.PQJeUQ3eK5IiIuwtUarsiUKCet3Qe05tJu9sy1M4PIU"
          // }
          let authStatus = r?.data?.data?.authStatus
          let token = r?.data?.data?.token
          let refreshToken = r?.data?.data?.refreshToken
          let user = r?.data?.data

          if (authStatus === 'not_registered' && this.redirect?.name === 'auth') {
            this.$toast ({
              status: 'success',
              title: 'Success',
              description: 'lengkapi profile',
              duration: 3000,
            })
            this.$router.replace({ name: 'auth.detail-profile', query: {
              ...user,
              token,
              birthDate: `${user?.birthDate?.year}-${user?.birthDate?.month}-${user?.birthDate?.day}`,
            } })
            return
          }

          if (authStatus === 'not_registered') {
            const res = await this.axios.put('/v1/users/update-profile', {
              firstName: user.firstName || '',
              lastName: user.lastName || '',
              birthDate: `${user?.birthDate?.year}-${user?.birthDate?.month}-${user?.birthDate?.day}`,
              country: '',
              phone: '',
              email: user.email,
              token: token,
            })

            authStatus = res?.data?.data?.authStatus
            token = res?.data?.data?.token
            refreshToken = res?.data?.data?.refreshToken
            user = res?.data?.data
          }

          this.triggerUpdateUserType()

          this.$store.commit('auth/setUser', user)
          this.$store.commit('auth/setToken', token)
          this.$store.commit('auth/setRefreshToken', refreshToken)
          this.$store.commit('auth/setIsGoogle', user.isGoogle)
          if (this.redirect?.name) {
            this.$router.push(this.redirect)
            this.setRedirect(null)
          }
        } catch (err) {
          console.error('error while trying to signin with google code', err)
          if (err.response.data?.message.includes("user doesn't exists")) {
            this.$router.replace({ name: 'auth.register' })
            return
          }
          this.$errorToastFromCatch(err)
        }
      }
    },
  },
}
</script>
<style>
body {
  overflow: inherit;
}

#landingpage {
  position: relative;
  width: 100%;
}
</style>
