<template>
  <BaseList
    :type="type"
    pos="outside"
    ml="24px"
    :font-size="['12px','16px']"
    color="neutral.superDarkGray"
  >
    <BaseListItem
      v-for="(item, i) in items"
      :key="i"
    >
      <c-box
        v-if="item?.menuType === 'multipleEntry'"
      >
        {{ item?.menuName }}
        <ListMenuRecommendation
          type="disc"
          :items="item?.menus"
        />
      </c-box>
      <c-box
        v-else-if="item?.menuType === 'singleEntry'"
      >
        {{ item?.menus?.servingSize }} {{ item?.menus?.servingSizeUnit === 'Lainnya' ? item?.menus?.servingSizeUnitOther : item?.menus?.servingSizeUnit }} {{ item?.menuName }} {{ item?.menus?.servingSizeOtherPerGram ? `(${item?.menus?.servingSizeOtherPerGram} gram)`: "" }}
      </c-box>
      <c-box
        v-else
      >
        {{ item?.servingSize }} {{ item?.servingSizeUnit === 'Lainnya' ? item?.servingSizeUnitOther : item?.servingSizeUnit }} {{ item?.foodName }} {{ item?.servingSizeOtherPerGram ? `(${item?.servingSizeOtherPerGram} gram)`: "" }}
      </c-box>
    </BaseListItem>
  </BaseList>
</template>

<script>
import ListMenuRecommendation from '@/components/meal-plan/_widgets/list-menu-recommendation.vue'
import BaseList from '@/components/elements/base-list'
import BaseListItem from '@/components/elements/base-list-item'

export default {
  name: 'ListMenuRecommendation',
  components: {
    BaseList,
    BaseListItem,
    ListMenuRecommendation,
  },
  props: {
    type: {
      type: String,
      default: 'decimal',
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
}
</script>

<style>

</style>