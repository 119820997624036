var render = function () {
  var _vm$data, _vm$data2, _vm$data3, _vm$data4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.data ? _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "padding-inline": "8px",
      "justify-content": "center",
      "position": "relative",
      "z-index": "10"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "max-width": ['600px', '800px'],
      "border-radius": ['8px', '12px'],
      "padding-inline": ['12px', '80px'],
      "padding-block": ['8px', '10px'],
      "background-color": "#FFDA45",
      "justify-content": "space-between",
      "align-items": "center",
      "gap": "0px"
    }
  }, [_c('c-box', {
    directives: [{
      name: "chakra",
      rawName: "v-chakra",
      value: {
        'p': {
          color: '#003935',
          fontSize: ['10px', '16px'],
          fontWeight: 500
        },
        'b': {
          fontSize: ['15px', '22px'],
          fontWeight: 700
        }
      },
      expression: "{\n        'p': {\n          color: '#003935',\n          fontSize: ['10px', '16px'],\n          fontWeight: 500,\n        },\n        'b': {\n          fontSize: ['15px', '22px'],\n          fontWeight: 700,\n        },\n      }"
    }],
    attrs: {
      "flex-grow": "1"
    },
    domProps: {
      "innerHTML": _vm._s((_vm$data = _vm.data) === null || _vm$data === void 0 ? void 0 : _vm$data.title)
    }
  }), _c('c-flex', {
    attrs: {
      "position": "relative",
      "flex-shrink": "0",
      "flex-direction": "column",
      "justify-content": "center"
    }
  }, [(_vm$data2 = _vm.data) !== null && _vm$data2 !== void 0 && _vm$data2.image ? _c('c-image', {
    attrs: {
      "src": (_vm$data3 = _vm.data) === null || _vm$data3 === void 0 ? void 0 : _vm$data3.image,
      "height": ['22px', '50px'],
      "margin-bottom": ['-1px', '-4px'],
      "alt": "pedoman jajan"
    }
  }) : _vm._e(), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "min-width": ['120px', '290px'],
      "padding-inline": ['12px', '16px'],
      "padding-block": ['5px', '10px'],
      "border-radius": ['12px', '50px'],
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "height": ['24px', '50px'],
      "is-loading": _vm.isLoading
    },
    on: {
      "click": _vm.onClickCta
    }
  }, [_vm._v(" " + _vm._s((_vm$data4 = _vm.data) === null || _vm$data4 === void 0 ? void 0 : _vm$data4.cta) + " ")])], 1)], 1)], 1) : _vm._e();
}
var staticRenderFns = []

export { render, staticRenderFns }