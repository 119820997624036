import { reqNutritionist_mealPlans_preview } from '@/requests/dietela-api/nutritionist/other'

export const namespaced = true

export const state = () => ({
  preview: {},
})

export const getters = {
  isValidTabNutritionalProfile: (state) => {
    return state.preview?.nutritionalConditions?.validation === 1 && state.preview?.nutritionalIntake?.validation === 1 && state.preview?.bodyConditions?.validation === 1 && state.preview?.targetAndSuggestions?.validation === 1
  },
  isValidTabMealPlan: (state) => {
    return state.preview?.mealPlanSchedules?.every((v) => v.validation === 1)
  },
}

export const mutations = {
  setPreview(state, data) {
    state.preview = data
  },
}

export const actions = {
  async fetchPreview(ctx, props) {
    const res = await reqNutritionist_mealPlans_preview(ctx.rootGetters.axios, {
      clientId: props.clientId || 'xxx',
      programId: props.programId || 'xxx',
      month: props.month || 'xxx',
    }).then((r) => r.data.data)

    ctx.commit('setPreview', res)
  },
}
