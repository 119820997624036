import { render, staticRenderFns } from "./base-input-autocomplete-2.vue?vue&type=template&id=b8cfea22&scoped=true&"
import script from "./base-input-autocomplete-2.vue?vue&type=script&lang=js&"
export * from "./base-input-autocomplete-2.vue?vue&type=script&lang=js&"
import style0 from "./base-input-autocomplete-2.vue?vue&type=style&index=0&id=b8cfea22&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b8cfea22",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CInput: require('@chakra-ui/vue').CInput, CSpinner: require('@chakra-ui/vue').CSpinner, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CFlex: require('@chakra-ui/vue').CFlex})
