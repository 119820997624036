import Vue from 'vue'
import Vuex from 'vuex'
import axios from 'axios'
import VuexPersistence from 'vuex-persist'

import * as mixinsUseRequest from './mixins-use-request'
import * as auth from './auth'
import * as cart from './cart'
import * as checkout from './checkout'
import * as notification from './notification'
import * as profile from './profile'
import * as dataDiet from './data-diet'
import * as mealPlan from './meal-plan'
import * as profileProgram from './profile-program'
import * as profilePembelian from './profile-pembelian'
// import * as rencanaMakan from './rencana-makan';
import * as clients from './clients'
import * as glossary from './glossary'
import * as kuisioner from './kuisioner'
import * as contentChallenge from './content-challenge'
import * as localConfig from './local-config'
import * as general from './general'
import * as clientProgressTracker from './client/progress-tracker'
import * as followUpNotes from './client/follow-up-notes.js'
import * as footer from './footer'
import * as referral from './referral'

// nutritionist
import * as nutriProgressTracker from './nutri/progress-tracker'
import * as nutriFollowUpNotes from './nutri/follow-up-notes.js'
import * as nutriAdimeNote from './nutri/adime-notes.js'

// superadmin
import * as suNutritionists from './superadmin/nutritionists'
import * as suAdmins from './superadmin/admins'
import * as suManagementClient from './superadmin/management-client'
import * as suManagementProgram from './superadmin/diet-program'
import * as suDashboard from './superadmin/dashboard'
import * as suKuisioner from './superadmin/kuisioner'
import * as suManagementNotifikasi from './superadmin/notifikasi'
import * as suGlossary from './superadmin/glossary'
import * as suCoupons from './superadmin/coupons'
import * as suLogActivity from './superadmin/log-activity'
import * as suProgressTracker from './superadmin/progress-tracker'
import * as suFollowUpNotes from './superadmin/follow-up-notes'

// role admin
import * as admDashboard from './admin/dashboard'
import * as admManagementProgram from './admin/diet-program'
import * as admContentChallenge from './admin/content-challenge'
import * as admNutritionists from './admin/nutritionists'
import * as admKuisioner from './admin/kuisioner'
import * as admCoupons from './admin/coupons'
import * as admClients from './admin/clients'
import * as admLogActivity from './admin/log-activity'
import * as admNotifikasi from './admin/notifikasi'
import * as admGlossary from './admin/glossary'
import * as admProgressTracker from './admin/progress-tracker'
import * as admFollowUpNotes from './admin/follow-up-notes'
import * as userType from './user-type'
import * as generalPersist from './general-persist'
import * as mealPlan2 from '@/components/meal-plan/_stores/meal-plan'

import { createVuexHelpers } from 'vue2-helpers'
import { ENV } from '@/constants/config'
import posthog from 'posthog-js'
import Cookies from 'js-cookie'
import { getAuthCookieDomain } from '@/utils/get-parent-domain'

const persistence = new VuexPersistence({
  key: 'dietela-vuex-store',
  reducer(s) {
    return {
      localConfig: s.localConfig,
      kuisioner: s.kuisioner,
      footer: s.footer,
      userType: s.userType,
      generalPersist: s.generalPersist,
    }
  },
})

const persistence2 = new VuexPersistence({
  key: 'dietela-auth-cookie',
  restoreState: (key) => {
    const data = {
      auth: {
        'user': {
          'id': Cookies.get(key + '-id') || '',
          'firstName': Cookies.get(key + '-firstname') || '',
          'lastName': Cookies.get(key + '-lastname') || '',
          'email': Cookies.get(key + '-email') || '',
          'birthDate': Cookies.get(key + '-birthdate') || '',
          'status': Cookies.get(key + '-status') || '',
          'photoUrl': Cookies.get(key + '-photourl') || '',
          'role': Cookies.get(key + '-role') || '',
          'clientType': Cookies.get(key + '-clienttype') || '',
          'isGoogle': Cookies.get(key + '-isgoogle') || '',
          'kol': Cookies.get(key + '-kol') || '',
          'isKol': Cookies.get(key + '-iskol') || '',
        },
        'token': Cookies.get(key + '-token') || '',
        'refreshToken': Cookies.get(key + '-refresh-token') || '',
        'isGoogle': Cookies.get(key + '-isgoogle') || '',
        'redirect': Cookies.get(key + '-redirect') ? JSON.parse(Cookies.get(key + '-redirect')) : '',
      },
    }
    return data
  },
  saveState: (key, state) => {
    const data = state?.auth?.user || {}
    const data2 = state?.auth || {}
    const domain = getAuthCookieDomain(window?.location?.hostname || '')
    Cookies.set(key +'-id', data.id || '', { domain })
    Cookies.set(key +'-firstname', data.firstName || '', { domain })
    Cookies.set(key +'-lastname', data.lastName || '', { domain })
    Cookies.set(key +'-clienttype', data.clientType || '', { domain })
    Cookies.set(key +'-email', data.email || '', { domain })
    Cookies.set(key +'-isgoogle', data.isGoogle || '', { domain })
    Cookies.set(key +'-iskol', data.isKol || '', { domain })
    Cookies.set(key +'-photourl', data.photoUrl || '', { domain })
    Cookies.set(key +'-role', data.role || '', { domain })
    Cookies.set(key +'-status', data.status || '', { domain })
    Cookies.set(key +'-refresh-token', data2.refreshToken || '', { domain })
    Cookies.set(key +'-token', data2.token || '', { domain })
    // di landingpage harus ditambahkan
    Cookies.set(key +'-birthdate', data.birthDate || '', { domain })
    Cookies.set(key +'-kol', data.kol || '', { domain })
    Cookies.set(key +'-redirect', data2.redirect ? JSON.stringify(data2.redirect) : '', { domain })
  },
  reducer(s) {
    return {
      auth: s.auth,
    }
  },
})

Vue.use(Vuex)

const vuexHelpers = createVuexHelpers()
export const useState = vuexHelpers.useState
export const useActions = vuexHelpers.useActions
export const useGetters = vuexHelpers.useGetters
export const useMutations = vuexHelpers.useMutations

let isRefreshingToken = false
let pendingRequest = null

function refreshToken(store) {
  if (isRefreshingToken && pendingRequest != null) return pendingRequest

  isRefreshingToken = true
  pendingRequest = store.dispatch('auth/refreshToken').then((it) => {
    isRefreshingToken = false
    return it
  })

  return pendingRequest
}
window.invalidateToken = () => {
  let data = JSON.parse(localStorage.getItem('dietela-vuex-store'))
  data.auth.token += '--123'
  data.auth.refreshToken += '--123'
  localStorage.setItem('dietela-vuex-store', JSON.stringify(data))
}

export const store = new Vuex.Store({
  plugins: [persistence.plugin, persistence2.plugin],
  state: {},
  getters: {
    axios(state) {
      /** @type {import('axios').AxiosRequestConfig} */
      let config = {
        // baseURL: 'http://localhost:4001',
        // baseURL: "https://app-api.staging.dietela.io",
        // baseURL: "https://dietela.api.project.skyshi.io",
        baseURL: ENV.URL_DIETELA_BE,
      }

      if (state.auth.token != null) {
        config.headers = config.headers ?? {}
        config.headers['Authorization'] = `Bearer ${state.auth.token}`
      }

      let client = axios.create(config)

      client.interceptors.response.use(undefined, async(err) => {
        let code = err.response?.status
        let message = err.response?.data?.message
          ? Object.keys(err.response?.data?.message).length > 0
            ? JSON.stringify(err.response?.data?.message)
            : err.response?.data?.message
          : ''
        let url = err.request?.responseURL ?? ''
        message = message.toLowerCase()

        // if (code === 403 && message.includes("forbidden")) {
        //   location.replace("/");
        //   return;
        // }

        if (
          message.includes('invalid token') ||
          message.includes('token invalid') ||
          (url.includes('refresh-token') && message.includes('expired')) ||
          (code === 403 && message.includes('already logout'))
        ) {
          store.commit('auth/setUser', null)
          store.commit('auth/setToken', null)
          store.commit('auth/setRefreshToken', null)

          // location.replace(`/login?next=${location.hash.slice(1)}`);
          let hashes = location.hash.slice(1)
          if (hashes.includes('next="')) {
            hashes = hashes.replace(/next="(.*?)"/, `&next=${hashes}`)
          } else {
            hashes = `&next=${hashes}`
          }

          let url = `/auth?tab=login${hashes}`
          let path = window.location.pathname
          if (path !== '/auth' && path !== '/') {
            url += `&redirect=${window.location.pathname}`
          }

          store.dispatch('userType/resetUserType')
          store.commit('resetStore')
          posthog.reset()
          location.replace(url)
          return
        }

        if (message.includes('jwt') || message.includes('invalid signature') || message.includes('expired')) {
          return refreshToken(store).then((token) => {
            err.config.headers['Authorization'] = `Bearer ${token}`
            return client.request(err.config).catch(() => {
              store.commit('auth/setUser', null)
              store.commit('auth/setToken', null)
              store.commit('auth/setRefreshToken', null)

              store.dispatch('userType/resetUserType')
              store.commit('resetStore')
              posthog.reset()
              let url = '/auth?tab=login'
              let path = window.location.pathname
              if (path !== '/auth' && path !== '/') {
                url += `&redirect=${window.location.pathname}`
              }
              location.replace(url)
            })
          }).catch(() => {
            store.commit('auth/setUser', null)
            store.commit('auth/setToken', null)
            store.commit('auth/setRefreshToken', null)

            store.dispatch('userType/resetUserType')
            store.commit('resetStore')
            posthog.reset()
            location.replace('/auth?tab=login')
          })
        }

        return Promise.reject(err)
      })

      return client
    },
    customAxiosCMS() {
      /** @type {import('axios').AxiosRequestConfig} */
      let config = {
        baseURL: ENV.URL_DIETELA_CMS,
      }

      let cms = axios.create(config)

      cms.interceptors.response.use(undefined, async(err) => {
        let message = err.response?.data?.message ?? ''

        return Promise.reject(message)
      })

      return cms
    },
    axiosWordpress() {
      /** @type {import('axios').AxiosRequestConfig} */
      let config = {
        baseURL: `${ENV.URL_DIETELA_WORDPRESS}/wp-json/wp/v2`,
      }
      config.headers = config.headers ?? {}

      let cms = axios.create(config)

      cms.interceptors.response.use(undefined, async(err) => {
        let message = err.response?.data?.message ?? ''

        return Promise.reject(message)
      })

      return cms
    },
  },
  mutations: {
    resetStore(state) {
      // Reset Kuisioner
      state.kuisioner.anthropometryData = {}
      state.kuisioner.dailyActivities = {}
      state.kuisioner.dietAndEatingHistory = {}
      state.kuisioner.foodRecords = {}
      state.kuisioner.generalData = {}
      state.kuisioner.informedConsent = {}
      state.kuisioner.medicalHistory = {}
      state.kuisioner.physicalActivityAbilities = {}
      state.kuisioner.questions = {}
      state.kuisioner.currentStep = 0

      // Reset Clients
      state.clients.isHavingPendingNutritionist = false
      state.clients.isHavingPendingQuisionary = false
      state.clients.isCompletedQuisionary = false
      state.clients.isSkipQuestionnaire = false
      state.clients.isFetchingPrograms = false
      state.clients.programChooseNutritionist = {}
      state.clients.programFillQuestionnaire = {}
    },
  },
  modules: {
    mixinsUseRequest,
    auth,
    cart,
    checkout,
    notification,
    profile,
    dataDiet,
    profileProgram,
    profilePembelian,
    mealPlan,
    mealPlan2,
    // rencanaMakan,
    clients,
    glossary,
    kuisioner,
    contentChallenge,
    localConfig,
    general,
    clientProgressTracker,
    followUpNotes,
    footer,
    referral,
    //
    suNutritionists,
    suAdmins,
    suManagementClient,
    suManagementProgram,
    suDashboard,
    suKuisioner,
    suManagementNotifikasi,
    suCoupons,
    suGlossary,
    suLogActivity,
    suProgressTracker,
    suFollowUpNotes,

    // role admin
    admDashboard,
    admManagementProgram,
    admContentChallenge,
    admNutritionists,
    admKuisioner,
    admCoupons,
    admClients,
    admLogActivity,
    admNotifikasi,
    admGlossary,
    admProgressTracker,
    admFollowUpNotes,

    // role nutritionist
    nutriProgressTracker,
    nutriFollowUpNotes,
    nutriAdimeNote,

    userType,
    generalPersist,
  },
})
