export const mealTileIdToLabel = {
  'breakfast': 'Sarapan',
  'morningSnack': 'Snack Pagi',
  'lunch': 'Makan Siang',
  'afternoonSnack': 'Snack Siang/Sore',
  'dinner': 'Makan Malam',
  'nightSnack': 'Snack Malam',
}

export const mealTileIdToImageUrl = {
  'breakfast': 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/sarapan.webp?updatedAt=1722506012077',
  'morningSnack': 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/snack%20pagi.webp?updatedAt=1722506011937',
  'lunch': 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/makan%20siang.webp?updatedAt=1722506011672',
  'afternoonSnack': 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/snack%20siang.webp?updatedAt=1722506012118',
  'dinner': 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/makan%20malam.webp?updatedAt=1722506011877',
  'nightSnack': 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/snack%20malam.webp?updatedAt=1722506011658',
}
