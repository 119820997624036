var render = function () {
  var _vm$data, _vm$data$mealPlanSche, _vm$selectedFilter, _vm$parseData$_vm$sel, _vm$selectedFilter2, _vm$parseData$_vm$sel2, _vm$selectedFilter3, _vm$parseData$_vm$sel3, _vm$selectedFilter4, _vm$selectedFilter5, _vm$parseData$_vm$sel4, _vm$selectedFilter6, _vm$parseData$_vm$sel5, _vm$selectedFilter7, _vm$selectedMenu, _vm$selectedMenu11, _vm$selectedMenu12, _vm$selectedMenu13, _vm$selectedMenu14, _vm$selectedMenu15, _vm$recommendationMea2, _vm$selectedMenu17;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "position": "relative",
      "width": "100%",
      "height": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "margin-inline": "auto",
      "background-color": ['#F2F2F2', '#FFF'],
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['0px', '16px'],
      "padding": ['1rem 1rem 80px 1rem', '1.5rem'],
      "min-height": ['calc(100vh - 62px)', 'auto']
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": _vm.breadcrumbLabel
    }
  })], 1), _vm.isLoadingData ? _c('c-box', [_c('c-flex', {
    attrs: {
      "width": "100%",
      "flex-direction": "column",
      "justify-content": "center",
      "align-items": "center",
      "padding": "128px 24px"
    }
  }, [_c('c-spinner', {
    attrs: {
      "thickness": "4px",
      "speed": "0.65s",
      "empty-color": "green.200",
      "color": "primary.400",
      "size": "xl",
      "margin-bottom": "16px"
    }
  }), _c('c-text', {
    attrs: {
      "color": "primary.400",
      "font-weight": "500"
    }
  }, [_vm._v(" Memuat data Rencana Makan... ")])], 1)], 1) : !((_vm$data = _vm.data) !== null && _vm$data !== void 0 && (_vm$data$mealPlanSche = _vm$data.mealPlanSchedules) !== null && _vm$data$mealPlanSche !== void 0 && _vm$data$mealPlanSche.length) ? _c('EmptyState', {
    attrs: {
      "title": "Rencana makanmu masih kosong",
      "description": "Ahli gizi kami masih merencanakan panduan makan terbaik buat kamu. Mohon ditunggu, ya!",
      "button-text": "Beranda",
      "button-route": "client.index"
    }
  }) : _c('c-box', {
    attrs: {
      "width": "100%",
      "background-color": ['#FFF', 'transparent'],
      "padding": ['16px', '0px'],
      "margin-bottom": "auto"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "justify-content": "space-between",
      "margin-bottom": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-grow": "1",
      "gap": "16px",
      "align-items": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "display": ['none', 'flex'],
      "variant": "ghost",
      "padding": "0px",
      "width": "30px",
      "height": "30px",
      "min-width": "30x",
      "align-items": "center"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.back();
      }
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-chevron-left.svg'),
      "height": "20px",
      "width": "20px"
    }
  })], 1), _c('c-text', {
    attrs: {
      "font-size": ['18px', '20px'],
      "font-weight": "600",
      "color": "neutral.superDarkGray"
    }
  }, [_vm._v(" " + _vm._s((_vm$selectedFilter = _vm.selectedFilter) === null || _vm$selectedFilter === void 0 ? void 0 : _vm$selectedFilter.label) + " - " + _vm._s(((_vm$parseData$_vm$sel = _vm.parseData[(_vm$selectedFilter2 = _vm.selectedFilter) === null || _vm$selectedFilter2 === void 0 ? void 0 : _vm$selectedFilter2.id]) === null || _vm$parseData$_vm$sel === void 0 ? void 0 : _vm$parseData$_vm$sel.caloriesAmount) || 0) + "kkal ")])], 1), _c('c-text', {
    attrs: {
      "font-size": ['14px', '18px'],
      "color": "neutral.888888"
    }
  }, [_vm._v(" " + _vm._s(_vm.formatTime((_vm$parseData$_vm$sel2 = _vm.parseData[(_vm$selectedFilter3 = _vm.selectedFilter) === null || _vm$selectedFilter3 === void 0 ? void 0 : _vm$selectedFilter3.id]) === null || _vm$parseData$_vm$sel2 === void 0 ? void 0 : _vm$parseData$_vm$sel2.startTime)) + " - " + _vm._s(_vm.formatTime((_vm$parseData$_vm$sel3 = _vm.parseData[(_vm$selectedFilter4 = _vm.selectedFilter) === null || _vm$selectedFilter4 === void 0 ? void 0 : _vm$selectedFilter4.id]) === null || _vm$parseData$_vm$sel3 === void 0 ? void 0 : _vm$parseData$_vm$sel3.endTime)) + " ")])], 1), _c('c-box', {
    staticClass: "client_meal-plan_meal-plan-schedule_detail_2",
    class: _vm.isTourActive ? 'pointer-events-none' : ''
  }, [_c('ChipFilter', {
    attrs: {
      "options": _vm.optionFilters,
      "selected-filter": _vm.selectedFilter,
      "justify-content": "flex-start",
      "min-width": ['30px', '40px']
    },
    on: {
      "on-change-filter": _vm.onChangeFilter
    }
  })], 1), _vm.parseData[(_vm$selectedFilter5 = _vm.selectedFilter) === null || _vm$selectedFilter5 === void 0 ? void 0 : _vm$selectedFilter5.id] ? _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [!((_vm$parseData$_vm$sel4 = _vm.parseData[(_vm$selectedFilter6 = _vm.selectedFilter) === null || _vm$selectedFilter6 === void 0 ? void 0 : _vm$selectedFilter6.id]) !== null && _vm$parseData$_vm$sel4 !== void 0 && _vm$parseData$_vm$sel4.isSkipped) ? _c('AccordionCustom', _vm._l((_vm$parseData$_vm$sel5 = _vm.parseData[(_vm$selectedFilter7 = _vm.selectedFilter) === null || _vm$selectedFilter7 === void 0 ? void 0 : _vm$selectedFilter7.id]) === null || _vm$parseData$_vm$sel5 === void 0 ? void 0 : _vm$parseData$_vm$sel5.mealComposition, function (item, idx) {
    var _item$mealItems, _item$additionalMealI;

    return _c('AccordionCustomItem', {
      key: idx,
      staticClass: "client_meal-plan_meal-plan-schedule_detail_3",
      attrs: {
        "label": item === null || item === void 0 ? void 0 : item.foodGroup,
        "sub-label": "".concat(item === null || item === void 0 ? void 0 : item.portion, " porsi"),
        "is-disabled": false,
        "default-is-open": ""
      }
    }, [(item === null || item === void 0 ? void 0 : (_item$mealItems = item.mealItems) === null || _item$mealItems === void 0 ? void 0 : _item$mealItems.length) >= 1 || (item === null || item === void 0 ? void 0 : (_item$additionalMealI = item.additionalMealItems) === null || _item$additionalMealI === void 0 ? void 0 : _item$additionalMealI.length) >= 1 ? _c('c-box', {
      staticClass: "meal-plan-panel"
    }, [_c('c-text', {
      attrs: {
        "font-size": ['12px', '16px'],
        "color": "primary.400"
      }
    }, [_vm._v(" Pilihan golongan makanan ")]), _c('c-grid', {
      class: _vm.isTourActive ? 'pointer-events-none' : '',
      attrs: {
        "margin-top": "8px",
        "template-columns": ['repeat(3, 1fr)', 'repeat(5, 1fr)'],
        "align-items": "flex-start",
        "gap": ['2', '6']
      }
    }, [_vm._l(item === null || item === void 0 ? void 0 : item.mealItems, function (mealItem) {
      return _c('c-flex', {
        key: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id,
        attrs: {
          "flex-direction": "column",
          "justify-content": "center",
          "align-items": "center",
          "w": "100%",
          "min-height": "10px",
          "margin-bottom": "auto",
          "background-color": "neutral.white",
          "border-radius": "8px",
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return _vm.onOpenDetailMenu(mealItem, item === null || item === void 0 ? void 0 : item.portionDecimal, true);
          }
        }
      }, [_c('c-box', {
        attrs: {
          "position": "relative",
          "border-radius": "8px",
          "object-fit": "cover",
          "overflow": "hidden",
          "margin-bottom": "4px",
          "box-shadow": "0px 0px 8px 5px #0000001F"
        }
      }, [_c('c-image', {
        attrs: {
          "src": (mealItem === null || mealItem === void 0 ? void 0 : mealItem.photoUrl) || 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
          "object-fit": "cover",
          "size": "72px"
        }
      }), _c('c-box', {
        attrs: {
          "position": "absolute",
          "width": "100%",
          "bottom": "0",
          "background-color": "#88888899",
          "color": "#FFF",
          "font-size": "10px",
          "text-align": "center"
        }
      }, [_vm._v(" Lihat detail ")])], 1), _c('c-text', {
        attrs: {
          "font-size": "14px",
          "text-align": "center"
        }
      }, [_vm._v(" " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.foodName) + " ")]), _c('c-text', {
        attrs: {
          "color": "neutral.888888",
          "font-size": ['12px', '14px'],
          "text-align": "center"
        }
      }, [_vm._v(" " + _vm._s(_vm.calcHouseholdMeasurement(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementPerPortion, item === null || item === void 0 ? void 0 : item.portionDecimal)) + " " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementUnitPerPortion) + " " + _vm._s(mealItem !== null && mealItem !== void 0 && mealItem.servingSizePerPortion ? _vm.calcServingSize(item === null || item === void 0 ? void 0 : item.portionDecimal, mealItem === null || mealItem === void 0 ? void 0 : mealItem.servingSizePerPortion, mealItem === null || mealItem === void 0 ? void 0 : mealItem.servingSizeUnitPerPortion) : '') + " ")])], 1);
    }), _vm._l(item === null || item === void 0 ? void 0 : item.additionalMealItems, function (mealItem) {
      return _c('c-flex', {
        key: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id,
        attrs: {
          "flex-direction": "column",
          "justify-content": "center",
          "align-items": "center",
          "w": "100%",
          "min-height": "10px",
          "border-radius": "8px",
          "cursor": "pointer"
        },
        on: {
          "click": function click($event) {
            return _vm.onOpenDetailMenu(mealItem, 0, false);
          }
        }
      }, [_c('c-box', {
        attrs: {
          "position": "relative",
          "border-radius": "8px",
          "object-fit": "cover",
          "overflow": "hidden",
          "margin-bottom": "4px",
          "box-shadow": "0px 0px 8px 5px #0000001F"
        }
      }, [_c('c-image', {
        attrs: {
          "src": "'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250'",
          "object-fit": "cover",
          "size": "72px"
        }
      }), _c('c-box', {
        attrs: {
          "position": "absolute",
          "width": "100%",
          "bottom": "0",
          "background-color": "#88888899",
          "color": "#FFF",
          "font-size": "10px",
          "text-align": "center"
        }
      }, [_vm._v(" Lihat detail ")])], 1), _c('c-text', {
        attrs: {
          "font-size": "14px"
        }
      }, [_vm._v(" " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.foodName) + " ")]), _c('c-text', {
        attrs: {
          "color": "neutral.888888",
          "font-size": ['12px', '14px']
        }
      }, [_vm._v(" " + _vm._s(_vm.calcHouseholdMeasurement(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurement, item === null || item === void 0 ? void 0 : item.portionDecimal)) + " " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementUnit) + " " + _vm._s(mealItem !== null && mealItem !== void 0 && mealItem.servingSizePerPortion ? _vm.calcServingSize(item === null || item === void 0 ? void 0 : item.portionDecimal, mealItem === null || mealItem === void 0 ? void 0 : mealItem.servingSizePerPortion, mealItem === null || mealItem === void 0 ? void 0 : mealItem.servingSizeUnitPerPortion) : '') + " ")])], 1);
    })], 2)], 1) : _c('NoData', {
      attrs: {
        "text": "Tidak ada data"
      }
    })], 1);
  }), 1) : _c('NoData', {
    attrs: {
      "text": "Jam makan diskip"
    }
  }), _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": ['34px', '50px'],
      "border-radius": "50px",
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "padding-inline": "8px",
      "gap": ['8px', '20px'],
      "is-disabled": _vm.isFirstStep
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span",
      "flex-shrink": "0",
      "display": ['block', 'none'],
      "width": ['18px', '20px'],
      "height": ['18px', '20px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-left.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1), _vm._v(" Sebelumnya ")], 1), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": ['34px', '50px'],
      "border-radius": "50px",
      "font-size": ['16px', '18px'],
      "font-weight": "500",
      "padding-inline": "8px",
      "gap": ['8px', '20px'],
      "is-disabled": _vm.isLastStep
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya "), _c('c-box', {
    attrs: {
      "as": "span",
      "display": ['block', 'none'],
      "flex-shrink": "0",
      "width": ['18px', '20px'],
      "height": ['18px', '20px']
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-right.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#FFFFFF"
    }
  })], 1)], 1)], 1)], 1) : _c('NoData', {
    attrs: {
      "text": "Tidak ada data"
    }
  })], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenDetailMenu && !['sm', 'md'].includes(_vm.currentBreakpoint),
      "close-on-overlay-click": false,
      "with-button-close": false,
      "has-footer": (_vm$selectedMenu = _vm.selectedMenu) !== null && _vm$selectedMenu !== void 0 && _vm$selectedMenu.isFromMealGlossary ? true : false,
      "size": "700px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "position": "relative",
            "width": "100%",
            "justify-content": "center",
            "align-items": "center",
            "padding-block": ['12px', '16px'],
            "border-bottom-width": "1px"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['18px', '24px'],
            "font-weight": "600",
            "color": "neutral.black",
            "text-align": "center"
          }
        }, [_vm._v(" Detail Menu ")]), _c('c-button', {
          attrs: {
            "position": "absolute",
            "right": ['12px', '20px'],
            "variant": "ghost",
            "width": "30px",
            "min-width": "30px",
            "height": "30px",
            "margin": "0",
            "padding": "0"
          },
          on: {
            "click": _vm.onCloseDetailMenu
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "close",
            "size": "16px"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$selectedMenu2, _vm$selectedMenu3, _vm$selectedMenu4, _vm$selectedMenu5, _vm$selectedMenu6, _vm$selectedMenu7, _vm$recommendationMea;

        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "max-height": "640px",
            "overflow": "auto"
          }
        }, [_c('c-box', {
          attrs: {
            "margin-inline": "auto",
            "max-width": "700px",
            "padding-inline": ['10px', '20px'],
            "padding-bottom": "16px"
          }
        }, [_c('c-flex', {
          attrs: {
            "flex-direction": "column",
            "width": "100%",
            "padding-block": "16px",
            "align-items": "center",
            "justify-content": "center",
            "gap": "12px",
            "border-bottom-width": (_vm$selectedMenu2 = _vm.selectedMenu) !== null && _vm$selectedMenu2 !== void 0 && _vm$selectedMenu2.isFromMealGlossary ? '1px' : '0px',
            "border-bottom-color": "#E2E8F0",
            "border-bottom-style": "solid"
          }
        }, [_c('c-box', {
          attrs: {
            "position": "relative",
            "border-radius": "8px",
            "object-fit": "cover",
            "overflow": "hidden",
            "margin-bottom": "4px",
            "box-shadow": "0px 0px 8px 3px #0000001F"
          }
        }, [_c('c-image', {
          attrs: {
            "src": _vm.selectedMenu ? (_vm$selectedMenu3 = _vm.selectedMenu) === null || _vm$selectedMenu3 === void 0 ? void 0 : _vm$selectedMenu3.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
            "width": ['220px', '300px'],
            "height": ['220px', '300px'],
            "object-fit": "cover",
            "object-position": "center"
          }
        })], 1), _c('c-flex', {
          attrs: {
            "gap": "6px",
            "justify-content": "center",
            "align-items": "flex-end"
          }
        }, [_c('c-box', {
          attrs: {
            "font-size": ['14px', '18px'],
            "font-weight": "500",
            "color": "neutral.superDarkGray"
          }
        }, [_vm._v(" " + _vm._s((_vm$selectedMenu4 = _vm.selectedMenu) === null || _vm$selectedMenu4 === void 0 ? void 0 : _vm$selectedMenu4.foodName) + " ")]), _c('c-box', {
          attrs: {
            "font-size": ['12px', '16px'],
            "color": "neutral.888888"
          }
        }, [_vm._v(" " + _vm._s((_vm$selectedMenu5 = _vm.selectedMenu) === null || _vm$selectedMenu5 === void 0 ? void 0 : _vm$selectedMenu5.portionDetail) + " " + _vm._s((_vm$selectedMenu6 = _vm.selectedMenu) === null || _vm$selectedMenu6 === void 0 ? void 0 : _vm$selectedMenu6.servingSizeDetail) + " ")])], 1)], 1), (_vm$selectedMenu7 = _vm.selectedMenu) !== null && _vm$selectedMenu7 !== void 0 && _vm$selectedMenu7.isFromMealGlossary ? _c('c-box', {
          attrs: {
            "margin-top": "16px"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": ['12px', '16px'],
            "color": "primary.400"
          }
        }, [_vm._v(" Pilihan Jenis Makanan ")]), _vm.isLoadingRecommendationMealGlossary ? _c('c-grid', {
          attrs: {
            "margin-top": "8px",
            "template-columns": ['repeat(4, 1fr)'],
            "gap": ['2', '4']
          }
        }, _vm._l(4, function (item) {
          return _c('c-flex', {
            key: item,
            attrs: {
              "flex-direction": "column",
              "justify-content": "center",
              "align-items": "center",
              "w": "100%",
              "min-height": "10px",
              "margin-bottom": "auto",
              "cursor": "wait",
              "background": "neutral.white",
              "border-radius": "8px",
              "_hover": {
                transition: 'background 0.25s',
                background: 'neutral.superLightGray'
              }
            }
          }, [_c('c-box', {
            attrs: {
              "position": "relative"
            }
          }, [_c('c-box', {
            attrs: {
              "width": ['72px'],
              "height": ['72px'],
              "mb": "4px",
              "border-radius": "6px",
              "margin-bottom": "4px"
            }
          }, [_c('vue-skeleton-loader', {
            attrs: {
              "type": "rect",
              "width": "100%",
              "height": "100%",
              "animation": "fade",
              "rounded": ""
            }
          })], 1)], 1), _c('c-box', {
            attrs: {
              "w": ['80%'],
              "h": ['16px', '18px'],
              "mb": "4px"
            }
          }, [_c('vue-skeleton-loader', {
            attrs: {
              "type": "rect",
              "width": "100%",
              "height": "100%",
              "animation": "fade",
              "rounded": ""
            }
          })], 1), _c('c-box', {
            attrs: {
              "w": ['40%'],
              "h": ['16px', '18px']
            }
          }, [_c('vue-skeleton-loader', {
            attrs: {
              "type": "rect",
              "width": "100%",
              "height": "100%",
              "animation": "fade",
              "rounded": ""
            }
          })], 1)], 1);
        }), 1) : ((_vm$recommendationMea = _vm.recommendationMealGlossary) === null || _vm$recommendationMea === void 0 ? void 0 : _vm$recommendationMea.length) > 0 ? _c('c-grid', {
          attrs: {
            "margin-top": "8px",
            "template-columns": ['repeat(4, 1fr)'],
            "align-items": "flex-start",
            "gap": ['2', '4']
          }
        }, _vm._l(_vm.recommendationMealGlossary, function (mealItem) {
          var _vm$selectedMenu8, _vm$selectedMenu9;

          return _c('c-flex', {
            key: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id,
            attrs: {
              "flex-direction": "column",
              "justify-content": "center",
              "align-items": "center",
              "w": "100%",
              "min-height": "10px",
              "margin-bottom": "auto",
              "cursor": "pointer",
              "background": "neutral.white",
              "border-radius": "8px",
              "_hover": {
                transition: 'background 0.25s',
                background: 'neutral.superLightGray'
              }
            },
            on: {
              "click": function click($event) {
                return _vm.$router.push({
                  name: 'client.glossaries-detail',
                  params: {
                    glossaryId: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id
                  }
                });
              }
            }
          }, [_c('c-box', {
            attrs: {
              "position": "relative",
              "border-radius": "8px",
              "object-fit": "cover",
              "overflow": "hidden",
              "margin-bottom": "4px",
              "box-shadow": "0px 0px 8px 3px #0000001F"
            }
          }, [_c('c-image', {
            attrs: {
              "src": (mealItem === null || mealItem === void 0 ? void 0 : mealItem.photoUrl) || 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
              "object-fit": "cover",
              "size": "72px"
            }
          })], 1), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.foodName) + " ")]), _c('c-text', {
            attrs: {
              "color": "neutral.888888",
              "font-size": ['12px', '14px'],
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(_vm.calcHouseholdMeasurement(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementPerPortion, (_vm$selectedMenu8 = _vm.selectedMenu) === null || _vm$selectedMenu8 === void 0 ? void 0 : _vm$selectedMenu8.portionDecimal)) + " " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementUnitPerPortion) + " " + _vm._s(mealItem !== null && mealItem !== void 0 && mealItem.servingSizePerPortion ? _vm.calcServingSize((_vm$selectedMenu9 = _vm.selectedMenu) === null || _vm$selectedMenu9 === void 0 ? void 0 : _vm$selectedMenu9.portionDecimal, mealItem === null || mealItem === void 0 ? void 0 : mealItem.servingSizePerPortion, mealItem === null || mealItem === void 0 ? void 0 : mealItem.servingSizeUnitPerPortion) : '') + " ")])], 1);
        }), 1) : _c('c-box', {
          attrs: {
            "text-align": "center",
            "padding-block": "20px",
            "font-size": ['14px', '16px'],
            "font-weight": "400",
            "color": "neutral.888888"
          }
        }, [_vm._v(" Tidak ada data jenis makanan terkait. ")])], 1) : _vm._e()], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-inline": "16px",
            "padding-bottom": "16px",
            "text-align": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "variant": "outlined",
            "min-width": "250px"
          },
          on: {
            "click": function click($event) {
              var _vm$selectedMenu10;

              return _vm.$router.push({
                name: 'client.glossaries-detail',
                params: {
                  glossaryId: (_vm$selectedMenu10 = _vm.selectedMenu) === null || _vm$selectedMenu10 === void 0 ? void 0 : _vm$selectedMenu10.id
                }
              });
            }
          }
        }, [_vm._v(" Lihat Informasi Nilai Gizi ")])], 1)];
      },
      proxy: true
    }])
  }), _c('c-drawer', {
    attrs: {
      "placement": "bottom",
      "on-close": _vm.onCloseDetailMenu,
      "is-open": _vm.isOpenDetailMenu && ['sm', 'md'].includes(_vm.currentBreakpoint)
    }
  }, [_c('c-drawer-overlay'), _c('c-drawer-content', {
    attrs: {
      "border-top-left-radius": "16px",
      "border-top-right-radius": "16px"
    }
  }, [_c('c-drawer-header', {
    attrs: {
      "border-bottom-width": "1px",
      "padding-inline": "12px",
      "padding-block": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "position": "relative",
      "justify-content": "center",
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['16px', '24px'],
      "font-weight": "500",
      "color": "neutral.black",
      "text-align": "center"
    }
  }, [_vm._v(" Detail Menu ")]), _c('c-button', {
    attrs: {
      "position": "absolute",
      "right": ['0px', '20px'],
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0"
    },
    on: {
      "click": _vm.onCloseDetailMenu
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "close",
      "size": ['13px', '17px']
    }
  })], 1)], 1)], 1), _c('c-drawer-body', {
    attrs: {
      "padding-inline": "12px",
      "padding-block": "0px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "100%",
      "max-height": "640px",
      "overflow": "auto"
    }
  }, [_c('c-box', {
    attrs: {
      "margin-inline": "auto",
      "max-width": "700px",
      "padding-inline": ['0px', '20px'],
      "padding-bottom": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "width": "100%",
      "padding-block": "16px",
      "align-items": "center",
      "justify-content": "center",
      "gap": "12px",
      "border-bottom-width": (_vm$selectedMenu11 = _vm.selectedMenu) !== null && _vm$selectedMenu11 !== void 0 && _vm$selectedMenu11.isFromMealGlossary ? '1px' : '0px',
      "border-bottom-color": "#E2E8F0",
      "border-bottom-style": "solid"
    }
  }, [_c('c-box', {
    attrs: {
      "position": "relative",
      "border-radius": "8px",
      "object-fit": "cover",
      "overflow": "hidden",
      "margin-bottom": "4px",
      "box-shadow": "0px 0px 8px 3px #0000001F"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.selectedMenu ? (_vm$selectedMenu12 = _vm.selectedMenu) === null || _vm$selectedMenu12 === void 0 ? void 0 : _vm$selectedMenu12.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
      "width": ['220px', '300px'],
      "height": ['220px', '300px'],
      "object-fit": "cover",
      "object-position": "center"
    }
  })], 1), _c('c-flex', {
    attrs: {
      "gap": "6px",
      "justify-content": "center",
      "align-items": "flex-end"
    }
  }, [_c('c-box', {
    attrs: {
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "neutral.superDarkGray"
    }
  }, [_vm._v(" " + _vm._s((_vm$selectedMenu13 = _vm.selectedMenu) === null || _vm$selectedMenu13 === void 0 ? void 0 : _vm$selectedMenu13.foodName) + " ")]), _c('c-box', {
    attrs: {
      "font-size": ['12px', '16px'],
      "color": "neutral.888888"
    }
  }, [_vm._v(" " + _vm._s((_vm$selectedMenu14 = _vm.selectedMenu) === null || _vm$selectedMenu14 === void 0 ? void 0 : _vm$selectedMenu14.portionDetail) + " ")])], 1)], 1), (_vm$selectedMenu15 = _vm.selectedMenu) !== null && _vm$selectedMenu15 !== void 0 && _vm$selectedMenu15.isFromMealGlossary ? _c('c-box', {
    attrs: {
      "margin-top": "16px"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "color": "primary.400"
    }
  }, [_vm._v(" Pilihan Jenis Makanan ")]), _vm.isLoadingRecommendationMealGlossary ? _c('c-grid', {
    attrs: {
      "margin-top": "8px",
      "template-columns": ['repeat(4, 1fr)'],
      "gap": ['2', '4']
    }
  }, _vm._l(4, function (item) {
    return _c('c-flex', {
      key: item,
      attrs: {
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "w": "100%",
        "min-height": "10px",
        "margin-bottom": "auto",
        "cursor": "wait",
        "background": "neutral.white",
        "border-radius": "8px",
        "_hover": {
          transition: 'background 0.25s',
          background: 'neutral.superLightGray'
        }
      }
    }, [_c('c-box', {
      attrs: {
        "position": "relative"
      }
    }, [_c('c-box', {
      attrs: {
        "width": ['72px'],
        "height": ['72px'],
        "mb": "4px",
        "border-radius": "6px",
        "margin-bottom": "4px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1), _c('c-box', {
      attrs: {
        "w": ['80%'],
        "h": ['16px', '18px'],
        "mb": "4px"
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1), _c('c-box', {
      attrs: {
        "w": ['40%'],
        "h": ['16px', '18px']
      }
    }, [_c('vue-skeleton-loader', {
      attrs: {
        "type": "rect",
        "width": "100%",
        "height": "100%",
        "animation": "fade",
        "rounded": ""
      }
    })], 1)], 1);
  }), 1) : ((_vm$recommendationMea2 = _vm.recommendationMealGlossary) === null || _vm$recommendationMea2 === void 0 ? void 0 : _vm$recommendationMea2.length) > 0 ? _c('c-grid', {
    attrs: {
      "margin-top": "8px",
      "template-columns": ['repeat(4, 1fr)'],
      "align-items": "flex-start",
      "gap": ['2', '4']
    }
  }, _vm._l(_vm.recommendationMealGlossary, function (mealItem) {
    var _vm$selectedMenu16;

    return _c('c-flex', {
      key: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id,
      attrs: {
        "flex-direction": "column",
        "justify-content": "center",
        "align-items": "center",
        "w": "100%",
        "min-height": "10px",
        "margin-bottom": "auto",
        "cursor": "pointer",
        "background": "neutral.white",
        "border-radius": "8px",
        "_hover": {
          transition: 'background 0.25s',
          background: 'neutral.superLightGray'
        }
      },
      on: {
        "click": function click($event) {
          return _vm.$router.push({
            name: 'client.glossaries-detail',
            params: {
              glossaryId: mealItem === null || mealItem === void 0 ? void 0 : mealItem.id
            }
          });
        }
      }
    }, [_c('c-box', {
      attrs: {
        "position": "relative",
        "border-radius": "8px",
        "object-fit": "cover",
        "overflow": "hidden",
        "margin-bottom": "4px",
        "box-shadow": "0px 0px 8px 3px #0000001F"
      }
    }, [_c('c-image', {
      attrs: {
        "src": (mealItem === null || mealItem === void 0 ? void 0 : mealItem.photoUrl) || 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
        "object-fit": "cover",
        "size": "72px"
      }
    })], 1), _c('c-text', {
      attrs: {
        "font-size": "14px",
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.foodName) + " ")]), _c('c-text', {
      attrs: {
        "color": "neutral.888888",
        "font-size": ['12px', '14px'],
        "text-align": "center"
      }
    }, [_vm._v(" " + _vm._s(_vm.calcHouseholdMeasurement(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementPerPortion, (_vm$selectedMenu16 = _vm.selectedMenu) === null || _vm$selectedMenu16 === void 0 ? void 0 : _vm$selectedMenu16.portionDecimal)) + " " + _vm._s(mealItem === null || mealItem === void 0 ? void 0 : mealItem.householdMeasurementUnitPerPortion) + " ")])], 1);
  }), 1) : _c('c-box', {
    attrs: {
      "text-align": "center",
      "padding-block": "20px",
      "font-size": ['14px', '16px'],
      "font-weight": "400",
      "color": "neutral.888888"
    }
  }, [_vm._v(" Tidak ada data jenis makanan terkait. ")])], 1) : _vm._e()], 1)], 1), (_vm$selectedMenu17 = _vm.selectedMenu) !== null && _vm$selectedMenu17 !== void 0 && _vm$selectedMenu17.isFromMealGlossary ? _c('c-box', {
    attrs: {
      "width": "100%",
      "padding-inline": "16px",
      "padding-bottom": "16px",
      "text-align": "center"
    }
  }, [_c('BaseButton', {
    attrs: {
      "variant": "outlined",
      "min-width": "250px",
      "height": "42px",
      "border-radius": "42px"
    },
    on: {
      "click": function click($event) {
        var _vm$selectedMenu18;

        return _vm.$router.push({
          name: 'client.glossaries-detail',
          params: {
            glossaryId: (_vm$selectedMenu18 = _vm.selectedMenu) === null || _vm$selectedMenu18 === void 0 ? void 0 : _vm$selectedMenu18.id
          }
        });
      }
    }
  }, [_vm._v(" Lihat Informasi Nilai Gizi ")])], 1) : _vm._e()], 1)], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }