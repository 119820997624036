var render = function () {
  var _vm$duplicationDetail;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('c-flex', {
    attrs: {
      "padding-block": "16px",
      "box-shadow": "0px 4px 8px rgba(0, 0, 0, 0.1)"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "flex-shrink": "0",
      "width": "320px",
      "padding-inline": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "height": ['34px', '50px'],
      "align-items": "center"
    }
  }, [_c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600",
      "margin-right": "auto"
    }
  }, [_vm._v(" Panduan Porsi ")])], 1), _c('c-box', {
    attrs: {
      "overflow-x": "auto",
      "margin-top": "16px"
    }
  }, [_c('ChipFilter', {
    attrs: {
      "options": _vm.optionsPanduanPorsi,
      "selected-filter": _vm.optionsPanduanPorsi[0],
      "justify-content": "flex-start",
      "min-width": ['30px', '40px']
    },
    on: {
      "on-change-filter": function onChangeFilter() {}
    }
  })], 1)], 1), _c('c-divider', {
    attrs: {
      "orientation": "vertical",
      "border-color": "neutral.888888",
      "margin-right": "8px"
    }
  }), _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "width": "100%",
      "padding-right": "16px",
      "padding-left": "8px"
    }
  }, [_c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "justify-content": "space-between"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0",
      "margin-right": "16px"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.menu-recommendation.detail'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "22px",
      "font-weight": "600",
      "margin-right": "auto"
    }
  }, [_vm._v(" Rekomendasi Menu ")]), _c('c-tooltip', {
    attrs: {
      "label": _vm.iconCloud.text,
      "placement": "bottom"
    }
  }, [_c('c-box', {
    class: _vm.saveState === null ? 'animate-spin' : '',
    attrs: {
      "width": ['35px'],
      "height": ['35px'],
      "margin-right": "16px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": _vm.iconCloud.icon,
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('BaseButton', {
    attrs: {
      "left-svg-icon": require('@/assets/icons/icon-save.svg'),
      "left-svg-icon-color": "white",
      "disabled": !_vm.isValidAllFormForAllDays || _vm.saveState === null || _vm.isLoadingSave || _vm.isSaveDraft === 1
    },
    on: {
      "click": _vm.onSave
    }
  }, [_vm._v(" Simpan ")])], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "align-items": "center",
      "gap": "0px",
      "margin-top": "0px"
    }
  }, [_c('ChipDays', {
    attrs: {
      "start-at": _vm.currentActiveDays[0],
      "end-at": _vm.currentActiveDays[_vm.currentActiveDays.length - 1],
      "selected-day": _vm.query.day,
      "invalid-day-validation": _vm.chipDaysState.invalidDayValidation,
      "disabled-day": _vm.isLoadingSave || _vm.saveState === null ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10] : _vm.chipDaysState.disabledDay,
      "dot-badge-days": _vm.chipDaysState.dotBadgeDay,
      "custom-item-width": "100%",
      "custom-item-min-width": "50px",
      "custom-template-columns": "repeat(10, 1fr)"
    },
    on: {
      "on-change-day": function onChangeDay(day) {
        _vm.$router.push(Object.assign({}, _vm.$route, {
          query: Object.assign({}, _vm.$route.query, {
            day: day,
            mealTime: 'breakfast'
          })
        }));
      }
    }
  }), _c('c-flex', {
    attrs: {
      "gap": "8px",
      "flex-shrink": "0"
    }
  }, [_c('ModalMenuDuplicate', {
    attrs: {
      "is-valid-all-form": _vm.isValidAllForm ? true : false,
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null || !_vm.isValidMealTime && !_vm.isSkippedMealTime,
      "meal-time-states": _vm.listMealTimeStates
    },
    on: {
      "fetch-form-days": function fetchFormDays() {
        return _vm.getFormDays();
      }
    }
  }), _c('c-button', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "variant-color": "secondary",
      "color": "primary.400",
      "background-color": "secondary.400",
      "border-radius": "calc(infinity * 1px)",
      "size": "lg",
      "width": "50px",
      "height": "50px",
      "padding": "0",
      "disabled": _vm.isLoadingSave || _vm.saveState === null || !_vm.getIsEditableForm
    },
    on: {
      "click": function click() {
        if (_vm.isLoadingSave || _vm.saveState === null || !_vm.getIsEditableForm) { return; }
        _vm.dataModalCreateBatchMenuRecommendations.isOpen = true;
      }
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span",
      "font-size": "30px",
      "font-weight": "400"
    }
  }, [_vm._v(" + ")])], 1)], 1)], 1)], 1)], 1), _c('c-flex', {
    attrs: {
      "width": "100%",
      "padding-block": "16px"
    }
  }, [_c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "flex-shrink": "0",
      "width": "320px",
      "height": "100%",
      "max-height": "calc(100vh - 275px)",
      "overflow-y": "auto",
      "padding-left": "16px"
    }
  }, [_vm.hasMealComposition ? _c('c-box', [_c('MealTimeHeader', {
    attrs: {
      "meal-time": _vm.constants.mealPlanValueToLabel[_vm.query.mealTime] || '-',
      "calories-amount": _vm.caloriesAmount,
      "start-time": _vm.startTime,
      "end-time": _vm.endTime
    }
  })], 1) : _vm._e(), _vm.hasMealComposition ? _c('c-flex', {
    attrs: {
      "margin-top": "16px",
      "flex-direction": "column",
      "gap": "16px"
    }
  }, _vm._l(_vm.mealComposition, function (item, itemIdx) {
    return _c('CardMealPortionGuide', {
      key: itemIdx,
      attrs: {
        "meal-type": item === null || item === void 0 ? void 0 : item.foodGroup,
        "meal-portion": "".concat((item === null || item === void 0 ? void 0 : item.portion) || '-'),
        "meal-portion-decimal": "".concat((item === null || item === void 0 ? void 0 : item.portionDecimal) || '0'),
        "meal-portion-unit": "Porsi",
        "meal-items": _vm.mergeMealItems(item === null || item === void 0 ? void 0 : item.portionDecimal, item === null || item === void 0 ? void 0 : item.mealItems, item === null || item === void 0 ? void 0 : item.additionalMealItems) || []
      }
    });
  }), 1) : _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "align-items": "center",
      "width": "100%",
      "height": "100%"
    }
  }, [_c('NoData2')], 1)], 1), _c('c-divider', {
    attrs: {
      "orientation": "vertical",
      "border-color": "neutral.888888",
      "margin-right": "8px"
    }
  }), _c('c-box', {
    attrs: {
      "flex-grow": "1",
      "width": "100%",
      "max-height": "calc(100vh - 275px)",
      "overflow-y": "auto",
      "padding-right": "8px",
      "padding-left": "8px"
    }
  }, [_c('LineStep', {
    attrs: {
      "steps": _vm.mealPlanSteps2
    }
  }), _c('c-flex', {
    attrs: {
      "gap": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "width": "238px",
      "flex-shrink": "0"
    }
  }, [_c('c-flex', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" " + _vm._s(_vm.constants.mealPlanValueToLabel[_vm.query.mealTime] || '-') + " ")])], 1), _c('c-text', {
    attrs: {
      "color": "#888888"
    }
  }, [_vm._v(" //Preview Menu Rekomendasi " + _vm._s(_vm.constants.mealPlanValueToLabel[_vm.query.mealTime] || '-') + " ")])], 1), _c('c-box', {
    attrs: {
      "flex-grow": "1"
    }
  }, [_vm.isSkippedMealTime ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info2', {
    attrs: {
      "variant": "warning",
      "text": "Kamu tidak bisa mengisi Rekomendasi menu karena  memilih tidak makan pada waktu makan ini"
    }
  })], 1) : !_vm.isValidTabNutritionalProfile || !_vm.isValidTabMealPlan ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info2', {
    attrs: {
      "variant": "danger",
      "text": "Kamu tidak bisa mengisi Rekomendasi Menu sebelum mengisi Rencana makan"
    }
  })], 1) : _vm._e(), _c('c-box', [_c('c-grid', {
    attrs: {
      "width": "100%",
      "template-columns": "repeat(2, 1fr)",
      "gap": "16px"
    }
  }, [_c('FormTime', {
    attrs: {
      "label": "Jam Mulai Makan",
      "is-required": "",
      "is-disabled": ""
    },
    model: {
      value: _vm.startTime,
      callback: function callback($$v) {
        _vm.startTime = $$v;
      },
      expression: "startTime"
    }
  }), _c('FormTime', {
    attrs: {
      "label": "Jam Akhir Makan",
      "is-required": "",
      "is-disabled": ""
    },
    model: {
      value: _vm.endTime,
      callback: function callback($$v) {
        _vm.endTime = $$v;
      },
      expression: "endTime"
    }
  })], 1), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Kebutuhan Jumlah Kalori",
      "placeholder": "Masukkan alokasi % kalori untuk waktu makan ini",
      "is-required": "",
      "is-disabled": "",
      "right-element": "%"
    },
    model: {
      value: _vm.caloriesNeedPercent,
      callback: function callback($$v) {
        _vm.caloriesNeedPercent = $$v;
      },
      expression: "caloriesNeedPercent"
    }
  }), _c('FormInput', {
    attrs: {
      "type": "number",
      "label": "Kkal - Jumlah Kalori",
      "placeholder": "Jumlah Kalori",
      "is-required": "",
      "is-disabled": "",
      "right-element": "Kkal"
    },
    model: {
      value: _vm.caloriesAmount,
      callback: function callback($$v) {
        _vm.caloriesAmount = $$v;
      },
      expression: "caloriesAmount"
    }
  }), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "16px"
    }
  }, [_c('c-box', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500",
      "color": "primary.400"
    }
  }, [_vm._v(" Data Menu ")]), _vm.duplicationDetail && ((_vm$duplicationDetail = _vm.duplicationDetail) === null || _vm$duplicationDetail === void 0 ? void 0 : _vm$duplicationDetail.isEdited) === 0 ? _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "border-radius": "30px",
      "font-weight": "500",
      "font-size": "18px"
    },
    on: {
      "click": _vm.onOpenEditMenuDuplicate
    }
  }, [_c('c-box', {
    attrs: {
      "as": "span",
      "min-height": ['20px', '22px'],
      "min-width": ['20px', '22px'],
      "height": ['20px', '22px'],
      "width": ['20px', '22px'],
      "margin-right": "8px"
    }
  }, [_c('inline-svg', {
    attrs: {
      "src": require('@/assets/icons/icon-edit-2.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#FFF"
    }
  })], 1), _vm._v(" Edit Menu Duplikasi ")], 1) : _vm._e()], 1), _c('TestFieldArray', {
    scopedSlots: _vm._u([{
      key: "default",
      fn: function fn(_ref) {
        var push = _ref.push,
            remove = _ref.remove;
        return [_vm._l(_vm.targets, function (v, i) {
          return _c('c-box', {
            key: i + 'a',
            attrs: {
              "margin-top": "20px",
              "_first": {
                marginTop: '0px'
              },
              "background-color": "neutral.E1E1E1",
              "padding": "20px"
            }
          }, [_c('c-flex', [i !== 0 ? _c('c-button', {
            attrs: {
              "background-color": "transparent",
              "_hover": {
                backgroundColor: 'neutral.lightGray'
              },
              "disabled": _vm.isDuplicateMealTime,
              "margin-left": "auto"
            },
            on: {
              "click": function click($event) {
                return remove(_vm.targets, i);
              }
            }
          }, [_c('c-box', {
            attrs: {
              "as": "span",
              "margin-right": "16px",
              "font-size": "16px",
              "font-weight": "500",
              "color": "danger.400"
            }
          }, [_vm._v(" Hapus menu ")]), _c('c-box', {
            attrs: {
              "height": "20px",
              "width": "20px"
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icons/icon-trash.svg'),
              "height": "20px",
              "width": "20px",
              "fill": "#D32737"
            }
          })], 1)], 1) : _vm._e()], 1), _c('FormSelect', {
            attrs: {
              "value": v.menuType,
              "width": "100%",
              "label": "Jenis Menu",
              "placeholder": "Pilih Jenis Menu",
              "is-required": "",
              "options": [{
                value: 'singleEntry',
                label: 'Single Entry'
              }, {
                value: 'multipleEntry',
                label: 'Multiple Entry'
              }],
              "is-disabled": !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
              "is-invalid": _vm.isInvalidField(_vm.$v.targets.$each[i].menuType),
              "invalid-text": _vm.isSkippedMealTime ? '' : 'Cek Ulang Data'
            },
            on: {
              "input": function input(val) {
                v.menuType = val;
                v.menuName = null;
                v.menusArray = [{
                  _menuType: val,
                  foodName: null,
                  servingSize: null,
                  servingSizeUnit: null,
                  servingSizeUnitOther: null,
                  servingSizeOtherPerGram: null,
                  photoUrl: null,
                  mealGlossariesId: null
                }];
                v.menusObject = {
                  _menuType: val,
                  servingSize: null,
                  servingSizeUnit: null,
                  servingSizeUnitOther: null,
                  servingSizeOtherPerGram: null,
                  photoUrl: null,
                  mealGlossariesId: null
                };
              },
              "blur": _vm.$v.targets.$each[i].menuType.$touch
            }
          }), v.menuType === 'singleEntry' ? _c('c-flex', {
            attrs: {
              "flex-direction": "column"
            }
          }, [true ? _c('c-flex', {
            attrs: {
              "gap": "20px",
              "width": "100%",
              "align-items": "flex-start"
            }
          }, [_c('c-flex', [_c('InputImage2', {
            attrs: {
              "photo-url": v.menusObject.photoUrl,
              "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm
            },
            on: {
              "delete:photo": function deletePhoto() {
                v.menusObject.photoUrl = null;
                v.menusObject.mealGlossariesId = null;
              },
              "click:photo": function clickPhoto() {
                _vm.popupIsOpen = true;
                _vm.popupItemIndexes = [i];
              }
            }
          })], 1), _c('c-flex', {
            attrs: {
              "width": "100%",
              "flex-direction": "column"
            }
          }, [_c('FormInput', {
            attrs: {
              "label": "Nama Menu",
              "placeholder": "Masukkan Nama Menu",
              "is-required": "",
              "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
              "is-invalid": _vm.isInvalidField(_vm.$v.targets.$each[i].menuName),
              "invalid-text": "Cek Ulang Data"
            },
            on: {
              "blur": _vm.$v.targets.$each[i].menuName.$touch
            },
            model: {
              value: v.menuName,
              callback: function callback($$v) {
                _vm.$set(v, "menuName", $$v);
              },
              expression: "v.menuName"
            }
          }), _c('InputTextWithSelect', {
            attrs: {
              "label": "Jumlah Takaran",
              "input-placeholder": "Masukkan Jumlah Takaran",
              "select-placeholder": "Pilih",
              "input-value": v.menusObject.servingSize,
              "invalid-input-text": "Cek Ulang Data",
              "is-invalid-input": _vm.isInvalidField(_vm.$v.targets.$each[i].menusObject.servingSize),
              "select-value": v.menusObject.servingSizeUnit,
              "invalid-select-text": "Cek Ulang Data",
              "is-invalid-select": _vm.isInvalidField(_vm.$v.targets.$each[i].menusObject.servingSizeUnit),
              "select-other-value": v.menusObject.servingSizeUnitOther,
              "invalid-select-other-text": "Lainnya wajib diisi",
              "is-invalid-select-other": _vm.isInvalidField(_vm.$v.targets.$each[i].menusObject.servingSizeUnitOther),
              "select-options": _vm.optionPortionUnits,
              "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
              "is-without-margin-bottom": ""
            },
            on: {
              "blur:input": _vm.$v.targets.$each[i].menusObject.servingSize.$touch,
              "blur:select": _vm.$v.targets.$each[i].menusObject.servingSizeUnit.$touch,
              "blur:select-other": _vm.$v.targets.$each[i].menusObject.servingSizeUnitOther.$touch,
              "change:input": function changeInput(v_) {
                return v.menusObject.servingSize = v_;
              },
              "change:select": function changeSelect(v_) {
                return v.menusObject.servingSizeUnit = v_;
              },
              "change:select-other": function changeSelectOther(v_) {
                return v.menusObject.servingSizeUnitOther = v_;
              }
            }
          }), _c('c-box', {
            attrs: {
              "height": "8px"
            }
          }), _c('FormInput', {
            attrs: {
              "type": "number",
              "label": "Ukuran Lainnya",
              "placeholder": "Masukkan Ukuran Lainnya",
              "right-element": "Gram",
              "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
              "is-required": null
            },
            model: {
              value: v.menusObject.servingSizeOtherPerGram,
              callback: function callback($$v) {
                _vm.$set(v.menusObject, "servingSizeOtherPerGram", $$v);
              },
              expression: "v.menusObject.servingSizeOtherPerGram"
            }
          })], 1)], 1) : _vm._e()], 1) : v.menuType === 'multipleEntry' ? _c('TestFieldArray', {
            scopedSlots: _vm._u([{
              key: "default",
              fn: function fn(_ref2) {
                var push2 = _ref2.push,
                    remove2 = _ref2.remove;
                return [_c('FormInput', {
                  attrs: {
                    "label": "Nama Menu",
                    "placeholder": "Masukkan Nama Menu",
                    "is-required": "",
                    "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
                    "is-invalid": _vm.isInvalidField(_vm.$v.targets.$each[i].menuName),
                    "invalid-text": "Cek Ulang Data"
                  },
                  on: {
                    "blur": _vm.$v.targets.$each[i].menuName.$touch
                  },
                  model: {
                    value: v.menuName,
                    callback: function callback($$v) {
                      _vm.$set(v, "menuName", $$v);
                    },
                    expression: "v.menuName"
                  }
                }), _vm._l(v.menusArray, function (v2, i2) {
                  return _c('c-flex', {
                    key: i2,
                    attrs: {
                      "flex-direction": "column"
                    }
                  }, [_c('c-flex', [i2 !== 0 ? _c('c-button', {
                    attrs: {
                      "background-color": "transparent",
                      "_hover": {
                        backgroundColor: 'neutral.lightGray'
                      },
                      "disabled": _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
                      "margin-left": "auto"
                    },
                    on: {
                      "click": function click($event) {
                        return remove2(v.menusArray, i2);
                      }
                    }
                  }, [_c('c-box', {
                    attrs: {
                      "as": "span",
                      "margin-right": "8px",
                      "font-size": "16px",
                      "font-weight": "500"
                    }
                  }, [_vm._v(" Hapus komposisi makanan ")]), _c('c-box', {
                    attrs: {
                      "height": "20px",
                      "width": "20px"
                    }
                  }, [_c('inline-svg', {
                    attrs: {
                      "src": require('@/assets/icon-close-black.svg'),
                      "height": "20px",
                      "width": "20px"
                    }
                  })], 1)], 1) : _vm._e()], 1), true ? _c('c-flex', {
                    attrs: {
                      "gap": "20px",
                      "width": "100%",
                      "align-items": "flex-start"
                    }
                  }, [_c('c-flex', [_c('InputImage2', {
                    attrs: {
                      "photo-url": v2.photoUrl,
                      "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm
                    },
                    on: {
                      "delete:photo": function deletePhoto() {
                        v2.photoUrl = null;
                        v2.mealGlossariesId = null;
                      },
                      "click:photo": function clickPhoto() {
                        _vm.popupIsOpen = true;
                        _vm.popupItemIndexes = [i, i2];
                      }
                    }
                  })], 1), _c('c-flex', {
                    attrs: {
                      "width": "100%",
                      "flex-direction": "column"
                    }
                  }, [_c('FormInput', {
                    attrs: {
                      "label": "Komposisi Makanan",
                      "placeholder": "Masukkan Komposisi Makanan",
                      "is-required": "",
                      "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
                      "is-invalid": _vm.isInvalidField(_vm.$v.targets.$each[i].menusArray.$each[i2].foodName),
                      "invalid-text": "Cek Ulang Data"
                    },
                    on: {
                      "blur": _vm.$v.targets.$each[i].menusArray.$each[i2].foodName.$touch
                    },
                    model: {
                      value: v2.foodName,
                      callback: function callback($$v) {
                        _vm.$set(v2, "foodName", $$v);
                      },
                      expression: "v2.foodName"
                    }
                  }), _c('InputTextWithSelect', {
                    attrs: {
                      "label": "Jumlah Takaran",
                      "input-placeholder": "Masukkan Jumlah Takaran",
                      "select-placeholder": "Pilih",
                      "input-value": v2.servingSize,
                      "invalid-input-text": "Cek Ulang Data",
                      "is-invalid-input": _vm.isInvalidField(_vm.$v.targets.$each[i].menusArray.$each[i2].servingSize),
                      "select-value": v2.servingSizeUnit,
                      "invalid-select-text": "Cek Ulang Data",
                      "is-invalid-select": _vm.isInvalidField(_vm.$v.targets.$each[i].menusArray.$each[i2].servingSizeUnit),
                      "select-other-value": v2.servingSizeUnitOther,
                      "invalid-select-other-text": "Lainnya wajib diisi",
                      "is-invalid-select-other": _vm.isInvalidField(_vm.$v.targets.$each[i].menusArray.$each[i2].servingSizeUnitOther),
                      "select-options": _vm.optionPortionUnits,
                      "is-disabled": !v.menuType || !_vm.hasMealComposition || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
                      "is-without-margin-bottom": ""
                    },
                    on: {
                      "blur:input": _vm.$v.targets.$each[i].menusArray.$each[i2].servingSize.$touch,
                      "blur:select": function blurSelect($event) {
                        _vm.$v.targets.$each[i].menusArray.$each[i2].servingSizeUnit.$touch;
                      },
                      "blur:select-other": function blurSelectOther($event) {
                        _vm.$v.targets.$each[i].menusArray.$each[i2].servingSizeUnitOther.$touch;
                      },
                      "change:input": function changeInput(v_) {
                        return v2.servingSize = v_;
                      },
                      "change:select": function changeSelect(v_) {
                        return v2.servingSizeUnit = v_;
                      },
                      "change:select-other": function changeSelectOther(v_) {
                        return v2.servingSizeUnitOther = v_;
                      }
                    }
                  }), _c('c-box', {
                    attrs: {
                      "height": "8px"
                    }
                  }), _c('FormInput', {
                    attrs: {
                      "type": "number",
                      "label": "Ukuran Lainnya",
                      "placeholder": "Masukkan Ukuran Lainnya",
                      "right-element": "Gram",
                      "is-required": null
                    },
                    model: {
                      value: v2.servingSizeOtherPerGram,
                      callback: function callback($$v) {
                        _vm.$set(v2, "servingSizeOtherPerGram", $$v);
                      },
                      expression: "v2.servingSizeOtherPerGram"
                    }
                  })], 1)], 1) : _vm._e(), _c('BaseDivider', {
                    attrs: {
                      "color": "neutral.gray",
                      "margin": "0 0 20px 0"
                    }
                  })], 1);
                }), _c('c-flex', {
                  attrs: {
                    "justify-content": "center"
                  }
                }, [_c('BaseButton', {
                  attrs: {
                    "margin": "auto",
                    "size": "large",
                    "border-radius": "1000px",
                    "variant": "outlined",
                    "width": "70%",
                    "background-color": "white",
                    "disabled": !v.menuType || !_vm.hasMealComposition || _vm.$v.$invalid || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
                    "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
                    "left-svg-icon-color": "#008C81"
                  },
                  on: {
                    "click": function click($event) {
                      $event.preventDefault();
                      return push2(_vm.targets[i].menusArray, {
                        _menuType: v.menuType,
                        foodName: null,
                        servingSize: null,
                        servingSizeUnit: null,
                        servingSizeUnitOther: null,
                        servingSizeOtherPerGram: null,
                        photoUrl: null,
                        mealGlossariesId: null
                      });
                    }
                  }
                }, [_vm._v(" Tambah Komposisi Makanan ")])], 1)];
              }
            }], null, true)
          }) : _vm._e()], 1);
        }), _c('c-flex', {
          attrs: {
            "justify-content": "center"
          }
        }, [_c('BaseButton', {
          attrs: {
            "margin": "auto",
            "mt": "28px",
            "size": "large",
            "border-radius": "1000px",
            "variant": "outlined",
            "width": "70%",
            "disabled": !_vm.hasMealComposition || _vm.$v.$invalid || _vm.isDuplicateMealTime || !_vm.getIsEditableForm,
            "left-svg-icon": require('@/assets/icons/icon-plus.svg'),
            "left-svg-icon-color": "#008C81"
          },
          on: {
            "click": function click($event) {
              var _Date$now;

              $event.preventDefault();
              push(_vm.targets, {
                id: (_Date$now = Date.now()) === null || _Date$now === void 0 ? void 0 : _Date$now.toString(),
                menuType: null,
                // single | multiple
                menuName: null,
                // {multipleEntry Only}
                menusObject: {
                  // {object: singleEntry | array: multipleEntry}
                  servingSize: null,
                  servingSizeUnit: null,
                  photoUrl: null,
                  servingSizeUnitOther: null,
                  servingSizeOtherPerGram: null,
                  mealGlossariesId: null
                },
                menusArray: [{
                  foodName: null,
                  servingSize: null,
                  servingSizeUnit: null,
                  photoUrl: null,
                  servingSizeUnitOther: null,
                  servingSizeOtherPerGram: null,
                  mealGlossariesId: null
                }]
              });
            }
          }
        }, [_vm._v(" Tambah Menu Lainnya ")])], 1)];
      }
    }])
  })], 1)], 1)], 1), _c('c-grid', {
    attrs: {
      "margin-top": "32px",
      "w": "100%",
      "template-columns": ['repeat(2, 1fr)'],
      "gap": ['16px']
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "outline",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null || _vm.activeStepIndex <= 0
    },
    on: {
      "click": _vm.handlePrevious
    }
  }, [_vm._v(" Sebelumnya ")]), _c('c-button', {
    attrs: {
      "variant": "solid",
      "variant-color": "primary",
      "height": "60px",
      "border-radius": "60px",
      "font-size": "18px",
      "is-disabled": _vm.isLoadingSave || _vm.saveState === null || _vm.activeStepIndex >= _vm.constants.steps.length - 1,
      "is-loading": _vm.isLoadingSave
    },
    on: {
      "click": _vm.handleNext
    }
  }, [_vm._v(" Selanjutnya ")])], 1)], 1)], 1), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalSuccessSaveData,
      "image": require('@/assets/images/success-image.svg'),
      "title": "Berhasil Menyimpan Data"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              return _vm.$router.push(Object.assign({}, _vm.$route, {
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: Object.assign({}, _vm.$route.params, {
                  bypass: 1
                })
              }));
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalSimpleInfo', {
    attrs: {
      "is-open": _vm.isOpenModalFailedSaveData,
      "image": require('@/assets/images/image-warning.svg'),
      "title": "Gagal Menyimpan Data",
      "description": "Terdapat kesalahan data, coba tinjau ulang datamu"
    },
    scopedSlots: _vm._u([{
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "20px",
            "width": "100%",
            "padding": "0 24px",
            "margin-bottom": "24px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "color": "primary",
            "rounded": "1000px",
            "width": "100%"
          },
          on: {
            "click": function click($event) {
              _vm.isOpenModalFailedSaveData = false;
            }
          }
        }, [_vm._v(" Oke ")])], 1)];
      },
      proxy: true
    }])
  }), _c('ModalCreateBatchMenuRecommendations', {
    attrs: {
      "client-id2": _vm.$route.params.clientId,
      "program-id2": _vm.$route.params.programId,
      "month": _vm.$route.params.month,
      "chip-days-state": {
        invalidDayValidation: [],
        // skip invalid state
        disabledDay: _vm.chipDaysState.disabledDay,
        mealtimeStates: _vm.listMealTimeStates
      },
      "option-portion-units": _vm.optionPortionUnits,
      "is-open": _vm.dataModalCreateBatchMenuRecommendations.isOpen,
      "selected-day-default-value": _vm.query.day
    },
    on: {
      "close": function close($event) {
        _vm.dataModalCreateBatchMenuRecommendations.isOpen = false;
      }
    }
  }), _c('ModalChooseMenu', {
    attrs: {
      "is-open": _vm.popupIsOpen,
      "title": "Pilih Tampilan",
      "is-loading-data-food": _vm.popupIsLoadingDataFood,
      "options": _vm.popupOptions,
      "selected-foods": _vm.popupSelectedMenu,
      "pages": _vm.popupPages,
      "filter": _vm.popupFilter
    },
    on: {
      "change:selected-food": function changeSelectedFood(menu) {
        var _vm$targets, _vm$targets$_vm$popup, _vm$targets$_vm$popup2, _vm$popupItemIndexes, _vm$popupItemIndexes2, _vm$targets2, _vm$targets2$_vm$popu, _vm$popupItemIndexes3;

        if ((_vm$targets = _vm.targets) !== null && _vm$targets !== void 0 && (_vm$targets$_vm$popup = _vm$targets[(_vm$popupItemIndexes = _vm.popupItemIndexes) === null || _vm$popupItemIndexes === void 0 ? void 0 : _vm$popupItemIndexes[0]]) !== null && _vm$targets$_vm$popup !== void 0 && (_vm$targets$_vm$popup2 = _vm$targets$_vm$popup.menusArray) !== null && _vm$targets$_vm$popup2 !== void 0 && _vm$targets$_vm$popup2[(_vm$popupItemIndexes2 = _vm.popupItemIndexes) === null || _vm$popupItemIndexes2 === void 0 ? void 0 : _vm$popupItemIndexes2[1]]) {
          _vm.targets[_vm.popupItemIndexes[0]].menusArray[_vm.popupItemIndexes[1]].photoUrl = (menu === null || menu === void 0 ? void 0 : menu.photoUrl) || '';
          _vm.targets[_vm.popupItemIndexes[0]].menusArray[_vm.popupItemIndexes[1]].mealGlossariesId = (menu === null || menu === void 0 ? void 0 : menu.id) || '';
        } else if ((_vm$targets2 = _vm.targets) !== null && _vm$targets2 !== void 0 && (_vm$targets2$_vm$popu = _vm$targets2[(_vm$popupItemIndexes3 = _vm.popupItemIndexes) === null || _vm$popupItemIndexes3 === void 0 ? void 0 : _vm$popupItemIndexes3[0]]) !== null && _vm$targets2$_vm$popu !== void 0 && _vm$targets2$_vm$popu.menusObject) {
          _vm.targets[_vm.popupItemIndexes[0]].menusObject.photoUrl = (menu === null || menu === void 0 ? void 0 : menu.photoUrl) || '';
          _vm.targets[_vm.popupItemIndexes[0]].menusObject.mealGlossariesId = (menu === null || menu === void 0 ? void 0 : menu.id) || '';
        }

        _vm.popupIsOpen = false;
      },
      "change:is-open": function changeIsOpen(open) {
        return _vm.popupIsOpen = open;
      },
      "change:filter-page": function changeFilterPage(page) {
        return _vm.popupFilter.page = page;
      },
      "change:filter-search": function changeFilterSearch(search) {
        _vm.popupFilter.page = 1;
        _vm.popupFilter.search = search;
      }
    }
  }), _c('BaseModal', {
    attrs: {
      "is-open": !!_vm.isOpenModalEditMenuDuplicate,
      "close-on-overlay-click": false,
      "with-button-close": false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "justify-content": "center",
            "margin": "24px 0 0 0"
          }
        }, [_c('CImage', {
          attrs: {
            "height": "120px",
            "object-fit": "cover",
            "src": require('@/assets/images/image-question.svg'),
            "alt": "Image Confirmation"
          }
        })], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "display": "flex",
            "flex-direction": "column",
            "align-items": "center",
            "px": "24px",
            "py": "16px",
            "text-align": "center",
            "max-width": "500px",
            "mx": "auto"
          }
        }, [_c('BaseText', {
          attrs: {
            "size-mobile": "16px",
            "size-desktop": "20px",
            "color": "primary.400"
          }
        }, [_vm._v(" Apakah ingin mengubah data menu duplikasi? ")])], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-box', {
          attrs: {
            "width": "100%",
            "padding-left": "24px",
            "padding-right": "24px",
            "margin": "24px 0"
          }
        }, [_c('c-flex', {
          attrs: {
            "gap": "8px"
          }
        }, [_c('BaseButton', {
          attrs: {
            "left-svg-icon": require('@/assets/icons/icon-circle-close.svg'),
            "left-svg-icon-color": "#008C81",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "variant": "outlined",
            "is-disabled": _vm.isLoadingEditMenuDuplicate
          },
          on: {
            "click": _vm.onCloseEditMenuDuplicate
          }
        }, [_vm._v(" Batal ")]), _c('BaseButton', {
          attrs: {
            "right-svg-icon": require('@/assets/icons/icon-circle-check.svg'),
            "right-svg-icon-color": "white",
            "color": "primary",
            "rounded": "1000px",
            "width": "100%",
            "is-loading": _vm.isLoadingEditMenuDuplicate
          },
          on: {
            "click": _vm.onSubmitEditMenuDuplicate
          }
        }, [_vm._v(" Ya ")])], 1)], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }