<template>
  <c-form-control
    v-bind="$attrs"
    :is-disabled="isDisabled"
    :is-required="isRequired"
    :is-invalid="isInvalid"
    :margin-bottom="['16px', '24px']"
  >
    <c-form-label
      v-if="!!label"
      :font-size="['14px', '16px']"
      :color="isInvalid ?
        'danger.400'
        : !isDataEmpty(photos.filter((photo) => !isDataEmpty(photo)))
          ? 'primary.400'
          : '#555'"
      font-weight="400"
      font-family="Roboto"
    >
      {{ label }}
      <c-box
        v-if="!isRequired"
        as="span"
        font-family="Roboto"
        font-weight="400"
        color="#C4C4C4"
        :font-size="['12px', '14px']"
      >
        (Optional)
      </c-box>
    </c-form-label>

    <c-box
      d="flex"
      align-items="center"
      :max-height="['90px','160px']"
    >
      <c-box
        d="flex"
        gap="16px"
        align-items="center"
      >
        <c-box
          v-for="(photo, indexPhoto) in photos"
          :key="indexPhoto"
          :height="['90px', '150px']"
          :width="['90px', '150px']"
        >
          <c-box
            v-if="photo.blobPreview || !isDataEmpty(photo)"
            position="relative"
            d="inline-block"
          >
            <c-image
              :src="photo.blobPreview || photo"
              style="object-fit: cover;"
              :height="['90px', '150px']"
              :width="['90px', '150px']"
              :rounded="['8px', '16px']"
            />
            <c-button
              v-chakra="{
                ':hover' : {
                  background: 'none'
                }
              }"
              p="0"
              m="0"
              background-color="transparent"
              position="absolute"
              top="-8px"
              right="-8px"
              :height="['20px','30px']"
              :width="['20px','30px']"
              @click="handleChangePhoto('delete-photo', indexPhoto, $event)"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-circle-close.svg')"
                height="100%"
                width="100%"
                fill="red"
              />
            </c-button>
          </c-box>
          <c-button
            v-else
            p="0"
            h="100%"
            w="100%"
            as="label"
            d="inline-block"
            :rounded="['8px', '16px']"
            overflow="hidden"
          >
            <inline-svg
              :src="require('@/assets/icons/icon-image-preview.svg')"
              height="100%"
              width="100%"
              fill="#008C81"
            />
            <c-input
              type="file"
              hidden
              @change="handleChangePhoto('input-photo', indexPhoto, $event)"
            />
          </c-button>
        </c-box>

        <BaseButton
          v-if="false"
          variant="outlined"
          size="large"
          border-radius="1000px"
          :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
          left-svg-icon-color="#008C81"
          @click="handleChangePhoto('add')"
        >
          <c-flex
            flex-direction="column"
            padding="16px 0"
          >
            <c-text
              font-size="18px"
              font-weight="500"
            >
              Masukkan Gambar
            </c-text>
            <c-text
              font-size="14px"
              font-weight="400"
              color="#888888"
            >
              (Max 10)
            </c-text>
          </c-flex>
        </BaseButton>
      </c-box>
    </c-box>

    <c-form-helper-text
      v-if="isHelper"
      font-size="12px"
    >
      {{ helperText }}
    </c-form-helper-text>
    <c-form-helper-text
      v-if="!isDataEmpty(invalidText) && isInvalid"
      :font-size="['12px', '16px']"
      :font-weight="['400', '400']"
      display="flex"
      flex-direction="column"
      gap="8px"
      color="#D32737"
    >
      <c-text
        v-for="(invalidTextValue, invalidTextKey) in invalidText_"
        :key="invalidTextKey"
      >
        &#9679; {{ invalidTextValue }}
      </c-text>
    </c-form-helper-text>
  </c-form-control>
</template>

<script>
import { isDataEmpty } from '@/utils/is-data-empty'
import {
  CBox,
  CText,
  CImage,
  CInput,
  CButton,
  CFormControl,
  CFormLabel,
  CFormHelperText,
} from '@chakra-ui/vue'
import BaseButton from '@/components/elements/base-button.vue'

export default {
  name: 'BaseInputPhotos',
  components: {
    BaseButton,
    CFormHelperText,
    CFormLabel,
    CFormControl,
    CBox,
    CButton,
    CText,
    CImage,
    CInput,
  },
  props: {
    isDisabled: {
      type: Boolean,
      default: false,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    isInvalid: {
      type: Boolean,
      default: false,
    },
    invalidText: {
      type: [String, Object],
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    photos: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
      default: 'form',
    },
    isHelper: {
      type: Boolean,
      default: false,
    },
    helperText: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isOpenPreview: false,
      preview: null,
    }
  },
  computed: {
    invalidText_() {
      if (typeof this.invalidText === 'string') {
        return [this.invalidText]
      }
      return this.invalidText
    },
  },
  methods: {
    isDataEmpty,
    handleChangePhoto(type, indexPhoto, e) {
      this.$emit('blur')
      if (type === 'add') {
        const newPhotos = [...this.photos, {}]
        this.$emit('update:photos', newPhotos)
        return
      }

      if (type === 'input-photo') {
        const imageFile = e.target.files?.[0]
        const newPhotos = [...this.photos]
        newPhotos[indexPhoto] = {
          file: imageFile,
          blobPreview: URL.createObjectURL(imageFile),
        }
        this.$emit('update:photos', newPhotos)
        return
      }

      if (type === 'delete-photo') {
        const newPhotos = [...this.photos]
        newPhotos[indexPhoto] = {}
        this.$emit('update:photos', newPhotos)
        return
      }
    },
  },
}
</script>
