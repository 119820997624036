var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-form-control', {
    attrs: {
      "width": _vm.width,
      "is-disabled": _vm.isDisabled,
      "is-required": _vm.isRequired,
      "is-invalid": _vm.isInvalid,
      "mb": _vm.isWithoutMarginBottom ? '0' : ['12px', '16px']
    }
  }, [_c('c-form-label', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isWithoutLabel,
      expression: "!isWithoutLabel"
    }],
    attrs: {
      "font-size": ['14px', '16px'],
      "color": _vm.isDisabled ? '#888' : _vm.isInvalid ? '#D32737' : _vm.value ? '#008C81' : '#555',
      "font-weight": "400",
      "font-family": "Roboto",
      "opacity": _vm.isDisabled ? '1' : ''
    }
  }, [_vm._v(" " + _vm._s(_vm.label) + " ")]), _c('c-select', {
    attrs: {
      "height": _vm.getSize.input.height,
      "font-size": _vm.getSize.input.fontSize,
      "placeholder": _vm.placeholder ? _vm.placeholder : _vm.label,
      "color": _vm.value ? _vm.isInvalid ? '#D32737' : '#008C81' : '#555',
      "font-weight": _vm.value ? '500' : '400',
      "border-radius": "8px"
    },
    model: {
      value: _vm.modelValue,
      callback: function callback($$v) {
        _vm.modelValue = $$v;
      },
      expression: "modelValue"
    }
  }, [_vm.options.length === 0 && _vm.modelValue ? _c('option', {
    key: _vm.modelValue,
    domProps: {
      "value": _vm.modelValue
    }
  }, [_vm._v(" " + _vm._s(_vm.modelValue) + " ")]) : _vm._e(), _vm._l(_vm.options, function (option) {
    return _c('option', {
      key: option.value,
      domProps: {
        "value": option.value
      }
    }, [_vm._v(" " + _vm._s(option.label) + " ")]);
  })], 2), _vm.isOther && _vm.modelValue == _vm.otherOption ? _c('c-form-control', {
    attrs: {
      "is-invalid": _vm.otherValue ? false : true,
      "mt": ['12px', '16px']
    }
  }, [_c('c-input', {
    attrs: {
      "type": "text",
      "height": _vm.getSize.input.height,
      "font-size": _vm.getSize.input.fontSize,
      "color": _vm.otherValue ? '#008C81' : '#555',
      "font-weight": _vm.otherValue ? '500' : '400',
      "placeholder": "Lainnnya",
      "mb": "8px",
      "border-radius": "8px"
    },
    model: {
      value: _vm.otherValue,
      callback: function callback($$v) {
        _vm.otherValue = $$v;
      },
      expression: "otherValue"
    }
  })], 1) : _vm._e(), !_vm.isDataEmpty(_vm.invalidText) && _vm.isInvalid ? _c('c-form-helper-text', {
    attrs: {
      "font-size": ['12px', '16px'],
      "font-weight": ['400', '400'],
      "d": "flex",
      "flex-direction": "column",
      "gap": "8px",
      "color": "#D32737"
    }
  }, _vm._l(_vm.invalidText_, function (invalidTextValue, invalidTextKey) {
    return _c('c-text', {
      key: invalidTextKey,
      attrs: {
        "d": "flex",
        "align-items": "center",
        "gap": "8px"
      }
    }, [_c('c-box', {
      attrs: {
        "min-height": ['13px', '16px'],
        "min-width": ['13px', '16px'],
        "height": ['13px', '16px'],
        "width": ['13px', '16px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-warn.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#D32737"
      }
    })], 1), _vm._v(" " + _vm._s(invalidTextValue) + " ")], 1);
  }), 1) : _vm._e()], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }