var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "background-color": "neutral.superLightGray",
      "border-bottom": "1px solid",
      "border-color": "neutral.lightGray",
      "padding": "16px",
      "width": "100%"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "space-between"
    }
  }, [_c('c-text', {
    attrs: {
      "font-size": "18px",
      "font-weight": "500"
    }
  }, [_vm._v(" " + _vm._s(_vm.mealType) + " ")]), _c('c-text', {
    attrs: {
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.mealPortion) + " " + _vm._s(_vm.mealPortionUnit) + " ")])], 1), _c('c-text', {
    attrs: {
      "margin-top": "16px",
      "color": "neutral.888888",
      "font-size": "16px",
      "margin-right": "auto"
    }
  }, [_vm._v(" Pilihan golongan makanan ")]), _c('c-grid', {
    attrs: {
      "margin-top": "8px",
      "template-columns": "repeat(auto-fill, minmax(75px, 1fr))",
      "gap": "4px"
    }
  }, _vm._l(_vm.mealItems, function (meal, mealIdx) {
    return _c('MealPortion', {
      key: mealIdx,
      attrs: {
        "image": (meal === null || meal === void 0 ? void 0 : meal.photoUrl) || require('@/assets/images/image-gray.png'),
        "name": (meal === null || meal === void 0 ? void 0 : meal.foodName) || '-',
        "household-measurement": _vm.calcHouseholdMeasurement(meal),
        "household-measurement-unit": (meal === null || meal === void 0 ? void 0 : meal.householdMeasurementUnitPerPortion) || (meal === null || meal === void 0 ? void 0 : meal.householdMeasurementUnit) || '',
        "serving-size": _vm.calcServingSize(meal),
        "serving-size-unit": (meal === null || meal === void 0 ? void 0 : meal.servingSizeUnitPerPortion) || ''
      }
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }