var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-flex', {
    attrs: {
      "flex-direction": "column",
      "align-items": "center",
      "width": "100%",
      "min-width": "75px",
      "min-height": "10px"
    }
  }, [_c('c-image', {
    attrs: {
      "src": _vm.image || require('@/assets/images/image-gray.png'),
      "object-fit": "cover",
      "size": "72px",
      "border-radius": "8px"
    }
  }), _c('c-text', {
    attrs: {
      "padding-top": "2px",
      "font-size": "14px",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _c('c-text', {
    attrs: {
      "color": "neutral.888888",
      "font-size": "14px",
      "text-align": "center"
    }
  }, [_vm._v(" " + _vm._s(_vm.householdMeasurement) + " " + _vm._s(_vm.householdMeasurementUnit) + " " + _vm._s(_vm.servingSize && _vm.servingSizeUnit ? "(".concat(_vm.servingSize, " ").concat(_vm.servingSizeUnit, ")") : '') + " ")])], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }