<template>
  <c-box>
    <AccordionMealPlanMenu
      v-for="(item, i) in menus"
      :key="i"
      :item="item"
      :is-disabled="isDisabled"
      :remaining-calories="remainingCalories"
      @update:item="val => updateItem(i, val)"
    />
  </c-box>
</template>

<script>
import AccordionMealPlanMenu from '@/components/meal-plan/_widgets/accordion-meal-plan-menu'

export default {
  components: {
    AccordionMealPlanMenu,
  },
  props: {
    menus: {
      type: Array,
      default: () => [],
    },
    remainingCalories: {
      type: [Number, null],
      default: null,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:menus'],
  methods: {
    calculationNutritionalPropertiesByPortion(data) {
      const calc = (dataNutritional_) => {
        let result = data.portionDecimal ? (parseFloat(data.portionDecimal) * parseFloat(dataNutritional_)) : null
        if (result && !Number.isInteger(result)) result = result.toFixed(2)
        return result
      }
      return {
        carbohydrate: calc(data?.raw?.carbohydrate),
        fat: calc(data?.raw?.fat),
        fiber: calc(data?.raw?.fiber),
        calories: calc(data?.raw?.calory),
        protein: calc(data?.raw?.protein),
      }
    },
    updateItem(index, data) {
      let menus = this.menus
      const resultCalc = this.calculationNutritionalPropertiesByPortion(data)
      menus[index] = {
        ...data,
        ...resultCalc,
      }
      this.$emit('update:menus', menus)
    },
  },
}
</script>

<style></style>