var render = function () {
  var _vm$item, _vm$item2, _vm$item3, _vm$item4;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('BaseAccordionList', {
    attrs: {
      "allow-toggle": false
    }
  }, [_c('BaseAccordionListItem', {
    attrs: {
      "label": (_vm$item = _vm.item) === null || _vm$item === void 0 ? void 0 : _vm$item.foodGroup,
      "is-disabled": _vm.isDisabled || !((_vm$item2 = _vm.item) !== null && _vm$item2 !== void 0 && _vm$item2.isActive),
      "is-active": (_vm$item3 = _vm.item) !== null && _vm$item3 !== void 0 && _vm$item3.isActive ? true : false,
      "is-error": _vm.isErrorAccordionListItem ? true : false,
      "is-open": (_vm$item4 = _vm.item) !== null && _vm$item4 !== void 0 && _vm$item4.isActive ? true : false
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        var _vm$item5, _vm$item6, _vm$item7, _vm$item8;

        return [_c('c-flex', {
          attrs: {
            "height": "100%",
            "align-items": "center",
            "gap": "8px",
            "color": "#FFF",
            "font-size": "14px",
            "margin-right": "16px",
            "cursor": _vm.isDisabled ? 'not-allowed' : 'pointer'
          },
          on: {
            "click": function click($event) {
              $event.stopPropagation();
              return _vm.onToggleActive.apply(null, arguments);
            }
          }
        }, [!((_vm$item5 = _vm.item) !== null && _vm$item5 !== void 0 && _vm$item5.isActive) ? _c('c-text', [_vm._v(" Tidak Aktif ")]) : _vm._e(), _c('c-flex', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              ':hover': {
                backgroundColor: _vm.isDisabled ? 'neutral.white' : 'primary.50'
              }
            },
            expression: "{\n              ':hover': {\n                backgroundColor: isDisabled ? 'neutral.white' : 'primary.50',\n              }\n            }"
          }],
          attrs: {
            "position": "relative",
            "width": "60px",
            "height": "25px",
            "align-items": "center",
            "border-radius": "25px",
            "background-color": "#FFF"
          }
        }, [_c('c-box', {
          attrs: {
            "position": "absolute",
            "width": "20px",
            "height": "20px",
            "border-radius": "20px",
            "left": (_vm$item6 = _vm.item) !== null && _vm$item6 !== void 0 && _vm$item6.isActive ? '35px' : '5px',
            "background-color": (_vm$item7 = _vm.item) !== null && _vm$item7 !== void 0 && _vm$item7.isActive ? 'primary.400' : 'neutral.lightGray'
          }
        })], 1), (_vm$item8 = _vm.item) !== null && _vm$item8 !== void 0 && _vm$item8.isActive ? _c('c-text', [_vm._v(" Aktif ")]) : _vm._e()], 1)];
      },
      proxy: true
    }, {
      key: "default",
      fn: function fn() {
        var _vm$item9, _vm$item10, _vm$item11, _vm$item12, _vm$item13, _vm$item14, _vm$item15, _vm$item$calories, _vm$item16, _vm$item17, _vm$item18;

        return [_c('c-flex', {
          attrs: {
            "width": "100%",
            "padding-block": "8px",
            "border-bottom": "1px solid",
            "border-bottom-color": "neutral.lightGray"
          }
        }, [_c('c-box', {
          attrs: {
            "width": "200px",
            "flex-shrink": "0"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": "16px",
            "font-weight": "500",
            "margin-bottom": "12px"
          }
        }, [_vm._v(" Jumlah Porsi ")]), _c('c-flex', {
          attrs: {
            "flex-direction": "column",
            "width": "150px",
            "gap": "8px"
          }
        }, [_c('c-form-control', {
          attrs: {
            "is-invalid": _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item9 = _vm.item) !== null && _vm$item9 !== void 0 && _vm$item9.calories),
            "margin-bottom": "16px"
          }
        }, [_c('c-input-group', [_c('c-input-left-element', [_c('c-flex', {
          attrs: {
            "as": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item10 = _vm.item) !== null && _vm$item10 !== void 0 && _vm$item10.calories) ? 'danger.400' : 'primary.400',
            "border-top-left-radius": "6px",
            "border-bottom-left-radius": "6px",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onAdjustPortion(-0.25);
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-minus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item11 = _vm.item) !== null && _vm$item11 !== void 0 && _vm$item11.calories) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)], 1), _c('c-input', {
          attrs: {
            "value": _vm._portion,
            "type": "text",
            "width": "150px",
            "text-align": "center",
            "padding-inline": "40px",
            "font-size": "20px",
            "border-radius": "6px",
            "border-color": _vm.remainingCalories && _vm.remainingCalories < 0 ? 'danger.400' : '#C4C4C4',
            "_focus": {
              outline: 'none'
            }
          },
          on: {
            "keydown": _vm.onKeyDownPortion,
            "input": _vm.debouncePortion
          }
        }), _c('c-input-right-element', [_c('c-flex', {
          attrs: {
            "as": "button",
            "justify-content": "center",
            "align-items": "center",
            "width": "40px",
            "height": "100%",
            "border-width": "1px",
            "border-style": "solid",
            "border-color": _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item12 = _vm.item) !== null && _vm$item12 !== void 0 && _vm$item12.calories) ? 'danger.400' : 'primary.400',
            "border-top-right-radius": "6px",
            "border-bottom-right-radius": "6px",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.onAdjustPortion(0.25);
            }
          }
        }, [_c('c-box', {
          attrs: {
            "width": "15px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-plus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item13 = _vm.item) !== null && _vm$item13 !== void 0 && _vm$item13.calories) ? '#D32737' : '#008C81'
          }
        })], 1)], 1)], 1)], 1), _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item14 = _vm.item) !== null && _vm$item14 !== void 0 && _vm$item14.calories) ? _c('c-form-helper-text', {
          attrs: {
            "font-size": ['12px', '16px'],
            "font-weight": ['400', '400'],
            "d": "flex",
            "align-items": "center",
            "gap": "10px",
            "color": "#D32737"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-circle-warn.svg'),
            "height": "20px",
            "width": "20px",
            "fill": "#D32737"
          }
        }), _c('c-text', [_vm._v(" Cek Ulang Data ")])], 1) : _vm._e()], 1), _c('c-flex', {
          attrs: {
            "width": "100%",
            "flex-direction": "column",
            "justify-content": "center",
            "align-items": "center",
            "padding": "8px",
            "border-radius": "12px",
            "background-color": _vm.remainingCalories && _vm.remainingCalories < 0 || !((_vm$item15 = _vm.item) !== null && _vm$item15 !== void 0 && _vm$item15.calories) ? 'danger.50' : 'primary.50'
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": "14px"
          }
        }, [_vm._v(" Jumlah Kalori ")]), _c('c-text', {
          attrs: {
            "font-size": "20px",
            "font-weight": "500"
          }
        }, [_vm._v(" " + _vm._s((_vm$item$calories = (_vm$item16 = _vm.item) === null || _vm$item16 === void 0 ? void 0 : _vm$item16.calories) !== null && _vm$item$calories !== void 0 ? _vm$item$calories : '--') + " Kkal ")])], 1)], 1)], 1), _c('c-box', {
          attrs: {
            "flex-grow": "1"
          }
        }, [_c('c-text', {
          attrs: {
            "color": "neutral.darkGray",
            "font-size": "16px",
            "font-weight": "500",
            "margin-bottom": "12px"
          }
        }, [_vm._v(" Pilihan bahan makanan ")]), _c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(4, 1fr)",
            "gap": "16px"
          }
        }, [_vm._l((_vm$item17 = _vm.item) === null || _vm$item17 === void 0 ? void 0 : _vm$item17.defaultItems, function (food, i) {
          return _c('c-flex', {
            key: "default-".concat(i),
            attrs: {
              "position": "relative",
              "flex-direction": "column",
              "justify-content": "flex-start",
              "align-items": "center",
              "width": "100%",
              "opacity": _vm.isIncludeDefaultItem(food === null || food === void 0 ? void 0 : food.id) ? '1' : '0.5'
            }
          }, [_c('c-box', {
            attrs: {
              "position": "relative",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onToggleDefaultItem(food);
              }
            }
          }, [_c('c-box', {
            attrs: {
              "position": "absolute",
              "top": "4px",
              "right": "6px"
            }
          }, [_c('c-checkbox', {
            attrs: {
              "size": "lg",
              "variant-color": "primary",
              "pointer-events": 'none',
              "is-checked": _vm.isIncludeDefaultItem(food === null || food === void 0 ? void 0 : food.id)
            }
          })], 1), _c('c-image', {
            attrs: {
              "src": food === null || food === void 0 ? void 0 : food.photoUrl,
              "width": "72px",
              "height": "72px",
              "object-fit": "cover",
              "object-position": "center",
              "border-radius": "8px",
              "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)",
              "margin-bottom": "8px"
            }
          })], 1), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(food === null || food === void 0 ? void 0 : food.foodName) + " ")]), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": "#888",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(_vm.onAdjustPortionMealItems(food === null || food === void 0 ? void 0 : food.householdMeasurementPerPortion)) + " " + _vm._s(food === null || food === void 0 ? void 0 : food.householdMeasurementUnitPerPortion) + " " + _vm._s(food !== null && food !== void 0 && food.servingSizePerPortion ? _vm.calcServingSize(food === null || food === void 0 ? void 0 : food.servingSizePerPortion, food === null || food === void 0 ? void 0 : food.servingSizeUnitPerPortion) : '') + " ")])], 1);
        }), _vm._l(_vm.listFoodsExcludeDefaultItems, function (exclude, j) {
          return _c('c-flex', {
            key: "exclude-".concat(j),
            attrs: {
              "position": "relative",
              "flex-direction": "column",
              "justify-content": "flex-start",
              "align-items": "center",
              "width": "100%"
            }
          }, [_c('c-box', {
            attrs: {
              "position": "relative",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onRemoveExcludeDefaultItems(exclude);
              }
            }
          }, [_c('c-box', {
            attrs: {
              "position": "absolute",
              "top": "2px",
              "right": "2px"
            }
          }, [_c('c-box', {
            attrs: {
              "width": "20px",
              "height": "auto"
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icon-close-black.svg'),
              "height": "100%",
              "width": "100%",
              "fill": "#555"
            }
          })], 1)], 1), _c('c-image', {
            attrs: {
              "src": exclude === null || exclude === void 0 ? void 0 : exclude.photoUrl,
              "width": "72px",
              "height": "72px",
              "object-fit": "cover",
              "object-position": "center",
              "border-radius": "8px",
              "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)",
              "margin-bottom": "8px"
            }
          })], 1), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(exclude === null || exclude === void 0 ? void 0 : exclude.foodName) + " ")]), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": "#888",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(_vm.onAdjustPortionMealItems(exclude === null || exclude === void 0 ? void 0 : exclude.householdMeasurementPerPortion)) + " " + _vm._s(exclude === null || exclude === void 0 ? void 0 : exclude.householdMeasurementUnitPerPortion) + " " + _vm._s(exclude !== null && exclude !== void 0 && exclude.servingSizePerPortion ? _vm.calcServingSize(exclude === null || exclude === void 0 ? void 0 : exclude.servingSizePerPortion, exclude === null || exclude === void 0 ? void 0 : exclude.servingSizeUnitPerPortion) : '') + " ")])], 1);
        }), _vm._l((_vm$item18 = _vm.item) === null || _vm$item18 === void 0 ? void 0 : _vm$item18.additionalMealItems, function (additional, k) {
          return _c('c-flex', {
            key: "additional-".concat(k),
            attrs: {
              "position": "relative",
              "flex-direction": "column",
              "justify-content": "flex-start",
              "align-items": "center",
              "width": "100%"
            }
          }, [_c('c-box', {
            attrs: {
              "position": "relative",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onRemoveAdditionalMealItems(additional);
              }
            }
          }, [_c('c-box', {
            attrs: {
              "position": "absolute",
              "top": "2px",
              "right": "2px"
            }
          }, [_c('c-box', {
            attrs: {
              "width": "20px",
              "height": "auto"
            }
          }, [_c('inline-svg', {
            attrs: {
              "src": require('@/assets/icon-close-black.svg'),
              "height": "100%",
              "width": "100%",
              "fill": "#555"
            }
          })], 1)], 1), _c('c-image', {
            attrs: {
              "src": additional !== null && additional !== void 0 && additional.photoUrl ? additional === null || additional === void 0 ? void 0 : additional.photoUrl : 'https://ik.imagekit.io/dietela/pwa_webp/meal_plan/ilustrasi%20general.webp?updatedAt=1716858300250',
              "width": "72px",
              "height": "72px",
              "object-fit": "cover",
              "object-position": "center",
              "border-radius": "8px",
              "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)",
              "margin-bottom": "8px"
            }
          })], 1), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(additional === null || additional === void 0 ? void 0 : additional.foodName) + " ")]), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": "#888",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(_vm.onAdjustPortionMealItems(additional === null || additional === void 0 ? void 0 : additional.householdMeasurement)) + " " + _vm._s(additional === null || additional === void 0 ? void 0 : additional.householdMeasurementUnit) + " " + _vm._s(additional !== null && additional !== void 0 && additional.servingSizePerPortion ? _vm.calcServingSize(additional === null || additional === void 0 ? void 0 : additional.servingSizePerPortion, additional === null || additional === void 0 ? void 0 : additional.servingSizeUnitPerPortion) : '') + " ")])], 1);
        }), _c('c-flex', {
          attrs: {
            "position": "relative",
            "flex-direction": "column",
            "justify-content": "flex-start",
            "align-items": "center",
            "width": "100%"
          }
        }, [_c('c-button', {
          attrs: {
            "variant": "outline",
            "variant-color": "primary",
            "justify-content": "center",
            "align-items": "center",
            "width": "72px",
            "height": "72px",
            "border-radius": "8px",
            "margin-bottom": "8px"
          },
          on: {
            "click": _vm.onOpenModalFood
          }
        }, [_c('c-box', {
          attrs: {
            "width": "40px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-plus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1)], 1), _c('c-text', {
          attrs: {
            "font-size": "14px",
            "text-align": "center",
            "color": "#888"
          }
        }, [_vm._v(" Tambah makanan lainnya ")])], 1)], 2)], 1)], 1)];
      },
      proxy: true
    }])
  })], 1), _c('BaseModal', {
    attrs: {
      "is-open": _vm.isOpenModalFood,
      "close-on-overlay-click": false,
      "with-button-close": false,
      "size": "700px"
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function fn() {
        var _vm$item19;

        return [_c('c-box', {
          attrs: {
            "position": "relative"
          }
        }, [_c('c-flex', {
          attrs: {
            "width": "100%",
            "padding": "16px",
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_vm._v(" Detail Menu " + _vm._s((_vm$item19 = _vm.item) === null || _vm$item19 === void 0 ? void 0 : _vm$item19.foodGroup) + " ")]), _c('c-button', {
          attrs: {
            "variant": "ghost",
            "position": "absolute",
            "right": "12px",
            "top": "8px",
            "width": "40px",
            "height": "40px"
          },
          on: {
            "click": _vm.onCloseModalFood
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "close",
            "size": "20px"
          }
        })], 1)], 1)];
      },
      proxy: true
    }, {
      key: "body",
      fn: function fn() {
        var _vm$formAdditional$im, _vm$formAdditional$im2;

        return [_c('c-flex', {
          attrs: {
            "position": "relative",
            "flex-direction": "column",
            "justify-content": "center",
            "text-align": "center"
          }
        }, [_c('c-box', {
          attrs: {
            "margin-inline": "24px",
            "padding-bottom": "8px"
          }
        }, [_c('c-form-control', {
          attrs: {
            "width": "100%",
            "pos": "relative"
          }
        }, [_c('c-image', {
          attrs: {
            "src": require('@/assets/ic-search.svg'),
            "alt": "icon",
            "position": "absolute",
            "top": "50%",
            "transform": "translateY(-50%)",
            "left": "15px",
            "height": "24px",
            "z-index": "2"
          }
        }), _c('c-input', {
          attrs: {
            "id": "search",
            "type": "text",
            "w": "100%",
            "height": "48px",
            "placeholder": "Pencarian",
            "border-radius": "20px",
            "padding-left": "50px",
            "position": "relative",
            "font-size": ['14px', '16px'],
            "z-index": "1"
          },
          on: {
            "keyup": _vm.onChangeSearch
          },
          model: {
            value: _vm.filter.search,
            callback: function callback($$v) {
              _vm.$set(_vm.filter, "search", $$v);
            },
            expression: "filter.search"
          }
        })], 1)], 1), !_vm.isLoadingDataFood ? _c('c-box', {
          attrs: {
            "max-height": "400px",
            "height": "100%",
            "overflow-y": "auto",
            "padding-block": "8px",
            "padding-inline": "24px"
          }
        }, [_vm.listFoods.length > 0 ? _c('c-box', [_c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(5, 1fr)",
            "gap": "8px"
          }
        }, [_vm._l(_vm.listFoods, function (food, i) {
          return _c('c-flex', {
            key: i,
            attrs: {
              "position": "relative",
              "flex-direction": "column",
              "justify-content": "flex-start",
              "align-items": "center",
              "width": "100%"
            }
          }, [_c('c-box', {
            attrs: {
              "position": "relative",
              "cursor": "pointer"
            },
            on: {
              "click": function click($event) {
                return _vm.onToggleSelectedFoods(food);
              }
            }
          }, [_c('c-box', {
            attrs: {
              "position": "absolute",
              "top": "4px",
              "right": "6px"
            }
          }, [_c('c-checkbox', {
            attrs: {
              "size": "lg",
              "variant-color": "primary",
              "pointer-events": 'none',
              "is-checked": _vm.isIncludeSelectedFoods(food === null || food === void 0 ? void 0 : food.id)
            }
          })], 1), _c('c-image', {
            attrs: {
              "src": food === null || food === void 0 ? void 0 : food.photoUrl,
              "width": "72px",
              "height": "72px",
              "object-fit": "cover",
              "object-position": "center",
              "border-radius": "8px",
              "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)",
              "margin-bottom": "8px"
            }
          })], 1), _c('c-text', {
            attrs: {
              "font-size": "14px"
            }
          }, [_vm._v(" " + _vm._s(food === null || food === void 0 ? void 0 : food.foodName) + " ")]), _c('c-text', {
            attrs: {
              "font-size": "14px",
              "color": "#888",
              "text-align": "center"
            }
          }, [_vm._v(" " + _vm._s(_vm.onAdjustPortionMealItems(food === null || food === void 0 ? void 0 : food.householdMeasurementPerPortion)) + " " + _vm._s(food === null || food === void 0 ? void 0 : food.householdMeasurementUnitPerPortion) + " " + _vm._s(food !== null && food !== void 0 && food.servingSizePerPortion ? _vm.calcServingSize(food === null || food === void 0 ? void 0 : food.servingSizePerPortion, food === null || food === void 0 ? void 0 : food.servingSizeUnitPerPortion) : '') + " ")])], 1);
        }), _vm.isShowFormOtherFood ? _c('c-flex', {
          attrs: {
            "position": "relative",
            "flex-direction": "column",
            "justify-content": "flex-start",
            "align-items": "center",
            "width": "100%"
          }
        }, [_c('c-box', {
          attrs: {
            "position": "absolute",
            "top": "2px",
            "right": "2px",
            "cursor": "pointer"
          },
          on: {
            "click": _vm.onRemoveOtherFood
          }
        }, [_c('c-box', {
          attrs: {
            "width": "20px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icon-close-black.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#555"
          }
        })], 1)], 1), _c('c-box', {
          attrs: {
            "height": "72px",
            "width": "72px",
            "margin-bottom": "4px"
          }
        }, [false || (_vm$formAdditional$im = _vm.formAdditional.imageDefault) !== null && _vm$formAdditional$im !== void 0 && _vm$formAdditional$im.blobPreview || !_vm.isDataEmpty(_vm.formAdditional.imageDefault) ? _c('c-box', {
          attrs: {
            "position": "relative",
            "d": "inline-block",
            "cursor": "pointer"
          },
          on: {
            "click": function click($event) {
              return _vm.handleChangePhoto('delete', $event);
            }
          }
        }, [_c('c-image', {
          attrs: {
            "src": ((_vm$formAdditional$im2 = _vm.formAdditional.imageDefault) === null || _vm$formAdditional$im2 === void 0 ? void 0 : _vm$formAdditional$im2.blobPreview) || _vm.formAdditional.imageDefault,
            "height": "72px",
            "width": "72px",
            "rounded": "8px",
            "object-fit": "cover",
            "object-position": "center",
            "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)"
          }
        })], 1) : _c('c-box', {
          attrs: {
            "p": "0",
            "h": "100%",
            "w": "100%",
            "as": "label",
            "d": "inline-block",
            "rounded": "8px",
            "overflow": "hidden",
            "box-shadow": "0px 0px 8px rgba(0, 0, 0, 0.12)"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-image-preview.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1)], 1), _c('c-input', {
          attrs: {
            "type": "text",
            "w": "100%",
            "height": "24px",
            "placeholder": "Nama makanan",
            "position": "relative",
            "font-size": "12px",
            "text-align": "center",
            "padding-inline": "4px",
            "margin-bottom": "4px",
            "border-color": "lightGray.900"
          },
          model: {
            value: _vm.formAdditional.foodName,
            callback: function callback($$v) {
              _vm.$set(_vm.formAdditional, "foodName", $$v);
            },
            expression: "formAdditional.foodName"
          }
        }), _c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(2, 1fr)",
            "margin-bottom": "4px"
          }
        }, [_c('c-input', {
          attrs: {
            "type": "text",
            "w": "100%",
            "height": "24px",
            "placeholder": "Ukuran 1",
            "position": "relative",
            "font-size": "12px",
            "text-align": "center",
            "padding-inline": "4px",
            "border-top-left-radius": "8px",
            "border-bottom-left-radius": "8px",
            "border-top-right-radius": "0",
            "border-bottom-right-radius": "0",
            "border-color": "lightGray.900"
          },
          model: {
            value: _vm.formAdditional.householdMeasurement,
            callback: function callback($$v) {
              _vm.$set(_vm.formAdditional, "householdMeasurement", $$v);
            },
            expression: "formAdditional.householdMeasurement"
          }
        }), _c('c-input', {
          attrs: {
            "type": "text",
            "w": "100%",
            "height": "24px",
            "placeholder": "Satuan 1",
            "position": "relative",
            "font-size": "12px",
            "text-align": "center",
            "color": _vm.formAdditional.householdMeasurementUnit ? 'primary.400' : '#888',
            "padding-inline": "4px",
            "background-color": "superLightGray.900",
            "border-top-left-radius": "0",
            "border-bottom-left-radius": "0",
            "border-top-right-radius": "8px",
            "border-bottom-right-radius": "8px",
            "border-color": "lightGray.900"
          },
          model: {
            value: _vm.formAdditional.householdMeasurementUnit,
            callback: function callback($$v) {
              _vm.$set(_vm.formAdditional, "householdMeasurementUnit", $$v);
            },
            expression: "formAdditional.householdMeasurementUnit"
          }
        })], 1), _c('c-grid', {
          attrs: {
            "width": "100%",
            "template-columns": "repeat(2, 1fr)"
          }
        }, [_c('c-input', {
          attrs: {
            "type": "number",
            "border-color": "lightGray.900",
            "height": "24px",
            "placeholder": "Ukuran 2",
            "position": "relative",
            "font-size": "12px",
            "text-align": "center",
            "padding-inline": "4px",
            "border-top-left-radius": "8px",
            "border-bottom-left-radius": "8px",
            "border-top-right-radius": "0",
            "border-bottom-right-radius": "0"
          },
          model: {
            value: _vm.formAdditional.servingSizePerPortion,
            callback: function callback($$v) {
              _vm.$set(_vm.formAdditional, "servingSizePerPortion", $$v);
            },
            expression: "formAdditional.servingSizePerPortion"
          }
        }), _c('c-select', {
          directives: [{
            name: "chakra",
            rawName: "v-chakra",
            value: {
              'select': {
                color: _vm.formAdditional.servingSizeUnitPerPortion ? 'primary.400' : '#888',
                paddingInline: '4px',
                backgroundColor: 'superLightGray.900',
                borderTopLeftRadius: '0',
                borderTopRightRadius: '8px',
                borderBottomLeftRadius: '0',
                borderBottomRightRadius: '8px',
                borderColor: 'lightGray.900'
              },
              'select + div': {
                right: '1px'
              }
            },
            expression: "{\n                      'select': {\n                        color: formAdditional.servingSizeUnitPerPortion ? 'primary.400' : '#888',\n                        paddingInline: '4px',\n                        backgroundColor: 'superLightGray.900',\n                        borderTopLeftRadius: '0',\n                        borderTopRightRadius: '8px',\n                        borderBottomLeftRadius: '0',\n                        borderBottomRightRadius: '8px',\n                        borderColor:'lightGray.900',\n                      },\n                      'select + div': {\n                        right: '1px',\n                      }\n                    }"
          }],
          attrs: {
            "placeholder": "Satuan 2",
            "height": "24px",
            "font-size": "12px"
          },
          model: {
            value: _vm.formAdditional.servingSizeUnitPerPortion,
            callback: function callback($$v) {
              _vm.$set(_vm.formAdditional, "servingSizeUnitPerPortion", $$v);
            },
            expression: "formAdditional.servingSizeUnitPerPortion"
          }
        }, [_c('option', {
          attrs: {
            "value": "gram"
          }
        }, [_vm._v(" gram ")]), _c('option', {
          attrs: {
            "value": "ml"
          }
        }, [_vm._v(" ml ")])])], 1)], 1) : _vm._e()], 2), _c('c-flex', {
          attrs: {
            "justify-content": "center",
            "w": "100%",
            "margin-top": "16px"
          }
        }, [_c('c-flex', {
          attrs: {
            "justify-content": "center"
          }
        }, [_c('c-button', {
          attrs: {
            "variant": "ghost",
            "variant-color": "primary",
            "px": "0",
            "py": "4px",
            "w": "32px",
            "h": "32px",
            "is-disabled": _vm.filter.page <= 1
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onPreviousMenus.apply(null, arguments);
            }
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "chevron-left",
            "size": "24px"
          }
        })], 1), _vm._l(_vm.pages, function (page_) {
          return _c('c-button', {
            key: page_,
            attrs: {
              "variant": page_ === _vm.filter.page ? 'outline' : 'ghost',
              "variant-color": "primary",
              "px": "0",
              "py": "4px",
              "w": "32px",
              "h": "32px",
              "mx": "4px"
            },
            on: {
              "click": function click($event) {
                return _vm.onChangePageMenus(page_);
              }
            }
          }, [_vm._v(" " + _vm._s(page_) + " ")]);
        }), _c('c-button', {
          attrs: {
            "variant": "ghost",
            "variant-color": "primary",
            "px": "0",
            "py": "4px",
            "w": "32px",
            "h": "32px",
            "is-disabled": _vm.pages.length === _vm.filter.page
          },
          on: {
            "click": function click($event) {
              $event.preventDefault();
              return _vm.onNextMenus.apply(null, arguments);
            }
          }
        }, [_c('c-icon', {
          attrs: {
            "name": "chevron-right",
            "size": "24px"
          }
        })], 1)], 2)], 1)], 1) : _c('c-flex', {
          attrs: {
            "width": "100%",
            "min-height": "100px",
            "justify-content": "center",
            "align-items": "center"
          }
        }, [_c('c-text', {
          attrs: {
            "font-size": "18px",
            "font-weight": "500",
            "color": "danger.400"
          }
        }, [_vm._v(" Data tidak ditemukan ")])], 1)], 1) : _c('c-box', {
          attrs: {
            "text-align": "center",
            "margin-block": "36px"
          }
        }, [_c('c-spinner', {
          attrs: {
            "thickness": "4px",
            "speed": "0.65s",
            "empty-color": "green.200",
            "color": "primary.400",
            "size": "xl"
          }
        }), _c('c-text', {
          attrs: {
            "color": "primary.400",
            "font-weight": "500"
          }
        }, [_vm._v(" Memuat data... ")])], 1)], 1)];
      },
      proxy: true
    }, {
      key: "footer",
      fn: function fn() {
        return [_c('c-flex', {
          attrs: {
            "gap": "8px",
            "width": "100%",
            "align-items": "flex-end",
            "padding-top": "8px",
            "padding-left": "16px",
            "padding-right": "16px",
            "margin-bottom": "30px"
          }
        }, [_c('c-flex', {
          attrs: {
            "position": "relative",
            "flex-direction": "column",
            "justify-content": "flex-start",
            "align-items": "center",
            "width": "110px"
          }
        }, [_c('c-button', {
          attrs: {
            "variant": "outline",
            "variant-color": "primary",
            "justify-content": "center",
            "align-items": "center",
            "width": "65px",
            "height": "65px",
            "border-radius": "8px",
            "margin-bottom": "8px",
            "is-disabled": _vm.isShowFormOtherFood
          },
          on: {
            "click": _vm.onAddOtherFood
          }
        }, [_c('c-box', {
          attrs: {
            "width": "35px",
            "height": "auto"
          }
        }, [_c('inline-svg', {
          attrs: {
            "src": require('@/assets/icons/icon-plus.svg'),
            "height": "100%",
            "width": "100%",
            "fill": "#008C81"
          }
        })], 1)], 1), _c('c-text', {
          attrs: {
            "position": "absolute",
            "bottom": "-26px",
            "width": "130px",
            "font-size": "14px",
            "text-align": "center",
            "color": "#888",
            "line-height": "1.25"
          }
        }, [_vm._v(" Tambah makanan lainnya ")])], 1), _c('BaseButton', {
          attrs: {
            "flex-grow": "1",
            "rounded": "1000px",
            "width": "100%",
            "is-disabled": _vm.isDisabledConfirmModalFood,
            "is-loading": _vm.isLoadingModalFood
          },
          on: {
            "click": _vm.onConfirmModalFood
          }
        }, [_vm._v(" Konfirmasi ")])], 1)];
      },
      proxy: true
    }])
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }