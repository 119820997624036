<template>
  <c-box>
    <c-flex
      padding-block="16px"
      box-shadow="0px 4px 8px rgba(0, 0, 0, 0.1)"
    >
      <!-- Header Panduan Porsi -->
      <c-flex
        flex-direction="column"
        flex-shrink="0"
        width="320px"
        padding-inline="16px"
      >
        <c-flex
          :height="['34px', '50px']"
          align-items="center"
        >
          <c-text
            color="neutral.superDarkGray"
            font-size="22px"
            font-weight="600"
            margin-right="auto"
          >
            Panduan Porsi
          </c-text>
        </c-flex>
        <c-box
          overflow-x="auto"
          margin-top="16px"
        >
          <ChipFilter
            :options="optionsPanduanPorsi"
            :selected-filter="optionsPanduanPorsi[0]"
            justify-content="flex-start"
            :min-width="['30px', '40px']"
            @on-change-filter="() => {}"
          />
        </c-box>
      </c-flex>

      <c-divider
        orientation="vertical"
        border-color="neutral.888888"
        margin-right="8px"
      />

      <!-- Pilihan Hari Rekomendasi Menu -->
      <c-box
        flex-grow="1"
        width="100%"
        padding-right="16px"
        padding-left="8px"
      >
        <c-flex
          width="100%"
          align-items="center"
          justify-content="space-between"
        >
          <c-button
            variant="ghost"
            width="30px"
            min-width="30px"
            height="30px"
            margin="0"
            padding="0"
            margin-right="16px"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
              })
            "
          >
            <c-box
              :width="['12px', '15px']"
              :height="['12px', '15px']"
            >
              <inline-svg
                :src="require('@/assets/icons/icon-arrow-down.svg')"
                height="100%"
                width="100%"
                fill="#008C81"
                style="transform: rotate(90deg)"
              />
            </c-box>
          </c-button>
          <c-text
            color="neutral.superDarkGray"
            font-size="22px"
            font-weight="600"
            margin-right="auto"
          >
            Rekomendasi Menu
          </c-text>
          <c-tooltip
            :label="iconCloud.text"
            placement="bottom"
          >
            <c-box
              :width="['35px']"
              :height="['35px']"
              margin-right="16px"
              :class="saveState === null ? 'animate-spin' : ''"
            >
              <inline-svg
                :src="iconCloud.icon"
                height="100%"
                width="100%"
                fill="#008C81"
              />
            </c-box>
          </c-tooltip>
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-save.svg')"
            left-svg-icon-color="white"
            :disabled="
              !isValidAllFormForAllDays ||
                saveState === null ||
                isLoadingSave ||
                isSaveDraft === 1
            "
            @click="onSave"
          >
            Simpan
          </BaseButton>
        </c-flex>

        <!--  -->
        <c-flex
          width="100%"
          align-items="center"
          gap="0px"
          margin-top="0px"
        >
          <ChipDays
            :start-at="currentActiveDays[0]"
            :end-at="currentActiveDays[currentActiveDays.length - 1]"
            :selected-day="query.day"
            :invalid-day-validation="chipDaysState.invalidDayValidation"
            :disabled-day="
              isLoadingSave || saveState === null
                ? [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
                : chipDaysState.disabledDay
            "
            :dot-badge-days="chipDaysState.dotBadgeDay"
            custom-item-width="100%"
            custom-item-min-width="50px"
            custom-template-columns="repeat(10, 1fr)"
            @on-change-day="
              (day) => {
                $router.push({
                  ...$route,
                  query: {
                    ...$route.query,
                    day,
                    mealTime: 'breakfast',
                  },
                })
              }
            "
          />

          <c-flex
            gap="8px"
            flex-shrink="0"
          >
            <ModalMenuDuplicate
              :is-valid-all-form="isValidAllForm ? true : false"
              :is-disabled="
                isLoadingSave ||
                  saveState === null ||
                  (!isValidMealTime && !isSkippedMealTime)
              "
              :meal-time-states="listMealTimeStates"
              @fetch-form-days="() => getFormDays()"
            />

            <c-button
              font-size="18px"
              font-weight="500"
              variant-color="secondary"
              color="primary.400"
              background-color="secondary.400"
              border-radius="calc(infinity * 1px)"
              size="lg"
              width="50px"
              height="50px"
              padding="0"
              :disabled="isLoadingSave || saveState === null || !getIsEditableForm"
              @click="
                () => {
                  if (isLoadingSave || saveState === null || !getIsEditableForm) return
                  dataModalCreateBatchMenuRecommendations.isOpen = true
                }
              "
            >
              <c-box
                as="span"
                font-size="30px"
                font-weight="400"
              >
                +
              </c-box>
            </c-button>
          </c-flex>
        </c-flex>
      </c-box>
    </c-flex>

    <c-flex
      width="100%"
      padding-block="16px"
    >
      <!-- Detail Panduan Porsi -->
      <c-flex
        flex-direction="column"
        flex-shrink="0"
        width="320px"
        height="100%"
        max-height="calc(100vh - 275px)"
        overflow-y="auto"
        padding-left="16px"
      >
        <c-box v-if="hasMealComposition">
          <MealTimeHeader
            :meal-time="constants.mealPlanValueToLabel[query.mealTime] || '-'"
            :calories-amount="caloriesAmount"
            :start-time="startTime"
            :end-time="endTime"
          />
        </c-box>

        <c-flex
          v-if="hasMealComposition"
          margin-top="16px"
          flex-direction="column"
          gap="16px"
        >
          <CardMealPortionGuide
            v-for="(item, itemIdx) in mealComposition"
            :key="itemIdx"
            :meal-type="item?.foodGroup"
            :meal-portion="`${item?.portion || '-'}`"
            :meal-portion-decimal="`${item?.portionDecimal || '0'}`"
            :meal-portion-unit="`Porsi`"
            :meal-items="
              mergeMealItems(
                item?.portionDecimal,
                item?.mealItems,
                item?.additionalMealItems
              ) || []
            "
          />
        </c-flex>
        <c-flex
          v-else
          justify-content="center"
          align-items="center"
          width="100%"
          height="100%"
        >
          <NoData2 />
        </c-flex>
      </c-flex>

      <c-divider
        orientation="vertical"
        border-color="neutral.888888"
        margin-right="8px"
      />

      <!-- Form Rekomendasi Menu -->
      <c-box
        flex-grow="1"
        width="100%"
        max-height="calc(100vh - 275px)"
        overflow-y="auto"
        padding-right="8px"
        padding-left="8px"
      >
        <LineStep :steps="mealPlanSteps2" />
        <c-flex
          gap="8px"
        >
          <!-- Preview Menu Rekomendasi berdasarkan Jam Makan -->
          <c-box
            width="238px"
            flex-shrink="0"
          >
            <c-flex margin-bottom="16px">
              <c-box
                font-size="18px"
                font-weight="500"
                color="primary.400"
              >
                {{ constants.mealPlanValueToLabel[query.mealTime] || '-' }}
              </c-box>
            </c-flex>
            <c-text
              color="#888888"
            >
              //Preview Menu Rekomendasi {{ constants.mealPlanValueToLabel[query.mealTime] || '-' }}
            </c-text>
          </c-box>

          <!-- Form Menu Rekomendasi berdasarkan Jam Makan -->
          <c-box
            flex-grow="1"
          >
            <c-box
              v-if="isSkippedMealTime"
              margin-bottom="16px"
            >
              <Info2
                variant="warning"
                text="Kamu tidak bisa mengisi Rekomendasi menu karena  memilih tidak makan pada waktu makan ini"
              />
            </c-box>
            <c-box
              v-else-if="!isValidTabNutritionalProfile || !isValidTabMealPlan"
              margin-bottom="16px"
            >
              <Info2
                variant="danger"
                text="Kamu tidak bisa mengisi Rekomendasi Menu sebelum mengisi Rencana makan"
              />
            </c-box>

            <c-box>
              <c-grid
                width="100%"
                template-columns="repeat(2, 1fr)"
                gap="16px"
              >
                <FormTime
                  v-model="startTime"
                  label="Jam Mulai Makan"
                  is-required
                  is-disabled
                />
                <FormTime
                  v-model="endTime"
                  label="Jam Akhir Makan"
                  is-required
                  is-disabled
                />
              </c-grid>

              <FormInput
                v-model="caloriesNeedPercent"
                type="number"
                label="Kebutuhan Jumlah Kalori"
                placeholder="Masukkan alokasi % kalori untuk waktu makan ini"
                is-required
                is-disabled
                right-element="%"
              />
              <FormInput
                v-model="caloriesAmount"
                type="number"
                label="Kkal - Jumlah Kalori"
                placeholder="Jumlah Kalori"
                is-required
                is-disabled
                right-element="Kkal"
              />

              <c-flex
                align-items="center"
                justify-content="space-between"
                margin-bottom="16px"
              >
                <c-box
                  font-size="18px"
                  font-weight="500"
                  color="primary.400"
                >
                  Data Menu
                </c-box>
                <c-button
                  v-if="duplicationDetail && duplicationDetail?.isEdited === 0"
                  variant="solid"
                  variant-color="primary"
                  border-radius="30px"
                  font-weight="500"
                  font-size="18px"
                  @click="onOpenEditMenuDuplicate"
                >
                  <c-box
                    as="span"
                    :min-height="['20px', '22px']"
                    :min-width="['20px', '22px']"
                    :height="['20px', '22px']"
                    :width="['20px', '22px']"
                    margin-right="8px"
                  >
                    <inline-svg
                      :src="require('@/assets/icons/icon-edit-2.svg')"
                      height="100%"
                      width="100%"
                      fill="#FFF"
                    />
                  </c-box>
                  Edit Menu Duplikasi
                </c-button>
              </c-flex>

              <TestFieldArray v-slot="{ push, remove }">
                <c-box
                  v-for="(v, i) in targets"
                  :key="i + 'a'"
                  margin-top="20px"
                  :_first="{ marginTop: '0px' }"
                  background-color="neutral.E1E1E1"
                  padding="20px"
                >
                  <c-flex>
                    <c-button
                      v-if="i !== 0"
                      background-color="transparent"
                      :_hover="{ backgroundColor: 'neutral.lightGray' }"
                      :disabled="isDuplicateMealTime"
                      margin-left="auto"
                      @click="remove(targets, i)"
                    >
                      <c-box
                        as="span"
                        margin-right="16px"
                        font-size="16px"
                        font-weight="500"
                        color="danger.400"
                      >
                        Hapus menu
                      </c-box>
                      <c-box
                        height="20px"
                        width="20px"
                      >
                        <inline-svg
                          :src="require('@/assets/icons/icon-trash.svg')"
                          height="20px"
                          width="20px"
                          fill="#D32737"
                        />
                      </c-box>
                    </c-button>
                  </c-flex>

                  <FormSelect
                    :value="v.menuType"
                    width="100%"
                    label="Jenis Menu"
                    placeholder="Pilih Jenis Menu"
                    is-required
                    :options="[
                      {
                        value: 'singleEntry',
                        label: 'Single Entry',
                      },
                      {
                        value: 'multipleEntry',
                        label: 'Multiple Entry',
                      },
                    ]"
                    :is-disabled="
                      !hasMealComposition || isDuplicateMealTime || !getIsEditableForm
                    "
                    :is-invalid="isInvalidField($v.targets.$each[i].menuType)"
                    :invalid-text="isSkippedMealTime ? '' : 'Cek Ulang Data'"
                    @input="
                      (val) => {
                        v.menuType = val
                        v.menuName = null
                        v.menusArray = [
                          {
                            _menuType: val,
                            foodName: null,
                            servingSize: null,
                            servingSizeUnit: null,
                            servingSizeUnitOther: null,
                            servingSizeOtherPerGram: null,
                            photoUrl: null,
                            mealGlossariesId: null,
                          },
                        ]
                        v.menusObject = {
                          _menuType: val,
                          servingSize: null,
                          servingSizeUnit: null,
                          servingSizeUnitOther: null,
                          servingSizeOtherPerGram: null,
                          photoUrl: null,
                          mealGlossariesId: null,
                        }
                      }
                    "
                    @blur="$v.targets.$each[i].menuType.$touch"
                  />

                  <!-- Single Entry -->
                  <c-flex
                    v-if="v.menuType === 'singleEntry'"
                    flex-direction="column"
                  >
                    <c-flex
                      v-if="true"
                      gap="20px"
                      width="100%"
                      align-items="flex-start"
                    >
                      <c-flex>
                        <InputImage2
                          :photo-url="v.menusObject.photoUrl"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              isDuplicateMealTime ||
                              !getIsEditableForm
                          "
                          @delete:photo="
                            () => {
                              v.menusObject.photoUrl = null
                              v.menusObject.mealGlossariesId = null
                            }
                          "
                          @click:photo="
                            () => {
                              popupIsOpen = true
                              popupItemIndexes = [i]
                            }
                          "
                        />
                      </c-flex>

                      <c-flex
                        width="100%"
                        flex-direction="column"
                      >
                        <FormInput
                          v-model="v.menuName"
                          label="Nama Menu"
                          placeholder="Masukkan Nama Menu"
                          is-required
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              isDuplicateMealTime ||
                              !getIsEditableForm
                          "
                          :is-invalid="isInvalidField($v.targets.$each[i].menuName)"
                          invalid-text="Cek Ulang Data"
                          @blur="$v.targets.$each[i].menuName.$touch"
                        />

                        <InputTextWithSelect
                          label="Jumlah Takaran"
                          input-placeholder="Masukkan Jumlah Takaran"
                          select-placeholder="Pilih"
                          :input-value="v.menusObject.servingSize"
                          invalid-input-text="Cek Ulang Data"
                          :is-invalid-input="
                            isInvalidField($v.targets.$each[i].menusObject.servingSize)
                          "
                          :select-value="v.menusObject.servingSizeUnit"
                          invalid-select-text="Cek Ulang Data"
                          :is-invalid-select="
                            isInvalidField($v.targets.$each[i].menusObject.servingSizeUnit)
                          "
                          :select-other-value="v.menusObject.servingSizeUnitOther"
                          invalid-select-other-text="Lainnya wajib diisi"
                          :is-invalid-select-other="
                            isInvalidField(
                              $v.targets.$each[i].menusObject.servingSizeUnitOther
                            )
                          "
                          :select-options="optionPortionUnits"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              isDuplicateMealTime ||
                              !getIsEditableForm
                          "
                          @blur:input="$v.targets.$each[i].menusObject.servingSize.$touch"
                          @blur:select="
                            $v.targets.$each[i].menusObject.servingSizeUnit.$touch
                          "
                          @blur:select-other="
                            $v.targets.$each[i].menusObject.servingSizeUnitOther.$touch
                          "
                          @change:input="(v_) => (v.menusObject.servingSize = v_)"
                          @change:select="(v_) => (v.menusObject.servingSizeUnit = v_)"
                          @change:select-other="
                            (v_) => (v.menusObject.servingSizeUnitOther = v_)
                          "
                          is-without-margin-bottom
                        />
                        <c-box height="8px" />
                        <FormInput
                          v-model="v.menusObject.servingSizeOtherPerGram"
                          type="number"
                          label="Ukuran Lainnya"
                          placeholder="Masukkan Ukuran Lainnya"
                          right-element="Gram"
                          :is-disabled="
                            !v.menuType ||
                              !hasMealComposition ||
                              isDuplicateMealTime ||
                              !getIsEditableForm
                          "
                          :is-required="null"
                        />
                      </c-flex>
                    </c-flex>
                  </c-flex>
                  <!-- Multiple Entry -->

                  <TestFieldArray
                    v-else-if="v.menuType === 'multipleEntry'"
                    v-slot="{ push: push2, remove: remove2 }"
                  >
                    <FormInput
                      v-model="v.menuName"
                      label="Nama Menu"
                      placeholder="Masukkan Nama Menu"
                      is-required
                      :is-disabled="
                        !v.menuType ||
                          !hasMealComposition ||
                          isDuplicateMealTime ||
                          !getIsEditableForm
                      "
                      :is-invalid="isInvalidField($v.targets.$each[i].menuName)"
                      invalid-text="Cek Ulang Data"
                      @blur="$v.targets.$each[i].menuName.$touch"
                    />
                    <c-flex
                      v-for="(v2, i2) in v.menusArray"
                      :key="i2"
                      flex-direction="column"
                    >
                      <c-flex>
                        <c-button
                          v-if="i2 !== 0"
                          background-color="transparent"
                          :_hover="{ backgroundColor: 'neutral.lightGray' }"
                          :disabled="isDuplicateMealTime || !getIsEditableForm"
                          margin-left="auto"
                          @click="remove2(v.menusArray, i2)"
                        >
                          <c-box
                            as="span"
                            margin-right="8px"
                            font-size="16px"
                            font-weight="500"
                          >
                            Hapus komposisi makanan
                          </c-box>
                          <c-box
                            height="20px"
                            width="20px"
                          >
                            <inline-svg
                              :src="require('@/assets/icon-close-black.svg')"
                              height="20px"
                              width="20px"
                            />
                          </c-box>
                        </c-button>
                      </c-flex>

                      <c-flex
                        v-if="true"
                        gap="20px"
                        width="100%"
                        align-items="flex-start"
                      >
                        <c-flex>
                          <InputImage2
                            :photo-url="v2.photoUrl"
                            :is-disabled="
                              !v.menuType ||
                                !hasMealComposition ||
                                isDuplicateMealTime ||
                                !getIsEditableForm
                            "
                            @delete:photo="
                              () => {
                                v2.photoUrl = null
                                v2.mealGlossariesId = null
                              }
                            "
                            @click:photo="
                              () => {
                                popupIsOpen = true
                                popupItemIndexes = [i, i2]
                              }
                            "
                          />
                        </c-flex>

                        <c-flex
                          width="100%"
                          flex-direction="column"
                        >
                          <FormInput
                            v-model="v2.foodName"
                            label="Komposisi Makanan"
                            placeholder="Masukkan Komposisi Makanan"
                            is-required
                            :is-disabled="
                              !v.menuType ||
                                !hasMealComposition ||
                                isDuplicateMealTime ||
                                !getIsEditableForm
                            "
                            :is-invalid="
                              isInvalidField(
                                $v.targets.$each[i].menusArray.$each[i2].foodName
                              )
                            "
                            invalid-text="Cek Ulang Data"
                            @blur="$v.targets.$each[i].menusArray.$each[i2].foodName.$touch"
                          />
                          <InputTextWithSelect
                            label="Jumlah Takaran"
                            input-placeholder="Masukkan Jumlah Takaran"
                            select-placeholder="Pilih"
                            :input-value="v2.servingSize"
                            invalid-input-text="Cek Ulang Data"
                            :is-invalid-input="
                              isInvalidField(
                                $v.targets.$each[i].menusArray.$each[i2].servingSize
                              )
                            "
                            :select-value="v2.servingSizeUnit"
                            invalid-select-text="Cek Ulang Data"
                            :is-invalid-select="
                              isInvalidField(
                                $v.targets.$each[i].menusArray.$each[i2].servingSizeUnit
                              )
                            "
                            :select-other-value="v2.servingSizeUnitOther"
                            invalid-select-other-text="Lainnya wajib diisi"
                            :is-invalid-select-other="
                              isInvalidField(
                                $v.targets.$each[i].menusArray.$each[i2]
                                  .servingSizeUnitOther
                              )
                            "
                            :select-options="optionPortionUnits"
                            :is-disabled="
                              !v.menuType ||
                                !hasMealComposition ||
                                isDuplicateMealTime ||
                                !getIsEditableForm
                            "
                            @blur:input="
                              $v.targets.$each[i].menusArray.$each[i2].servingSize.$touch
                            "
                            @blur:select="
                              $v.targets.$each[i].menusArray.$each[i2].servingSizeUnit
                                .$touch;
                            "
                            @blur:select-other="
                              $v.targets.$each[i].menusArray.$each[i2].servingSizeUnitOther
                                .$touch;
                            "
                            @change:input="(v_) => (v2.servingSize = v_)"
                            @change:select="(v_) => (v2.servingSizeUnit = v_)"
                            @change:select-other="(v_) => (v2.servingSizeUnitOther = v_)"
                            is-without-margin-bottom
                          />
                          <c-box height="8px" />
                          <FormInput
                            v-model="v2.servingSizeOtherPerGram"
                            type="number"
                            label="Ukuran Lainnya"
                            placeholder="Masukkan Ukuran Lainnya"
                            right-element="Gram"
                            :is-required="null"
                          />
                        </c-flex>
                      </c-flex>

                      <BaseDivider
                        color="neutral.gray"
                        margin="0 0 20px 0"
                      />
                    </c-flex>

                    <c-flex justify-content="center">
                      <BaseButton
                        margin="auto"
                        size="large"
                        border-radius="1000px"
                        variant="outlined"
                        width="70%"
                        background-color="white"
                        :disabled="
                          !v.menuType ||
                            !hasMealComposition ||
                            $v.$invalid ||
                            isDuplicateMealTime ||
                            !getIsEditableForm
                        "
                        :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
                        left-svg-icon-color="#008C81"
                        @click.prevent="
                          push2(targets[i].menusArray, {
                            _menuType: v.menuType,
                            foodName: null,
                            servingSize: null,
                            servingSizeUnit: null,
                            servingSizeUnitOther: null,
                            servingSizeOtherPerGram: null,
                            photoUrl: null,
                            mealGlossariesId: null,
                          })
                        "
                      >
                        Tambah Komposisi Makanan
                      </BaseButton>
                    </c-flex>
                  </TestFieldArray>
                </c-box>

                <c-flex justify-content="center">
                  <BaseButton
                    margin="auto"
                    mt="28px"
                    size="large"
                    border-radius="1000px"
                    variant="outlined"
                    width="70%"
                    :disabled="
                      !hasMealComposition ||
                        $v.$invalid ||
                        isDuplicateMealTime ||
                        !getIsEditableForm
                    "
                    :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
                    left-svg-icon-color="#008C81"
                    @click.prevent="
                      push(targets, {
                        id: Date.now()?.toString(),
                        menuType: null, // single | multiple
                        menuName: null, // {multipleEntry Only}
                        menusObject: {
                          // {object: singleEntry | array: multipleEntry}
                          servingSize: null,
                          servingSizeUnit: null,
                          photoUrl: null,
                          servingSizeUnitOther: null,
                          servingSizeOtherPerGram: null,
                          mealGlossariesId: null,
                        },
                        menusArray: [
                          {
                            foodName: null,
                            servingSize: null,
                            servingSizeUnit: null,
                            photoUrl: null,
                            servingSizeUnitOther: null,
                            servingSizeOtherPerGram: null,
                            mealGlossariesId: null,
                          },
                        ],
                      })
                    "
                  >
                    Tambah Menu Lainnya
                  </BaseButton>
                </c-flex>
              </TestFieldArray>
            </c-box>
          </c-box>
        </c-flex>
        <c-grid
          margin-top="32px"
          w="100%"
          :template-columns="['repeat(2, 1fr)']"
          :gap="['16px']"
        >
          <c-button
            variant="outline"
            variant-color="primary"
            height="60px"
            border-radius="60px"
            font-size="18px"
            :is-disabled="isLoadingSave || saveState === null || activeStepIndex <= 0"
            @click="handlePrevious"
          >
            Sebelumnya
          </c-button>
          <c-button
            variant="solid"
            variant-color="primary"
            height="60px"
            border-radius="60px"
            font-size="18px"
            :is-disabled="
              isLoadingSave ||
                saveState === null ||
                activeStepIndex >= constants.steps.length - 1
            "
            :is-loading="isLoadingSave"
            @click="handleNext"
          >
            Selanjutnya
          </c-button>
        </c-grid>
      </c-box>
    </c-flex>
    
    <!-- MODAL -->
    <ModalSimpleInfo
      :is-open="isOpenModalSuccessSaveData"
      :image="require('@/assets/images/success-image.svg')"
      title="Berhasil Menyimpan Data"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  ...$route.params,
                  bypass: 1,
                },
              })
            "
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-warning.svg')"
      title="Gagal Menyimpan Data"
      description="Terdapat kesalahan data, coba tinjau ulang datamu"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalCreateBatchMenuRecommendations
      :client-id2="$route.params.clientId"
      :program-id2="$route.params.programId"
      :month="$route.params.month"
      :chip-days-state="{
        invalidDayValidation: [], // skip invalid state
        disabledDay: chipDaysState.disabledDay,
        mealtimeStates: listMealTimeStates,
      }"
      :option-portion-units="optionPortionUnits"
      :is-open="dataModalCreateBatchMenuRecommendations.isOpen"
      :selected-day-default-value="query.day"
      @close="dataModalCreateBatchMenuRecommendations.isOpen = false"
    />

    <ModalChooseMenu
      :is-open="popupIsOpen"
      title="Pilih Tampilan"
      :is-loading-data-food="popupIsLoadingDataFood"
      :options="popupOptions"
      :selected-foods="popupSelectedMenu"
      :pages="popupPages"
      :filter="popupFilter"
      @change:selected-food="
        (menu) => {
          if (targets?.[popupItemIndexes?.[0]]?.menusArray?.[popupItemIndexes?.[1]]) {
            targets[popupItemIndexes[0]].menusArray[popupItemIndexes[1]].photoUrl =
              menu?.photoUrl || ''
            targets[popupItemIndexes[0]].menusArray[
              popupItemIndexes[1]
            ].mealGlossariesId = menu?.id || ''
          } else if (targets?.[popupItemIndexes?.[0]]?.menusObject) {
            targets[popupItemIndexes[0]].menusObject.photoUrl = menu?.photoUrl || ''
            targets[popupItemIndexes[0]].menusObject.mealGlossariesId = menu?.id || ''
          }
          popupIsOpen = false
        }
      "
      @change:is-open="(open) => (popupIsOpen = open)"
      @change:filter-page="(page) => (popupFilter.page = page)"
      @change:filter-search="
        (search) => {
          popupFilter.page = 1
          popupFilter.search = search
        }
      "
    />

    <BaseModal
      :is-open="!!isOpenModalEditMenuDuplicate"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <CImage
            height="120px"
            object-fit="cover"
            :src="require('@/assets/images/image-question.svg')"
            alt="Image Confirmation"
          />
        </c-box>
      </template>
      <template #body>
        <c-box
          display="flex"
          flex-direction="column"
          align-items="center"
          px="24px"
          py="16px"
          text-align="center"
          max-width="500px"
          mx="auto"
        >
          <BaseText
            size-mobile="16px"
            size-desktop="20px"
            color="primary.400"
          >
            Apakah ingin mengubah data menu duplikasi?
          </BaseText>
        </c-box>
      </template>
      <template #footer>
        <c-box
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin="24px 0"
        >
          <c-flex gap="8px">
            <BaseButton
              :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
              left-svg-icon-color="#008C81"
              color="primary"
              rounded="1000px"
              width="100%"
              variant="outlined"
              :is-disabled="isLoadingEditMenuDuplicate"
              @click="onCloseEditMenuDuplicate"
            >
              Batal
            </BaseButton>
            <BaseButton
              :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
              right-svg-icon-color="white"
              color="primary"
              rounded="1000px"
              width="100%"
              :is-loading="isLoadingEditMenuDuplicate"
              @click="onSubmitEditMenuDuplicate"
            >
              Ya
            </BaseButton>
          </c-flex>
        </c-box>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import FormInput from '@/views/client/kuisioner/forms/form-input-2'
import FormTime from '@/views/client/kuisioner/forms/form-time'
import BaseButton from '@/components/elements/base-button.vue'
import BaseText from '@/components/elements/base-text.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import { reqNutriMealPlan } from '@/requests/dietela-api/nutritionist/meal-plans'
import {
  requiredIf,
  required,
  helpers,
  or,
  decimal,
  maxLength,
} from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { CBox, CButton, CDivider, CFlex, CGrid, CText, CTooltip } from '@chakra-ui/vue'
import {
  tabsMealPlan,
  mealPlanSteps,
  mealPlanStepsWithCustomProperty,
  mealPlanValueToLabel,
} from '@/constants/meal-plans'
import LineStep from '@/components/line-step.vue'
import Info2 from '@/components/info-2.vue'
import MealTimeHeader from '@/components/meal-time-header.vue'
import CardMealPortionGuide from '@/components/card-meal-portion-guide.vue'
import NoData2 from '@/components/no-data-2.vue'
import TestFieldArray from '@/views/profile/detail/test-field-array.vue'
import InputTextWithSelect from '@/components/input-text-with-select.vue'
import FormSelect from '@/views/client/kuisioner/forms/form-select-2.vue'
import ModalChooseMenu from '@/components/modal-choose-menu.vue'
import {
  reqMenuRecommendation_Create,
  reqMenuRecommendation_MealPlanDetail,
  reqMenuRecommendation_PortionUnit,
} from '@/requests/dietela-api/other'
import InputImage2 from '@/components/input-image-2.vue'
import BaseDivider from '@/components/elements/base-divider.vue'
import { isDataEmpty } from '@/utils/is-data-empty'
import ChipDays from '@/components/chip-days.vue'
import ModalCreateBatchMenuRecommendations from '@/components/meal-plan/_widgets/modal-create-batch-menu-recommendations.vue'
// import ModalDuplicate from '@/components/meal-plan/_widgets/modal-duplicate-menu-recommendation.vue'
import ModalMenuDuplicate from '@/components/meal-plan/_widgets/modal-duplicate-menu-in-menu-recommendation.vue'
import { reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus } from '@/requests/dietela-api/nutritionist/other'
import mixinsTabsMealPlan from '@/mixins/mixins-tabs-meal-plan'
import ChipFilter from '@/components/chip-filter.vue'

const DEFAULT_DATA_MENU_VALUE = [
  {
    id: Date.now()?.toString(),
    menuType: null, // single | multiple
    menuName: null, // {multipleEntry Only}
    menusObject: {
      // {object: singleEntry | array: multipleEntry}
      _menuType: null,
      servingSize: null,
      servingSizeUnit: null,
      photoUrl: null,
      servingSizeUnitOther: null,
      servingSizeOtherPerGram: null,
      mealGlossariesId: null,
    },
    menusArray: [
      {
        _menuType: null,
        foodName: null,
        servingSize: null,
        servingSizeUnit: null,
        photoUrl: null,
        servingSizeUnitOther: null,
        servingSizeOtherPerGram: null,
        mealGlossariesId: null,
      },
    ],
  },
]

const fraction = helpers.regex('fraction', /^[1-9][0-9]*\/[1-9][0-9]*$/)

export default {
  name: 'FormMenuRecommendation',
  components: {
    ModalCreateBatchMenuRecommendations,
    // ModalDuplicate,
    ChipDays,
    BaseDivider,
    InputImage2,
    ModalChooseMenu,
    FormSelect,
    InputTextWithSelect,
    CButton,
    CBox,
    TestFieldArray,
    NoData2,
    CardMealPortionGuide,
    MealTimeHeader,
    Info2,
    LineStep,
    CFlex,
    CText,
    CDivider,
    CTooltip,
    CGrid,
    ModalSimpleInfo,
    FormInput,
    FormTime,
    BaseButton,
    BaseText,
    BaseModal,
    ModalMenuDuplicate,
    ChipFilter,
  },
  mixins: [mixinsTabsMealPlan],
  props: {
    draft: {
      type: Object,
      default: () => {},
    },
  },
  emits: ['update:validation'],
  data() {
    return {
      dataModalCreateBatchMenuRecommendations: {
        isOpen: false,
      },
      popupIsLoadingDataFood: false,
      popupItemIndexes: [],
      popupIsOpen: false,
      popupFilter: {
        search: '',
        page: 1,
        limit: 10,
      },
      popupMeta: {
        total: 0,
        page: 0,
        pagesize: 0,
      },
      popupSelectedMenu: {},
      popupPages: [1],
      popupOptions: [],

      photoUrl: null,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      isLoadingSave: false,

      startTime: '',
      endTime: '',
      caloriesNeedPercent: null,
      caloriesAmount: null,
      mealComposition: [],
      optionPortionUnits: [],
      duplicationDetail: null,
      isSaveDraft: null,

      // form: {
      //   breakfast: {
      //     isSkipped: false,
      //     startTime: '',
      //     endTime: '',
      //     caloriesNeedPercent: null,
      //     caloriesAmount: null,
      //     remainingCaloriesTotal: null,
      //     mealComposition: [],
      //     validation: 0,
      //   },
      // },
      targets: _.cloneDeep(DEFAULT_DATA_MENU_VALUE),
      targetsDuplicate: _.cloneDeep(DEFAULT_DATA_MENU_VALUE),
      // formDuplicate: null,
      isLoadingData: false,
      currentPreviewDay: null,
      listMealTimeStates: {},
      dataDays: [],
      isOpenModalEditMenuDuplicate: false,
      isLoadingEditMenuDuplicate: false,
      preview: null,
    }
  },
  computed: {
    ...mapGetters({
      isValidTabNutritionalProfile: 'mealPlan2/isValidTabNutritionalProfile',
      isValidTabMealPlan: 'mealPlan2/isValidTabMealPlan',
      defaultItems: 'mealPlan/defaultItems',
      saveState: 'general/saveState',
    }),
    getIsEditableForm() {
      return this.isValidTabNutritionalProfile && this.isValidTabMealPlan
    },
    isSkippedMealTime() {
      return this.listMealTimeStates?.[this.query?.mealTime]?.isSkipped
    },
    isValidMealTime() {
      return this.listMealTimeStates?.[this.query?.mealTime]?.isValid
    },
    chipDaysState() {
      return this.dataDays?.reduce(
        (acc, curr) => {
          if (parseInt(curr?.day) === parseInt(this.query.day) && this.isValidAllForm) {
            // skipped
          } else if (
            parseInt(curr?.day) === parseInt(this.query.day) &&
            !this.isValidAllForm
          ) {
            acc.invalidDayValidation?.push(curr?.day)
          } else if (curr?.validation === -1 || curr?.validation === 0) {
            acc.invalidDayValidation?.push(curr?.day)
          }

          if (curr?.isEnabled !== 1) {
            acc.disabledDay.push(curr?.day)
          }

          if (curr?.isBulkCreate) {
            acc.dotBadgeDay.push(curr?.day)
          }
          return acc
        },
        {
          invalidDayValidation: [],
          disabledDay: [],
          dotBadgeDay: [],
        },
      )
    },
    currentActiveDays() {
      return [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]
    },
    query() {
      return {
        mealTime: this.$route.query?.mealTime || '',
        day: this.$route.query?.day || '',
      }
    },
    hasMealComposition() {
      return this.mealComposition?.length >= 1
    },
    targets2() {
      return this.targets
    },
    watchPopupFilterAndMealComposition() {
      return {
        popupFilter: this.popupFilter,
        mealComposition: this.mealComposition,
      }
    },
    mealPlanSteps2() {
      const getColor = (validation) => {
        switch (validation) {
          case 1:
            return 'primary'
          case -1:
            return 'danger'
          default:
            return 'neutral'
        }
      }
      const disabled_ = this.isLoadingSave || this.saveState === null
      return mealPlanStepsWithCustomProperty({
        // eslint-disable-next-line no-constant-condition
        breakfast: {
          tooltip: this.listMealTimeStates?.breakfast?.isSkipped
            ? 'Jadwal ini diskip'
            : null,
          color: getColor(
            this.listMealTimeStates?.breakfast?.isSkipped || !this.getIsEditableForm
              ? null
              : this.draft?.breakfast?.validation,
          ),
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: 'breakfast',
              },
            })
          },
        },
        morningSnack: {
          tooltip: this.listMealTimeStates?.morningSnack?.isSkipped
            ? 'Jadwal ini diskip'
            : null,
          color: getColor(
            this.listMealTimeStates?.morningSnack?.isSkipped || !this.getIsEditableForm
              ? null
              : this.draft?.morningSnack?.validation,
          ),
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: 'morningSnack',
              },
            })
          },
        },
        lunch: {
          tooltip: this.listMealTimeStates?.lunch?.isSkipped ? 'Jadwal ini diskip' : null,
          color: getColor(
            this.listMealTimeStates?.lunch?.isSkipped || !this.getIsEditableForm
              ? null
              : this.draft?.lunch?.validation,
          ),
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: 'lunch',
              },
            })
          },
        },
        afternoonSnack: {
          tooltip: this.listMealTimeStates?.afternoonSnack?.isSkipped
            ? 'Jadwal ini diskip'
            : null,
          color: getColor(
            this.listMealTimeStates?.afternoonSnack?.isSkipped || !this.getIsEditableForm
              ? null
              : this.draft?.afternoonSnack?.validation,
          ),
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: 'afternoonSnack',
              },
            })
          },
        },
        dinner: {
          tooltip: this.listMealTimeStates?.dinner?.isSkipped
            ? 'Jadwal ini diskip'
            : null,
          color: getColor(
            this.listMealTimeStates?.dinner?.isSkipped || !this.getIsEditableForm
              ? null
              : this.draft?.dinner?.validation,
          ),
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: 'dinner',
              },
            })
          },
        },
        nightSnack: {
          tooltip: this.listMealTimeStates?.nightSnack?.isSkipped
            ? 'Jadwal ini diskip'
            : null,
          color: getColor(
            this.listMealTimeStates?.nightSnack?.isSkipped || !this.getIsEditableForm
              ? null
              : this.draft?.nightSnack?.validation,
          ),
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: 'nightSnack',
              },
            })
          },
        },
        [this?.query?.mealTime]: {
          tooltip: this.listMealTimeStates?.[this?.query?.mealTime]?.isSkipped
            ? 'Jadwal ini diskip'
            : null,
          color: this.hasMealComposition
            ? getColor(
              _.isEqual(this.targets, DEFAULT_DATA_MENU_VALUE) ||
                  this.listMealTimeStates?.[this?.query?.mealTime]?.isSkipped ||
                  !this.getIsEditableForm
                ? null
                : this.$v.$invalid
                  ? -1
                  : 1,
            )
            : null,
          disabled: disabled_,
          onClick: () => {
            if (disabled_) return
            this.$router.push({
              ...this.$route,
              query: {
                ...this.$route.query,
                mealTime: this?.query?.mealTime,
              },
            })
          },
        },
      })
    },
    constants() {
      return {
        steps: mealPlanSteps,
        tabsMealPlan,
        mealPlanValueToLabel,
      }
    },
    activeStepIndex() {
      if (!this.query.mealTime) return -1
      return this.constants.steps.findIndex((it) => it.value === this.query.mealTime)
    },
    isValidAllForm() {
      return (
        Object.values({
          breakfast:
            this.draft?.breakfast?.validation === 1 ||
            this.listMealTimeStates?.breakfast?.isSkipped,
          morningSnack:
            this.draft?.morningSnack?.validation === 1 ||
            this.listMealTimeStates?.morningSnack?.isSkipped,
          lunch:
            this.draft?.lunch?.validation === 1 ||
            this.listMealTimeStates?.lunch?.isSkipped,
          afternoonSnack:
            this.draft?.afternoonSnack?.validation === 1 ||
            this.listMealTimeStates?.afternoonSnack?.isSkipped,
          dinner:
            this.draft?.dinner?.validation === 1 ||
            this.listMealTimeStates?.dinner?.isSkipped,
          nightSnack:
            this.draft?.nightSnack?.validation === 1 ||
            this.listMealTimeStates?.nightSnack?.isSkipped,
          [this?.query?.mealTime]:
            !this.$v.$invalid ||
            this.listMealTimeStates?.[this?.query?.mealTime]?.isSkipped,
        })?.every((v) => v) || false
      )
    },
    isValidAllFormForAllDays() {
      return (
        this.dataDays
          ?.filter((v) => v?.isEnabled === 1)
          .filter((v) => parseInt(v?.day) !== parseInt(this.query.day))
          ?.every((v) => v?.validation === 1) && this.isValidAllForm
      )
    },
    // hasChangeForm() {
    //   return !_.isEqual(this.form, this.formDuplicate)
    // },
    iconCloud() {
      switch (this.saveState) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
          }
      }
    },
    isDuplicateMealTime() {
      return this.duplicationDetail && this.duplicationDetail?.isEdited == 0
        ? true
        : false
    },
    optionsPanduanPorsi() {
      return this.constants.steps.map((item) => {
        return {
          id: item.value,
          label: item.label,
          disabled: false, // need to update
        }
      })
    },
  },
  watch: {
    watchPopupFilterAndMealComposition: {
      // Fetch Popup Content
      async handler(value) {
        this.popupIsLoadingDataFood = true
        const resPopupContent = await reqNutriMealPlan.getMealGlossaries(
          this.$store.getters.axios,
          {
            q: value?.popupFilter?.search,
            page: value?.popupFilter?.page,
            perpage: value?.popupFilter?.limit,
          },
        )
        const dataPopupContent = resPopupContent.data
        if (dataPopupContent) {
          this.popupMeta = dataPopupContent?.meta
          this.popupOptions = dataPopupContent?.data?.result
          this.popupPages = Array.from(
            { length: Math.ceil(this.popupMeta.total / this.popupMeta.pagesize) },
            (_, i) => i + 1,
          )
        }
        this.popupIsLoadingDataFood = false
      },
      immediate: true,
      deep: true,
    },
    targets2: {
      handler() {
        this.setSaveState(null)
      },
      deep: true,
    },
    targets: {
      handler: _.debounce(function() {
        const data = {
          form: 'menu-recommendation',
          validation: this.isValidAllForm ? 1 : -1,
        }
        this.$emit('update:validation', data)

        // if (_.isEqual(this.targets, this.targetsDuplicate)) {
        //   this.setSaveState(true)
        //   return
        // }
        this.onSaveDraft()
      }, 1000),
      deep: true,
    },
  },
  async mounted() {
    this.isLoadingData = true

    // set default "currentPreviewDay" data
    this.currentPreviewDay = this.query.day >= 8 ? 2 : 1
    await this.getFormDays()

    // Fetch Preview Data
    const resDetailPreview = await reqMenuRecommendation_MealPlanDetail(
      this.$store.getters.axios,
      {
        clientId: this.$route.params.clientId,
        programId: this.$route.params.programId,
        month: this.$route.params.month,
        // mealTime: this.query.mealTime,
      },
    )

    this.preview = resDetailPreview.data?.data

    // Get List Meal Time States
    this.listMealTimeStates =
      resDetailPreview.data?.data?.reduce((acc, curr) => {
        acc[curr?.mealTime] = {
          isSkipped: curr?.isSkipped,
          isValid: curr?.validation === 1,
        }
        return acc
      }, {}) || {}
    const dataDetailPreview =
      resDetailPreview.data?.data?.find((v) => v?.mealTime === this.query.mealTime) || {}
    if (dataDetailPreview) {
      this.startTime = dataDetailPreview?.startTime
      this.endTime = dataDetailPreview?.endTime
      this.caloriesNeedPercent = dataDetailPreview?.caloriesNeedPercent
      this.caloriesAmount = dataDetailPreview?.caloriesAmount
      this.mealComposition = dataDetailPreview?.mealComposition
    }

    // Fetch Portion Unit
    const resPortionUnit = await reqMenuRecommendation_PortionUnit(
      this.$store.getters.axios,
    )
    const dataPortionUnit = resPortionUnit.data?.data
    let tempForCheckDuplicate = {}
    if (dataPortionUnit) {
      this.optionPortionUnits =
        dataPortionUnit?.allUnit?.reduce((acc, curr) => {
          if (tempForCheckDuplicate[curr?.value]) {
            return acc
          }
          tempForCheckDuplicate[curr?.value] = true
          acc.push({
            value: curr?.value,
            label: curr?.value,
          })

          return acc
        }, []) || []
      this.optionPortionUnits.push({
        value: 'Lainnya',
        label: 'Lainnya',
      })
    }

    // Get Data List Menu
    const d = this.draft?.[this.query.mealTime]?.dataMenu || []
    this.isSaveDraft = this.draft?.isSaveDraf
    this.targets =
      d?.length >= 1
        ? this.parseDataMenuPayloadToComponent(d)
        : _.cloneDeep(DEFAULT_DATA_MENU_VALUE)
    this.targetsDuplicate = _.cloneDeep(this.targets)
    this.duplicationDetail = this.draft?.[this.query.mealTime]?.duplicationDetail

    if (this.draft?.isBulkCreate) {
      this.updateBulkStatus()
    }
  },
  methods: {
    ...mapMutations({
      setSaveState: 'general/setSaveState',
      setEditValidation: 'mealPlan/setEditValidation',
    }),
    photoValidation(value, parentValue) {
      if (!parentValue?.hasPhoto || !parentValue?.isEat) {
        return true
      }
      return value.some((v) => !isDataEmpty(v))
    },
    mergeMealItems(portionDecimal, mealItems, additionalMealItems) {
      const remapMealItems =
        mealItems?.map((v) => {
          return {
            ...v,
            servingSize: parseFloat(v?.householdMeasurement || 0),
            servingSizeUnit: v?.householdMeasurementUnit,
          }
        }) || []
      const remapAdditionalMealItems =
        additionalMealItems?.map((v) => {
          return {
            ...v,
            servingSize:
              parseFloat(v?.householdMeasurementPerPortion || 0) *
              parseFloat(portionDecimal || 0),
            servingSizeUnit: v?.householdMeasurementUnitPerPortion,
          }
        }) || []

      return remapMealItems.concat(remapAdditionalMealItems)
    },
    getPreviewImage(url) {
      if (this.photoUrl) {
        return URL.createObjectURL(this.photoUrl)
      }
      if (url) {
        return url
      }
      return require('@/assets/icon-photo.svg')
    },
    isInvalidField,
    async getFormDays() {
      // Fetch days
      const resDays = await reqNutriMealPlan.getMenuRecommendationFormDays(
        this.$store.getters.axios,
        {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
        },
      )
      this.dataDays = resDays.data?.data
    },
    async handlePrevious() {
      if (!this.isLoadingSave) {
        // await this.onCheckSaveDraft()
        this.onSaveDraft()
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            mealTime: this.constants.steps[this.activeStepIndex - 1]?.value,
          },
        })
      }
    },
    async handleNext() {
      if (!this.isLoadingSave) {
        // await this.onCheckSaveDraft()
        this.onSaveDraft()
        this.$router.push({
          ...this.$route,
          query: {
            ...this.$route.query,
            mealTime: this.constants.steps[this.activeStepIndex + 1]?.value,
          },
        })
      }
    },
    async onSave() {
      try {
        await this.onSaveDraft(1)
        this.isOpenModalSuccessSaveData = true
      } catch (err) {
        this.isOpenModalFailedSaveData = true
      }
    },
    parseErrors,
    ...mapMutations({
      setDefaultItems: 'mealPlan/setDefaultItems',
    }),
    // onDuplicateForm() {
    //   this.formDuplicate = _.cloneDeep(this.form)
    // },
    // onCheckSaveDraft() {
    //   if ((!this.saveState) && !this.isLoadingData) {
    //     this.onSaveDraft()
    //   }
    // },
    parseDataMenuComponentToPayload(dataMenu) {
      return dataMenu.map((v) => {
        return {
          menuType: v?.menuType,
          menuName: v?.menuName,
          menus:
            v?.menuType === 'singleEntry'
              ? {
                servingSize: v?.menusObject?.servingSize,
                servingSizeUnit: v?.menusObject?.servingSizeUnit,
                photoUrl: v?.menusObject?.photoUrl,
                servingSizeUnitOther: v?.menusObject?.servingSizeUnitOther,
                servingSizeOtherPerGram: v?.menusObject?.servingSizeOtherPerGram,
                mealGlossariesId: v?.menusObject?.mealGlossariesId,
              }
              : v?.menusArray?.map((v2) => {
                return {
                  foodName: v2?.foodName,
                  servingSize: v2?.servingSize,
                  servingSizeUnit: v2?.servingSizeUnit,
                  photoUrl: v2?.photoUrl,
                  servingSizeUnitOther: v2?.servingSizeUnitOther,
                  servingSizeOtherPerGram: v2?.servingSizeOtherPerGram,
                  mealGlossariesId: v2?.mealGlossariesId,
                }
              }),
        }
      })
    },
    parseDataMenuPayloadToComponent(dataMenu) {
      return dataMenu?.map((v) => {
        return {
          menuType: v?.menuType,
          menuName: v?.menuName,
          menusObject: {
            _menuType: v?.menuType,
            servingSize: v?.menus?.servingSize,
            servingSizeUnit: v?.menus?.servingSizeUnit,
            photoUrl: v?.menus?.photoUrl,
            servingSizeUnitOther: v?.menus?.servingSizeUnitOther,
            servingSizeOtherPerGram: v?.menus?.servingSizeOtherPerGram,
            mealGlossariesId: v?.menus?.mealGlossariesId,
          },
          menusArray:
            v?.menus?.length >= 1
              ? v?.menus?.map((v2) => {
                return {
                  _menuType: v?.menuType,
                  foodName: v2?.foodName,
                  servingSize: v2?.servingSize,
                  servingSizeUnit: v2?.servingSizeUnit,
                  photoUrl: v2?.photoUrl,
                  servingSizeUnitOther: v2?.servingSizeUnitOther,
                  servingSizeOtherPerGram: v2?.servingSizeOtherPerGram,
                  mealGlossariesId: v2?.mealGlossariesId,
                }
              })
              : DEFAULT_DATA_MENU_VALUE[0].menusArray,
        }
      })
    },
    async onSaveDraft(isSaveDraf = 0) {
      try {
        this.isLoadingSave = true
        this.setSaveState(null)

        let payload = {
          // ...this.draft,
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          isDraft: 1,
          isSaveDraf: isSaveDraf,
          day: this.query.day,
          validation: this.isValidAllForm ? 1 : -1, // {-1 | 1}
          [this.query.mealTime]: this.listMealTimeStates?.[this.query?.mealTime]
            ?.isSkipped
            ? {
              isSkipped: true,
              validation: 1,
              duplicationDetail: null,
              startTime: null,
              endTime: null,
              caloriesNeedPercent: null,
              caloriesAmount: null,
              dataMenu: null,
            }
            : {
              isSkipped: false,
              validation: this.$v.$invalid ? -1 : 1,
              duplicationDetail: this.duplicationDetail,
              startTime: this.startTime,
              endTime: this.endTime,
              caloriesNeedPercent: this.caloriesNeedPercent,
              caloriesAmount: this.caloriesAmount,
              dataMenu: this.parseDataMenuComponentToPayload(this.targets),
            },
          /** save data skipped meal time without visit the page */
          ...Object.keys(this.listMealTimeStates)?.reduce((acc, curr) => {
            if (this.listMealTimeStates?.[curr]?.isSkipped) {
              acc[curr] = {
                isSkipped: true,
                validation: 1,
                duplicationDetail: null,
                startTime: null,
                endTime: null,
                caloriesNeedPercent: null,
                caloriesAmount: null,
                dataMenu: null,
              }
            }
            return acc
          }, {}),
        }

        this.isSaveDraft = isSaveDraf

        await reqMenuRecommendation_Create(this.$store.getters.axios, payload)
        this.$v.$touch()

        // this.onDuplicateForm()
        this.setSaveState(true)

        this.setEditValidation({
          routeName: this.$route.name,
          validation: this.isValidAllForm ? 1 : -1,
        })
      } catch (err) {
        this.setSaveState(false)
        this.isOpenModalFailedSaveData = true
      } finally {
        this.isLoadingSave = false
      }
    },
    onOpenEditMenuDuplicate() {
      this.isOpenModalEditMenuDuplicate = true
    },
    onCloseEditMenuDuplicate() {
      if (!this.isLoadingEditMenuDuplicate) {
        this.isOpenModalEditMenuDuplicate = false
      }
    },
    async onSubmitEditMenuDuplicate() {
      try {
        this.isLoadingEditMenuDuplicate = true
        this.duplicationDetail = {
          ...this.duplicationDetail,
          isEdited: 1,
        }
        await this.onSaveDraft()
      } finally {
        this.isLoadingEditMenuDuplicate = false
        this.onCloseEditMenuDuplicate()
      }
    },
    async updateBulkStatus() {
      const menuRecommendationId = this.draft?.id
      await reqNutritionist_mealPlans_menuRecommendation_bulkCreateStatus(
        this.$store.getters.axios,
        {
          id: menuRecommendationId,
        },
      )
      this.getFormDays()
    },
  },
  validations() {
    return {
      targets: {
        $each: {
          menuType: {
            required,
          },
          menuName: {
            required,
          },
          menusObject: {
            servingSize: {
              required: requiredIf((it) => it._menuType === 'singleEntry'),
              decimalFraction: or(decimal, fraction),
            },
            servingSizeUnit: {
              required: requiredIf((it) => it._menuType === 'singleEntry'),
            },
            servingSizeUnitOther: {
              required: requiredIf((it) => it.servingSizeUnit === 'Lainnya'),
              maxLength: maxLength(20),
            },
          },
          menusArray: {
            $each: {
              foodName: {
                required: requiredIf((it) => it._menuType === 'multipleEntry'),
              },
              servingSize: {
                required: requiredIf((it) => it._menuType === 'multipleEntry'),
                decimalFraction: or(decimal, fraction),
              },
              servingSizeUnit: {
                required: requiredIf((it) => it._menuType === 'multipleEntry'),
              },
              servingSizeUnitOther: {
                required: requiredIf((it) => it.servingSizeUnit === 'Lainnya'),
                maxLength: maxLength(20),
              },
            },
          },
        },
      },
    }
  },
}
</script>
