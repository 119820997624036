<template>
  <c-box>
    <c-flex
      align-items="center"
      justify-content="space-between"
    >
      <c-button
        variant="ghost"
        width="30px"
        min-width="30px"
        height="30px"
        margin="0"
        padding="0"
        margin-right="16px"
        @click="$router.push({
          ...$route,
          name: 'nutri.meal-plan.meal-plan-schedule.detail',
        })
        "
      >
        <c-box
          :width="['12px', '15px']"
          :height="['12px', '15px']"
        >
          <inline-svg
            :src="require('@/assets/icons/icon-arrow-down.svg')"
            height="100%"
            width="100%"
            fill="#008C81"
            style="transform: rotate(90deg);"
          />
        </c-box>
      </c-button>
      <TabsMealPlan 
        mode="edit"
        :tabs="subTabs"
        :is-loading="isLoadingSave || saveState === null"
      />
      <c-tooltip
        :label="iconCloud.text"
        placement="bottom"
      >
        <c-box
          :width="['35px']"
          :height="['35px']"
          margin-right="16px"
          flex-shrink="0"
          :class="saveState === null ? 'animate-spin' : ''"
        >
          <inline-svg
            :src="iconCloud.icon"
            height="100%"
            width="100%"
            fill="#008C81"
          />
        </c-box>
      </c-tooltip>
      <BaseButton
        :left-svg-icon="require('@/assets/icons/icon-save.svg')"
        left-svg-icon-color="white"
        :disabled="!isValidAllMealPlanForm || saveState === null || isLoadingSave"
        @click="onSaveDraft"
      >
        Simpan
      </BaseButton>
    </c-flex>
    <c-box margin-top="16px">
      <c-flex
        v-if="!isProfilGiziValid"
        margin-top="16px"
        background-color="danger.50"
        padding="6px 10px"
        border-radius="8px"
        align-items="center"
        gap="10px"
        margin-bottom="16px"
      >
        <inline-svg
          :src="require('@/assets/icons/icon-circle-warn.svg')"
          height="20px"
          width="20px"
          fill="#D32737"
        />
        <c-text
          color="danger.400"
          font-size="14px"
        >
          Selesaikan formulir Profil Gizi terlebih dahulu untuk mengaktifkan formulir ini
        </c-text>
      </c-flex>

      <!-- simple nutritional info -->
      <Observer
        root-margin="-100px"
        @on-change="onChange"
      >
        <div>&nbsp;</div>
      </Observer>
      <c-flex
        gap="8px"
        padding-block="24px"
        position="sticky"
        top="83px"
        background-color="white"
        z-index="20"
        :box-shadow="boxShadowSimpleNutrionalInfo"
      >
        <c-box
          v-for="[nutri, nutriValue] in Object.entries(getSimpleNutritionalInfo).toSorted((a, b) => a - b)"
          :key="nutri"
          padding-right="12px"
          width="100%"
        >
          <CardInfoSimpleNutritionalProperty
            :title="nutri"
            :requirement="nutriValue.target"
            :current="nutriValue.current"
            :unit="nutriValue.unit"
          />
        </c-box>
      </c-flex>

      <MealPlanSteps
        :active-step="step"
        :draft="preview?.mealPlanSchedules || []"
        :validation="form[step]?.isSkipped ? 1 : ((isFormInvalid || remainingTotalMealCompositionCalories === null || remainingTotalMealCompositionCalories < 0) ? -1 : 1)"
        :is-loading="isLoadingSave || isLoadingData || saveState === null"
        @save="onCheckSaveDraft"
      />
      
      <c-flex
        justify-content="space-between"
        align-items="center"
        font-size="16px"
        margin-bottom="16px"
      >
        <c-box
          font-weight="500"
          :color="remainingPercentTotalEnergyNeeded >= 0 ? 'primary.400' : 'danger.400'"
        >
          Sisa kalori makanan yang dibutuhkan: {{ remainingPercentTotalEnergyNeeded }}%
        </c-box>
        <c-flex
          align-items="center"
          gap="6px"
          color="primary.400"
        >
          Skip jam makan 
          <c-box
            :cursor="!isProfilGiziValid ? 'not-allowed' : 'pointer'"
            @click="onToggleSkip(step)"
          >
            <c-checkbox
              size="lg"
              variant-color="primary"
              :pointer-events="'none'"
              :is-checked="form[step]?.isSkipped"
              :is-disabled="!isProfilGiziValid"
            />
          </c-box>
        </c-flex>
      </c-flex>

      <c-box>
        <c-grid
          width="100%"
          template-columns="repeat(2, 1fr)"
          gap="16px"
        >
          <FormTime 
            v-model="form[step].startTime"
            label="Jam Mulai Makan"
            is-required
            :is-disabled="form[step]?.isSkipped || !isProfilGiziValid"
            :is-invalid="isInvalidField($v.form[step].startTime)"
            :invalid-text="parseErrors('Jam Mulai Makan', $v.form[step].startTime)"
            @blur="$v.form[step].startTime.$touch"
          />
          <FormTime
            v-model="form[step].endTime"
            label="Jam Akhir Makan"
            is-required
            :is-disabled="form[step]?.isSkipped || !isProfilGiziValid"
            :is-invalid="isInvalidField($v.form[step].endTime)"
            :invalid-text="parseErrors('Jam Akhir Makan', $v.form[step].endTime)"
            @blur="$v.form[step].endTime.$touch"
          />
        </c-grid>

        <FormInput
          v-model="form[step].caloriesNeedPercent"
          type="number"
          label="Kebutuhan Jumlah Kalori"
          placeholder="Masukkan alokasi % kalori untuk waktu makan ini"
          is-required
          :is-disabled="form[step]?.isSkipped || !isProfilGiziValid"
          right-element="%"
          :is-invalid="!isValidCaloriesNeedPercent || isInvalidField($v.form[step].caloriesNeedPercent)"
          :invalid-text="isValidCaloriesNeedPercent ? parseErrors('Jumlah Kalori', $v.form[step].caloriesNeedPercent, customMessageErrors) : `Kebutuhan jumlah kalori tidak boleh melebihi 110%`"
          @blur="$v.form[step].caloriesNeedPercent.$touch"
        />
        <FormInput
          v-model="form[step].caloriesAmount"
          type="number"
          label="Kkal - Jumlah Kalori"
          placeholder="Jumlah Kalori"
          is-required
          is-disabled
          right-element="Kkal"
        />

        <c-box
          margin-bottom="16px"
        >
          <Info
            variant="info"
            text="Daftar golongan makanan di bawah ini opsional."
          />
        </c-box>
        
        <c-box
          v-if="remainingTotalMealCompositionCalories && remainingTotalMealCompositionCalories < 0"
          margin-bottom="16px"
        >
          <Info
            variant="warning"
            :text="`Porsi yang dimasukan melebihi kebutuhan jumlah kalori sebesar ${parseFloatToFixed2(Math.abs(remainingTotalMealCompositionCalories), 2)}`"
          />
        </c-box>

        <c-box
          v-if="getIsValidTotalMacroNutrient === -1"
          margin-bottom="16px"
        >
          <Info
            variant="warning"
            text="Zat gizi makro yang dimasukan melebihi kebutuhan"
          />
        </c-box>

        <AccordionMealPlan
          :menus="form[step].mealComposition"
          :is-disabled="form[step].isSkipped || !isProfilGiziValid"
          :remaining-calories="remainingTotalMealCompositionCalories"
          @update:menus="onChangeMenus"
        />
      </c-box>
    </c-box>
    <c-grid
      w="100%"
      :template-columns="['repeat(2, 1fr)']"
      :gap="['16px']"
    >
      <c-button
        variant="outline"
        variant-color="primary"
        height="60px"
        border-radius="60px"
        font-size="18px"
        :is-disabled="isLoadingSave || saveState === null || (activeStepIndex <= 0)"
        @click="handlePrevious"
      >
        Sebelumnya
      </c-button>
      <c-button
        variant="solid"
        variant-color="primary"
        height="60px"
        border-radius="60px"
        font-size="18px"
        :is-disabled="isLoadingSave || saveState === null || (activeStepIndex >= (constants.steps.length -1))"
        @click="handleNext"
      >
        Selanjutnya
      </c-button>
    </c-grid>

    <!-- MODAL -->
    <ModalSimpleInfo
      :is-open="isOpenModalSuccessSaveData"
      :image="require('@/assets/images/image-question.svg')"
      title="Data Rencana Makan berhasil disimpan"
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="
              $router.push({
                ...$route,
                name: 'nutri.meal-plan.menu-recommendation.detail',
                params: {
                  bypass: 1,
                }
              })
            "
          >
            Oke
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>

    <ModalSimpleInfo
      :is-open="isOpenModalFailedSaveData"
      :image="require('@/assets/images/image-question.svg')"
      title="Ups, Gagal Menyimpan Data"
      description="Mohon maaf, sepertinya terdapat kesalahan data atau data belum terisi semua. Mohon untuk mengecek ulang data anda."
    >
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding="0 24px"
          margin-bottom="24px"
        >
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-circle-check.svg')"
            right-svg-icon-color="white"
            color="primary"
            rounded="1000px"
            width="100%"
            @click="isOpenModalFailedSaveData = false"
          >
            Konfirmasi
          </BaseButton>
        </c-flex>
      </template>
    </ModalSimpleInfo>
  </c-box>
</template>

<script>
import _ from 'lodash'
import Observer from 'vue-intersection-observer'
import FormInput from '@/views/client/kuisioner/forms/form-input-2'
import FormTime from '@/views/client/kuisioner/forms/form-time'
import TabsMealPlan from '@/components/meal-plan/_widgets/tab-meal-plan.vue'
import BaseButton from '@/components/elements/base-button.vue'
import MealPlanSteps from '@/components/meal-plan/_widgets/meal-plan-steps.vue'
import AccordionMealPlan from '@/components/meal-plan/_widgets/accordion-meal-plan'
import Info from '@/components/info.vue'
import { reqNutriMealPlan, reqNutri_mealPlans_menuRecommendation_isSkip } from '@/requests/dietela-api/nutritionist/meal-plans'
import { requiredIf, minValue, maxValue } from 'vuelidate/lib/validators'
import { mapGetters, mapMutations } from 'vuex'
import { isInvalidField } from '@/utils/vuelidate-helpers/is-invalid-field'
import { parseErrors } from '@/utils/vuelidate-helpers/parse-errors'
import ModalSimpleInfo from '@/components/elements/modal-simple-info.vue'
import { CFlex } from '@chakra-ui/vue'
import { tabsMealPlan, mealPlanSteps, mealPlansConstant } from '@/constants/meal-plans'
import { isAllFormValid } from '@/components/meal-plan/_utils'
import { parseFloatToFixed2 } from '@/utils/other'
import CardInfoSimpleNutritionalProperty
  from '@/components/meal-plan/_widgets/card-info-simple-nutritional-property.vue'
import { parseFrom } from '@/components/meal-plan/_widgets/card-info-simple-nutritional-property.utils'

export default {
  components: {
    CardInfoSimpleNutritionalProperty,
    CFlex,
    ModalSimpleInfo,
    FormInput,
    FormTime,
    TabsMealPlan,
    BaseButton,
    MealPlanSteps,
    AccordionMealPlan,
    Info,
    Observer,
  },
  props: {
    draft: {
      type: [Object, null],
      default: null,
    },
    preview: {
      type: [Object, null],
      default: null,
    },
    subTabs: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['update:validation', 'update:skip-meal-time'],
  data() {
    return {
      // isOpenModalBackToPreview: false,
      isOpenModalSuccessSaveData: false,
      isOpenModalFailedSaveData: false,
      isLoadingSave: false,
      form: {
        breakfast: {
          isSkipped: false,
          startTime: '',
          endTime: '',
          caloriesNeedPercent: null,
          caloriesAmount: null,
          remainingCaloriesTotal: null,
          mealComposition: [],
          validation: 0,
        },
        morningSnack: {
          isSkipped: false,
          startTime: '',
          endTime: '',
          caloriesNeedPercent: null,
          caloriesAmount: null,
          remainingCaloriesTotal: null,
          mealComposition: [],
          validation: 0,
        },
        lunch: {
          isSkipped: false,
          startTime: '',
          endTime: '',
          caloriesNeedPercent: null,
          caloriesAmount: null,
          remainingCaloriesTotal: null,
          mealComposition: [],
          validation: 0,
        },
        afternoonSnack: {
          isSkipped: false,
          startTime: '',
          endTime: '',
          caloriesNeedPercent: null,
          caloriesAmount: null,
          remainingCaloriesTotal: null,
          mealComposition: [],
          validation: 0,
        },
        dinner: {
          isSkipped: false,
          startTime: '',
          endTime: '',
          caloriesNeedPercent: null,
          caloriesAmount: null,
          remainingCaloriesTotal: null,
          mealComposition: [],
          validation: 0,
        },
        nightSnack: {
          isSkipped: false,
          startTime: '',
          endTime: '',
          caloriesNeedPercent: null,
          caloriesAmount: null,
          remainingCaloriesTotal: null,
          mealComposition: [],
          validation: 0,
        },
      },
      formDuplicate: null,
      isLoadingData: false,
      boxShadowSimpleNutrionalInfo: null,
    }
  },
  computed: {
    ...mapGetters({
      defaultItems: 'mealPlan/defaultItems',
      saveState: 'general/saveState',
    }),
    getIsValidTotalMacroNutrient() {
      // START - THIS CODE MUST SAME WITH BE
      const {
        fatRequirement,
        proteinRequirement,
        carbohydrateRequirement,
        fiberNeedsForAgeCategory,
      } = this.draft?.nutritionalIntake || {}

      const calculateNeeded = (requirement) =>
        parseFloat(
          Number(
            (mealPlansConstant.thresholdConstants.THRESHOLD / 100) *
                  requirement,
          ) || 0,
        ).toFixed(2)

      const fatNeeded = parseFloat(calculateNeeded(fatRequirement))
      const proteinNeeded = parseFloat(calculateNeeded(proteinRequirement))
      const carbohydrateNeeded = parseFloat(calculateNeeded(carbohydrateRequirement))
      const fiberNeeded = parseFloat(calculateNeeded(fiberNeedsForAgeCategory))

      const calculateNutrient = (nutrient) =>
        parseFloat(
          Object.values(this.form || {})
            ?.filter((e) => e.isSkipped === false)
            .reduce((total, e) => {
              const mealTotal = e?.mealComposition
                ?.filter((e) => e.isActive === true)
                ?.reduce(
                  (sum, current) => sum + parseFloat(current?.[nutrient] || 0),
                  0,
                )
              return total + mealTotal
            }, 0) || 0,
        ).toFixed(2)

      // Calculate total nutrients
      const fat = parseFloat(calculateNutrient('fat'))
      const protein = parseFloat(calculateNutrient('protein'))
      const carbohydrate = parseFloat(calculateNutrient('carbohydrate'))
      const fiber = parseFloat(calculateNutrient('fiber'))

      return fat > fatNeeded ||
      protein > proteinNeeded ||
      carbohydrate > carbohydrateNeeded ||
      fiber > fiberNeeded
        ? -1
        : 1

      // END - THIS CODE MUST SAME WITH BE
    },
    getSimpleNutritionalInfo() {
      return parseFrom({ ...this.preview, mealPlanSchedules: Object.values(this.form || {}) }, this.defaultItems || [], 'form')
    },
    constants() {
      return {
        steps: mealPlanSteps,
        tabsMealPlan,
      }
    },
    activeStepIndex() {
      if (!this.step) return -1
      return this.constants.steps.findIndex((it) => it.value === this.step)
    },
    isValidAllMealPlanForm() {
      if (!this.draft?.mealPlanSchedules) {
        return false
      }
      if (!this.draft?.mealRecommendations) {
        return false
      }
      return !this.$v.$invalid // this.draft?.mealPlanSchedules?.every((v) => v.validation === 1)
          && this.draft?.mealRecommendations?.validation === 1
    },
    form2() {
      return this.form
    },
    customMessageErrors() {
      return {
        required: '{{fieldName}} wajib diisi',
        minValue: '{{fieldName}} tidak boleh kurang dari {{minValue.min}}%',
        maxValue: '{{fieldName}} tidak boleh lebih dari {{maxValue.max}}%',
      }
    },
    step() {
      return this.$route.query?.mealTime || 'breakfast'
    },
    isProfilGiziValid() {
      const isAllValid = this.isAllFormValid({
        nutritionalConditions: this.draft?.nutritionalConditions && this.draft?.nutritionalConditions?.validation === 1,
        nutritionalIntake: this.draft?.nutritionalIntake && this.draft?.nutritionalIntake?.validation === 1,
        bodyConditions: this.draft?.bodyConditions && this.draft?.bodyConditions?.validation === 1,
        targetAndSuggestions: this.draft?.targetAndSuggestions && this.draft?.targetAndSuggestions?.validation === 1,
      })

      return isAllValid
    },
    isFormInvalid() {
      switch(this.step) {
        case 'breakfast':
          return this.$v.form.breakfast.$invalid
          
        case 'morningSnack':
          return this.$v.form.morningSnack.$invalid
          
        case 'lunch':
          return this.$v.form.lunch.$invalid
          
        case 'afternoonSnack':
          return this.$v.form.afternoonSnack.$invalid
          
        case 'dinner':
          return this.$v.form.dinner.$invalid
          
        case 'nightSnack':
          return this.$v.form.nightSnack.$invalid

        default:
          return this.$v.form
      }
    },
    totalEnergyNeeded() {
      return this.preview?.nutritionalConditions?.totalEnergyNeeded || 0
    },
    remainingPercentTotalEnergyNeeded() {
      const threshold = 110 // 100% + 10% threshold for total calories
      return threshold - Number(this.form.breakfast.caloriesNeedPercent) - Number(this.form.morningSnack.caloriesNeedPercent) - Number(this.form.lunch.caloriesNeedPercent) - Number(this.form.afternoonSnack.caloriesNeedPercent) - Number(this.form.dinner.caloriesNeedPercent) - Number(this.form.nightSnack.caloriesNeedPercent)
    },
    remainingTotalEnergyNeeded() {
      return this.totalEnergyNeeded - Number(this.form.breakfast.caloriesAmount) - Number(this.form.morningSnack.caloriesAmount) - Number(this.form.lunch.caloriesAmount) - Number(this.form.afternoonSnack.caloriesAmount) - Number(this.form.dinner.caloriesAmount) - Number(this.form.nightSnack.caloriesAmount)
    },
    isValidCaloriesNeedPercent() {
      if (this.remainingPercentTotalEnergyNeeded >= 0) {
        return true
      }
      return false
    },
    hasChangeForm() {
      return !_.isEqual(this.form, this.formDuplicate)
    },
    iconCloud() {
      switch (this.saveState) {
        case true:
          return {
            icon: require('@/assets/icons/icon-cloud-done.svg'),
            text: 'Semua perubahan telah disimpan',
          }
        case false:
          return {
            icon: require('@/assets/icons/icon-cloud-off.svg'),
            text: 'Koneksi terputus, perubahan tidak tersimpan',
          }
        default:
          return {
            icon: require('@/assets/icons/icon-sync.svg'),
            text: 'Loading...',
          }
      }
    },
    totalMealCompositionCalories() {
      let calory = 0
      this.form[this.step].mealComposition.forEach((it) => {
        if (it?.calories && it?.isActive) {
          calory += Number(it?.calories)
        }
      })
      return calory
    },
    remainingTotalMealCompositionCalories() {
      if (this.form[this.step].caloriesAmount && this.totalMealCompositionCalories) {
        return Number(this.form[this.step].caloriesAmount) - this.totalMealCompositionCalories
      }
      return null
    },
  },
  watch: {
    form: {
      async handler() {
        const data = {
          form: 'meal-plan',
          mealTime: this.step,
          validation: this.form[this.step].isSkipped 
            ? 1 
            : (this.isFormInvalid || this.remainingTotalMealCompositionCalories === null || this.remainingTotalMealCompositionCalories < 0 || this.getIsValidTotalMacroNutrient === -1)
              ? -1 
              : 1,
        }
        this.$emit('update:validation', data)

        if (!this.isLoadingData) {
          this.setSaveState(null)
        }
      },
      deep: true,
    },
    form2: {
      handler: _.debounce(async function() {
        this.onCheckSaveDraft()
      }, 1000),
      deep: true,
    },
    'form.breakfast.caloriesNeedPercent': {
      handler: _.debounce(function(val) {
        const caloriesAmount = this.getPercentOfTotal(Number(val), Number(this.totalEnergyNeeded))
        if (caloriesAmount) {
          this.form.breakfast.caloriesAmount = caloriesAmount
        }
      }, 500),
    },
    'form.morningSnack.caloriesNeedPercent': {
      handler: _.debounce(function(val) {
        const caloriesAmount = this.getPercentOfTotal(Number(val), Number(this.totalEnergyNeeded))
        if (caloriesAmount) {
          this.form.morningSnack.caloriesAmount = caloriesAmount
        }
      }, 500),
    },
    'form.lunch.caloriesNeedPercent': {
      handler: _.debounce(function(val) {
        const caloriesAmount = this.getPercentOfTotal(Number(val), Number(this.totalEnergyNeeded))
        if (caloriesAmount) {
          this.form.lunch.caloriesAmount = caloriesAmount
        }
      }, 500),
    },
    'form.afternoonSnack.caloriesNeedPercent': {
      handler: _.debounce(function(val) {
        const caloriesAmount = this.getPercentOfTotal(Number(val), Number(this.totalEnergyNeeded))
        if (caloriesAmount) {
          this.form.afternoonSnack.caloriesAmount = caloriesAmount
        }
      }, 500),
    },
    'form.dinner.caloriesNeedPercent': {
      handler: _.debounce(function(val) {
        const caloriesAmount = this.getPercentOfTotal(Number(val), Number(this.totalEnergyNeeded))
        if (caloriesAmount) {
          this.form.dinner.caloriesAmount = caloriesAmount
        }
      }, 500),
    },
    'form.nightSnack.caloriesNeedPercent': {
      handler: _.debounce(function(val) {
        const caloriesAmount = this.getPercentOfTotal(Number(val), Number(this.totalEnergyNeeded))
        if (caloriesAmount) {
          this.form.nightSnack.caloriesAmount = caloriesAmount
        }
      }, 500),
    },
  },
  async mounted() {
    this.isLoadingData = true
    if (!!this.preview?.mealPlanSchedules && this.preview?.mealPlanSchedules.length > 0) {
      this.form.breakfast = this.preview?.mealPlanSchedules.find((it) => it.mealTime === 'breakfast')
      this.form.morningSnack = this.preview?.mealPlanSchedules.find((it) => it.mealTime === 'morningSnack')
      this.form.lunch = this.preview?.mealPlanSchedules.find((it) => it.mealTime === 'lunch')
      this.form.afternoonSnack = this.preview?.mealPlanSchedules.find((it) => it.mealTime === 'afternoonSnack')
      this.form.dinner = this.preview?.mealPlanSchedules.find((it) => it.mealTime === 'dinner')
      this.form.nightSnack = this.preview?.mealPlanSchedules.find((it) => it.mealTime === 'nightSnack')
      this.$v.$touch()
    }

    if (this.defaultItems.length === 0) {
      await this.getDefaultItems()
    }

    this.form = Object.entries(this.form || {}).reduce((acc, [key, value]) => {
      const mealComposition = this.defaultItems.map((it) => {
        const find = value.mealComposition.find((el) => el.foodGroup === it.foodGroup)
        const resultCalc = this.calculationNutritionalPropertiesByPortion({ ...find, raw: {
          calory: it.calory,
          carbohydrate: it.carbohydrate,
          fat: it.fat,
          fiber: it.fiber,
          protein: it.protein,
        } })
        const isGroupMakananPokok = it?.foodGroup.includes('Makanan Pokok')
        const isFormStillEmpty = (!value.startTime && !value.endTime && !value.caloriesNeedPercent && value.mealComposition.length == 0)

        return {
          foodGroup: find?.foodGroup ?? it?.foodGroup,
          foodGroupMealGlossaries: find?.foodGroupMealGlossaries ?? it?.foodGroupMealGlossaries,
          portion: find?.portion ?? '',
          portionDecimal: find?.portionDecimal ?? 0,
          carbohydrate: resultCalc.carbohydrate ?? null,
          fat: find?.fat ?? null,
          fiber: find?.fiber ?? null,
          calories: find?.calories ?? null,
          protein: find?.protein ?? null,
          calory: find?.calory ?? null,
          mealItems: (isGroupMakananPokok && isFormStillEmpty) ? it?.item : (find?.mealItems ?? []), // if form is empty, checklist all default items on group makanan pokok
          additionalMealItems: find?.additionalMealItems ?? [],
          defaultItems: it?.item,
          isActive: find ? true : (value.isSkipped ? false : (isGroupMakananPokok && isFormStillEmpty ? true : false)),
          // raw data from API
          raw: {
            carbohydrate: it?.carbohydrate,
            fat: it?.fat,
            fiber: it?.fiber,
            calory: it?.calory,
            protein: it?.protein,
          },
        }
      })

      acc[key] = { ...value, mealComposition }
      return acc
    }, {})

    setTimeout(() => {
      this.isLoadingData = false
      this.setSaveState(true)
    }, 1000)
  },
  methods: {
    parseFloatToFixed2,
    isAllFormValid,
    ...mapMutations({
      setSaveState: 'general/setSaveState',
      setEditValidation: 'mealPlan/setEditValidation',
    }),
    onChange(state) {
      if (state.isIntersecting) {
        this.boxShadowSimpleNutrionalInfo = null
      } else {
        this.boxShadowSimpleNutrionalInfo = '0px 9px 6px -6px rgba(0, 0, 0, 0.25), 10px 0px 0px 0px white, -10px 0px 0px 0px white'
      }
    },
    calculationNutritionalPropertiesByPortion(data) {
      const calc = (dataNutritional_) => {
        let result = data.portionDecimal ? (parseFloat(data.portionDecimal) * parseFloat(dataNutritional_)) : null
        if (result && !Number.isInteger(result)) result = result.toFixed(2)
        return result
      }
      return {
        carbohydrate: calc(data?.raw?.carbohydrate),
        fat: calc(data?.raw?.fat),
        fiber: calc(data?.raw?.fiber),
        calories: calc(data?.raw?.calory),
        protein: calc(data?.raw?.protein),
      }
    },
    isInvalidField,
    async handlePrevious() {
      if (!this.isLoadingSave) {
        await this.onCheckSaveDraft()
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.constants.tabsMealPlan['meal-plan'],
            mealTime: this.constants.steps[this.activeStepIndex - 1]?.value,
          },
        })
      }
    },
    async handleNext() {
      if (!this.isLoadingSave) {
        await this.onCheckSaveDraft()
        this.$router.push({
          name: this.$route.name,
          params: this.$route.params,
          query: {
            tab: this.constants.tabsMealPlan['meal-plan'],
            mealTime: this.constants.steps[this.activeStepIndex + 1]?.value,
          },
        })
      }
    },
    parseErrors,
    ...mapMutations({
      setDefaultItems: 'mealPlan/setDefaultItems',
    }),
    onDuplicateForm() {
      this.formDuplicate = _.cloneDeep(this.form)
    },
    async onToggleSkip(value) {
      if (this.isProfilGiziValid) {
        const isSkipped = !this.form[value]?.isSkipped
        this.form[value].isSkipped = isSkipped
  
        if (isSkipped) {
          this.form[value].startTime = ''
          this.form[value].endTime = ''
          this.form[value].caloriesNeedPercent = null
          this.form[value].caloriesAmount = null
          this.form[value].mealComposition = this.form[value].mealComposition.map((it) => {
            return {
              ...it,
              isActive: false,
            }
          })
        }

        const payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          isSkip: isSkipped ? 1 : -1,
          mealTime: value,
        }
        await reqNutri_mealPlans_menuRecommendation_isSkip(this.$store.getters.axios, payload)
        this.$emit('update:skip-meal-time')
      }
    },
    getPercentOfTotal(percent, total) {
      return parseFloat((percent / 100) * total).toFixed(2)
    },
    async getDefaultItems() {
      try {
        const res = await reqNutriMealPlan.getDefaultItem(this.$store.getters.axios)
        await this.setDefaultItems(res.data?.data)
      } catch (err) {
        //
      }
    },
    onChangeMenus(menus) {
      this.form[this.step].mealComposition = [...menus]
    },
    onCheckSaveDraft() {
      if (!this.saveState && !this.isLoadingData && this.isProfilGiziValid) {
        this.onSaveDraft()
      }
    },
    async onSaveDraft(confirm = null) {
      try {
        const activeStep = this.step
        this.isLoadingSave = true
        this.setSaveState(null)

        let mealPlanSchedule = Object.keys(this.form).map((key) => {

          let mealComposition = this.form[key].isSkipped ? [] : this.form[key].mealComposition.filter((it) => { return it?.isActive }).map((it) => _.pick(it, ['additionalMealItems', 'calories', 'carbohydrate', 'fat', 'fiber', 'protein', 'foodGroup', 'foodGroupMealGlossaries', 'mealItems', 'portion', 'portionDecimal']))
          let validation = this.form[key].isSkipped ? 1 : this.form[key].validation

          if (key === activeStep) {
            validation = this.form[key].isSkipped
              ? 1
              : (this.isFormInvalid || this.remainingTotalMealCompositionCalories === null || this.remainingTotalMealCompositionCalories < 0 || this.getIsValidTotalMacroNutrient === -1)
                ? -1
                : 1
          }

          return {
            ...this.form[key],
            mealTime: key,
            mealComposition,
            validation,
          }
        })

        let payload = {
          clientId: this.$route.params.clientId,
          programId: this.$route.params.programId,
          month: this.$route.params.month,
          mealPlanSchedules: mealPlanSchedule,
        }
        await reqNutriMealPlan.updateMealPlanDraft(this.$store.getters.axios, { payload })
        this.$v.$touch()
        
        this.onDuplicateForm()
        this.setSaveState(true)
        if (confirm) {
          this.isOpenModalSuccessSaveData = true
        }
        const isInvalidValidation = mealPlanSchedule.some((it) => it?.validation !== 1)
        this.setEditValidation({
          routeName: this.$route.name,
          validation: isInvalidValidation ? -1 : 1,
        })
      } catch (err) {
        this.setSaveState(false)
        this.isOpenModalFailedSaveData = true
      } finally {
        this.isLoadingSave = false
      }
    },
  },
  validations() {
    return {
      form: {
        breakfast: {
          startTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          endTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          caloriesNeedPercent: {
            required: requiredIf((model) => !model.isSkipped),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          mealComposition: {
            required: requiredIf((model) => !model.isSkipped),
            $each: {
              portion: {
                required: requiredIf((model) => model.isActive),
              },
            },
          },
        },
        morningSnack: {
          startTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          endTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          caloriesNeedPercent: {
            required: requiredIf((model) => !model.isSkipped),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          mealComposition: {
            required: requiredIf((model) => !model.isSkipped),
            $each: {
              portion: {
                required: requiredIf((model) => model.isActive),
              },
            },
          },
        },
        lunch: {
          startTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          endTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          caloriesNeedPercent: {
            required: requiredIf((model) => !model.isSkipped),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          mealComposition: {
            required: requiredIf((model) => !model.isSkipped),
            $each: {
              portion: {
                required: requiredIf((model) => model.isActive),
              },
            },
          },
        },
        afternoonSnack: {
          startTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          endTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          caloriesNeedPercent: {
            required: requiredIf((model) => !model.isSkipped),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          mealComposition: {
            required: requiredIf((model) => !model.isSkipped),
            $each: {
              portion: {
                required: requiredIf((model) => model.isActive),
              },
            },
          },
        },
        dinner: {
          startTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          endTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          caloriesNeedPercent: {
            required: requiredIf((model) => !model.isSkipped),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          mealComposition: {
            required: requiredIf((model) => !model.isSkipped),
            $each: {
              portion: {
                required: requiredIf((model) => model.isActive),
              },
            },
          },
        },
        nightSnack: {
          startTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          endTime: {
            required: requiredIf((model) => !model.isSkipped),
          },
          caloriesNeedPercent: {
            required: requiredIf((model) => !model.isSkipped),
            minValue: minValue(1),
            maxValue: maxValue(100),
          },
          mealComposition: {
            required: requiredIf((model) => !model.isSkipped),
            $each: {
              portion: {
                required: requiredIf((model) => model.isActive),
              },
            },
          },
        },
      },
    }
  },
}
</script>

<style>

</style>