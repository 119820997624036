var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', [_c('Tabs', {
    attrs: {
      "active-tab": "recommendation",
      "is-program-consultation-basic": _vm.isProgramConsultationBasic,
      "tabs": _vm.tabs
    }
  }, [_vm.isProgramConsultationBasic ? _c('c-box', {
    attrs: {
      "margin-bottom": "16px"
    }
  }, [_c('Info', {
    attrs: {
      "variant": "warning",
      "text": "Formulir tidak dapat diubah dan ditambah karena klien menjalankan program Konsultasi Basic",
      "padding": "16px 28px",
      "font-size": "14px"
    }
  })], 1) : _vm._e(), _c('c-flex', {
    attrs: {
      "align-items": "center",
      "justify-content": "space-between",
      "margin-bottom": "12px"
    }
  }, [_c('c-flex', {
    attrs: {
      "align-items": "center",
      "gap": "16px"
    }
  }, [_c('c-button', {
    attrs: {
      "variant": "ghost",
      "width": "30px",
      "min-width": "30px",
      "height": "30px",
      "margin": "0",
      "padding": "0"
    },
    on: {
      "click": function click($event) {
        return _vm.$router.push(Object.assign({}, _vm.$route, {
          name: _vm.isRoleNutritionist ? 'nutri.meal-plan.meal-plan-schedule.detail' : 'admin.clients.meal-plan.meal-plan-schedule'
        }));
      }
    }
  }, [_c('c-box', {
    attrs: {
      "width": ['12px', '15px'],
      "height": ['12px', '15px']
    }
  }, [_c('inline-svg', {
    staticStyle: {
      "transform": "rotate(90deg)"
    },
    attrs: {
      "src": require('@/assets/icons/icon-arrow-down.svg'),
      "height": "100%",
      "width": "100%",
      "fill": "#008C81"
    }
  })], 1)], 1), _c('c-text', {
    attrs: {
      "color": "neutral.superDarkGray",
      "font-size": "28px",
      "font-weight": "700"
    }
  }, [_vm._v(" Rekomendasi Menu ")])], 1), _vm.isRoleNutritionist ? _c('BaseButton', {
    attrs: {
      "left-svg-icon": _vm.preview ? require('@/assets/icons/icon-edit-2.svg') : require('@/assets/icons/icon-plus.svg'),
      "left-svg-icon-color": "white",
      "is-disabled": _vm.isLoadingData || !_vm.isEdited || _vm.isProgramConsultationBasic
    },
    on: {
      "click": function click($event) {
        var _vm$$route$query, _vm$$route$query2;

        _vm.isEdited && !_vm.isProgramConsultationBasic ? _vm.$router.push(Object.assign({}, _vm.$route, {
          name: 'nutri.meal-plan.menu-recommendation.edit',
          query: {
            day: (_vm$$route$query = _vm.$route.query) !== null && _vm$$route$query !== void 0 && _vm$$route$query.day ? Number((_vm$$route$query2 = _vm.$route.query) === null || _vm$$route$query2 === void 0 ? void 0 : _vm$$route$query2.day) % 10 : 1
          }
        })) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.getHasPreviewData ? 'Edit' : 'Tambah') + " ")]) : _vm._e()], 1), _c('PreviewMenuRecommendation', {
    attrs: {
      "calendars": _vm.calendars,
      "data": _vm.preview,
      "is-loading": _vm.isLoadingData
    }
  })], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }