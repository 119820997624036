<template>
  <CBox
    font-family="Roboto"
    color="#111"
    w="100%"
    :px="['0px', '120px']"
    :py="['16px', '30px']"
    :bg="['#F2F2F2', 'white']"
    :h="['100%', '100%']"
    :border="['none', '1px solid #f2f2f2']"
    :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
    :border-radius="['none', '16px']"
    display="flex"
    flex-direction="column"
  >
    <Portal to="breadcrumb">
      <BreadcrumbPath
        px="90px"
        py="20px"
        :paths="[
          {
            label: 'Dashboard',
            href: { name: 'client.index' }
          },
          {
            label: 'Akun',
            href: { name: 'client.profile' }
          },
          {
            label: 'Riwayat Transaksi',
            href: { name: 'client.profile.historyTransaction' }
          },
          {
            label: 'Detail Riwayat Transaksi',
            isCurrent: true,
          },
        ]"
      />
    </Portal>
    <CHeading
      as="h1"
      font-weight="700"
      font-size="28px"
      :d="['none', 'block']"
      :mx="['16px', '0px']"
      font-family="Roboto"
    >
      Detail Riwayat Transaksi
    </CHeading>
    <!-- 1 -->
    <CBox
      v-if="!!fetchDetailHistoryTransactionsQuery.data.value?.name"
      :px="['16px', '16px']"
      :mt="['0px','16px']"
      :mb="['16px', '0px']"
    >
      <CBox
        v-chakra="{
          ':not(:last-child)': {
            marginBottom: '30px',
          }
        }"
        :p="['16px', '32px']"
        rounded="8px"
        box-shadow="0px 5px 15px rgb(0 0 0 / 20%)"
        bg="white"
        display="flex"
        gap="16px"
        cursor="pointer"
      >
        <CBox
          width="100%"
        >
          <CBox
            as="table"
            :font-size="['12px', '16px']"
            font-weight="400"
            style=" border-collapse: separate; border-spacing: 0px 8px;"
            font-family="Roboto"
            width="100%"
          >
            <CBox as="tr">
              <CBox
                as="th"
                display="inline-block"
                margin-right="16px"
                font-weight="400"
              >
                Status
              </CBox>
              <CBox as="td">
                <LabelTransactionStatus :status="fetchDetailHistoryTransactionsQuery.data.value.status" />
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                display="inline-block"
                margin-right="16px"
                font-weight="400"
                text-align="start"
              >
                Tanggal Transaksi
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ formatDateV2(fetchDetailHistoryTransactionsQuery.data.value.date) }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                display="inline-block"
                margin-right="16px"
                font-weight="400"
              >
                Invoice
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ fetchDetailHistoryTransactionsQuery.data.value.invoice }}
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CBox>
    </CBox>

    <!-- 2 -->
    <CBox
      v-if="!!fetchDetailHistoryTransactionsQuery.data.value?.name"
      :px="['16px', '16px']"
      :mt="['0px','16px']"
      :mb="['16px', '0px']"
    >
      <CBox
        v-chakra="{
          ':not(:last-child)': {
            marginBottom: '30px',
          }
        }"
        :p="['16px', '32px']"
        rounded="8px"
        box-shadow="0px 5px 15px rgb(0 0 0 / 20%)"
        bg="white"
        display="flex"
        gap="16px"
        cursor="pointer"
      >
        <CImage
          object-fit="cover"
          h="230.35px"
          w="180px"
          :src="fetchDetailHistoryTransactionsQuery.data.value.image"
          alt="Image"
          rounded="6px"
          :display="['none', 'block']"
        />
        <CBox
          width="100%"
          display="flex"
          flex-direction="column"
          justify-content="center"
        >
          <CBox
            d="flex"
            align-items="center"
            justify-content="space-between"
            gap="8px"
          >
            <CBox
              display="flex"
              gap="8px"
              align-items="center"
            >
              <CImage
                object-fit="cover"
                h="50px"
                w="50px"
                :src="fetchDetailHistoryTransactionsQuery.data.value.image"
                alt="Image"
                rounded="6px"
                :display="['block', 'none']"
              />
              <CText
                :font-size="['16px', '28px']"
                :font-weight="['600', '700']"
                d="flex"
                align-items="center"
                gap="8px"
                flex-wrap="wrap"
                font-family="Roboto"
              >
                {{ fetchDetailHistoryTransactionsQuery.data.value?.productName || '-' }}
                <CText
                  :font-size="['16px', '20px']"
                  :font-weight="['600', '700']"
                >
                  ({{ fetchDetailHistoryTransactionsQuery.data.value?.name || '-' }})
                </CText>
              </CText>
            </CBox>
            <LabelProgramStatus :status="statusProgram" />
          </CBox>
          <CDivider border-color="lightGray" />
          <CBox
            as="table"
            :font-size="['12px', '16px']"
            font-weight="400"
            style=" border-collapse: separate; border-spacing: 0px 8px;"
            font-family="Roboto"
            width="100%"
          >
            <CBox
              as="tr"
              :display="['flex', 'table-row']"
              justify-content="space-between"
            >
              <CBox
                as="th"
                style="display: inline-block; margin-right: 16px;"
                font-weight="400"
              >
                Durasi
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ fetchDetailHistoryTransactionsQuery.data.value.duration }} hari
              </CBox>
            </CBox>
            <CBox
              as="tr"
              :display="['flex', 'table-row']"
              justify-content="space-between"
            >
              <CBox
                as="th"
                style="display: inline-block; margin-right: 16px;"
                font-weight="400"
              >
                Harga
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ formatCurrency(fetchDetailHistoryTransactionsQuery.data.value.price) }}
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CBox>
    </CBox>

    <!-- Detail Pembayaran -->
    <CBox
      v-if="!!fetchDetailHistoryTransactionsQuery.data.value?.name"
      :px="['16px', '16px']"
      :mt="['0px','16px']"
      :mb="['16px', '0px']"
    >
      <CBox
        v-chakra="{
          ':not(:last-child)': {
            marginBottom: '30px',
          }
        }"
        :p="['16px', '32px']"
        rounded="8px"
        box-shadow="0px 5px 15px rgb(0 0 0 / 20%)"
        bg="white"
        display="flex"
        gap="16px"
        cursor="pointer"
      >
        <CBox
          width="100%"
        >
          <CBox
            d="flex"
            align-items="center"
            justify-content="center"
            gap="8px"
          >
            <CText
              :font-size="['14px', '28px']"
              :font-weight="['400', '700']"
              d="flex"
              align-items="center"
              gap="8px"
              flex-wrap="wrap"
              font-family="Roboto"
            >
              Detail Pembayaran
            </CText>
          </CBox>
          <CDivider
            border-color="lightGray"
            :display="['none', 'block']"
          />
          <CBox
            as="table"
            :font-size="['12px', '16px']"
            font-weight="400"
            style=" border-collapse: separate; border-spacing: 0px 8px;"
            font-family="Roboto"
            width="100%"
          >
            <CBox as="tr">
              <CBox
                as="th"
                font-weight="400"
                display="inline-block"
                margin-right="16px"
              >
                Metode Pembayaran
              </CBox>
              <CBox
                as="td"
                color="#333333"
              >
                {{ fetchDetailHistoryTransactionsQuery.data.value.paymentMethod }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                font-weight="400"
                display="inline-block"
                margin-right="16px"
              >
                Total Harga
              </CBox>
              <CBox
                as="td"
                color="#555"
              >
                {{ formatCurrency(fetchDetailHistoryTransactionsQuery.data.value.price) }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                font-weight="400"
                display="inline-block"
                margin-right="16px"
              >
                Diskon
                <c-box
                  v-if="fetchDetailHistoryTransactionsQuery.data?.value?.coupon"
                  as="span"
                  color="primary.400"
                >
                  ({{ fetchDetailHistoryTransactionsQuery.data?.value?.coupon }})
                </c-box>
              </CBox>
              <CBox
                as="td"
                color="#555"
              >
                {{ fetchDetailHistoryTransactionsQuery.data?.value?.totalDiscount > 0 ? '-': '' }} {{ formatCurrency(fetchDetailHistoryTransactionsQuery.data?.value?.totalDiscount || 0) }}
              </CBox>
            </CBox>
            <CBox as="tr">
              <CBox
                as="th"
                font-weight="400"
                display="inline-block"
                margin-right="16px"
              >
                Total Pembayaran
              </CBox>
              <CBox
                as="td"
                color="#555"
              >
                {{ formatCurrency(fetchDetailHistoryTransactionsQuery.data.value.totalPayment) }}
              </CBox>
            </CBox>
          </CBox>
        </CBox>
      </CBox>
      <CBox
        pt="32px"
        mt="auto"
        px="16px"
      >
        <FloatingButton
          :transaction-id="route.params.transactionId"
          :status="fetchDetailHistoryTransactionsQuery.data.value.status"
          :invoice="fetchDetailHistoryTransactionsQuery.data.value.invoice"
          :product-service-id="fetchDetailHistoryTransactionsQuery.data.value.productServiceId"
          :quantity="fetchDetailHistoryTransactionsQuery.data.value.quantity"
          :flag-route="fetchDetailHistoryTransactionsQuery.data.value.flagRoute"
        />
      </CBox>
    </CBox>
    <EmptyCard
      v-else
      label="Riwayat Transaksi tidak tersedia"
    />
  </CBox>
</template>

<script >
import { useQuery } from '@tanstack/vue-query';
import { CImage, CDivider, CBox, CText, CHeading } from '@chakra-ui/vue';
import LabelProgramStatus from '@/views/profile/history-transaction/_widgets/label-program-status.vue';
import { useActions } from '@/stores';
import LabelTransactionStatus from '@/views/profile/history-transaction/_widgets/label-transaction-status.vue';
import { formatDateV2 } from '@/utils/format-date';
import { formatCurrency } from '@/utils/format-currency';
import { MILISECONDS_FOR } from '@/constants/miliseconds';
import EmptyCard from '@/views/profile/empty.vue';
import { useRoute } from 'vue2-helpers/vue-router';
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue';
import { Portal } from 'portal-vue';
import FloatingButton from '@/views/profile/history-transaction/[id]/_widgets/floating-button.vue';
import { computed } from '@vue/composition-api';
const __sfc_main = {};

__sfc_main.setup = (__props, __ctx) => {
  const route = useRoute();
  const action = useActions('clients', ['fetchDetailHistoryTransaction']);
  const fetchDetailHistoryTransactionsQuery = useQuery({
    queryKey: ['useFetchDetailHistoryTransactions', route.params.transactionId],
    queryFn: () => action.fetchDetailHistoryTransaction(route.params.transactionId),
    staleTime: MILISECONDS_FOR.ONE_MINUTE * 5
  });
  const historyTransaction = computed(() => fetchDetailHistoryTransactionsQuery.data.value);
  const statusProgram = computed(() => {
    const statusTransaction = historyTransaction.value.status;
    const statusProgram_ = historyTransaction.value.program;

    if (statusTransaction.toLowerCase() === 'pending') {
      return 'pending';
    }

    return statusProgram_;
  });
  return {
    formatDateV2,
    formatCurrency,
    route,
    fetchDetailHistoryTransactionsQuery,
    statusProgram
  };
};

__sfc_main.components = Object.assign({
  CBox,
  Portal,
  BreadcrumbPath,
  CHeading,
  LabelTransactionStatus,
  CImage,
  CText,
  LabelProgramStatus,
  CDivider,
  FloatingButton,
  EmptyCard
}, __sfc_main.components);
export default __sfc_main;
</script>
