import { render, staticRenderFns } from "./form-input-2.vue?vue&type=template&id=7df858e4&scoped=true&"
import script from "./form-input-2.vue?vue&type=script&lang=js&"
export * from "./form-input-2.vue?vue&type=script&lang=js&"
import style0 from "./form-input-2.vue?vue&type=style&index=0&id=7df858e4&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7df858e4",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CBox: require('@chakra-ui/vue').CBox, CFormLabel: require('@chakra-ui/vue').CFormLabel, CTextarea: require('@chakra-ui/vue').CTextarea, CFlex: require('@chakra-ui/vue').CFlex, CInputLeftElement: require('@chakra-ui/vue').CInputLeftElement, CInput: require('@chakra-ui/vue').CInput, CText: require('@chakra-ui/vue').CText, CInputRightElement: require('@chakra-ui/vue').CInputRightElement, CInputGroup: require('@chakra-ui/vue').CInputGroup, CFormHelperText: require('@chakra-ui/vue').CFormHelperText, CFormControl: require('@chakra-ui/vue').CFormControl})
