var render = function () {
  var _vm$programFillQuesti, _vm$nutritionistModal;

  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('c-box', {
    attrs: {
      "w": "100%",
      "bg": ['#F2F2F2', 'white'],
      "h": "100%",
      "pb": !_vm.hasProgramActive ? '40px' : '0px'
    }
  }, [_c('Portal', {
    attrs: {
      "to": "breadcrumb"
    }
  }, [_c('BreadcrumbPath', {
    attrs: {
      "px": "90px",
      "py": "20px",
      "paths": [{
        label: 'Dashboard',
        href: '/'
      }, {
        label: 'Pilih Ahli Gizi',
        isCurrent: true
      }]
    }
  })], 1), _c('c-box', {
    attrs: {
      "px": ['17.5px', '85px']
    }
  }, [_c('c-box', {
    attrs: {
      "box-shadow": ['none', '2px 2px 10px rgba(0, 0, 0, 0.15)'],
      "border-radius": ['none', '12px'],
      "w": "100%",
      "px": ['0', '85px'],
      "pb": "30px"
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "max-width": ['inherit', '1200px'],
      "mx": "auto",
      "pt": ['20px', '0'],
      "display": ['flex', 'block'],
      "border-bottom": ['1px solid #F2F2F2', 'none'],
      "padding-bottom": ['15px', '0'],
      "px": ['0', '20px']
    }
  }, [_c('c-box', {
    attrs: {
      "justify-content": "center",
      "display": ['none', 'flex']
    }
  }, [_c('c-heading', {
    attrs: {
      "display": ['none', 'block'],
      "font-size": "20px",
      "font-weight": "700",
      "color": "black.900",
      "mt": "30px"
    }
  }, [_vm._v(" List Ahli Gizi ")])], 1), _c('c-box', {
    attrs: {
      "w": ['100%', 'auto'],
      "margin-top": ['0', '30px'],
      "margin-bottom": ['0', '30px'],
      "d": "flex",
      "justify-content": "center",
      "align-items": "center",
      "gap": "8px"
    }
  }, [_c('c-box', {
    attrs: {
      "pos": "relative",
      "w": "100%"
    }
  }, [_c('c-image', {
    attrs: {
      "src": require('@/assets/ic-search.svg'),
      "h": ['14.58px', '21.86px'],
      "w": ['14.58px', '21.86px'],
      "alt": "icon",
      "pos": "absolute",
      "top": "50%",
      "left": "15px",
      "transform": "translateY(-50%)",
      "z-index": "2"
    }
  }), _c('c-input', {
    attrs: {
      "id": "searchId",
      "type": "text",
      "w": "100%",
      "placeholder": "Search",
      "border-radius": ['1000px', '20px'],
      "padding-left": "50px",
      "pos": "relative",
      "z-index": "1",
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": ['400', '500'],
      "h": ['38px', '62px']
    },
    on: {
      "keyup": _vm.onSearch
    },
    model: {
      value: _vm.search,
      callback: function callback($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1), _c('ModalFilterNutritionist', {
    attrs: {
      "options": _vm.listFilterSpecialist,
      "data": _vm.filterSpecialist
    },
    on: {
      "change": _vm.handleChangeFilterSpecialist
    }
  })], 1)], 1), _vm.filterSpecialist.length > 0 ? _c('CBox', {
    directives: [{
      name: "dragscroll",
      rawName: "v-dragscroll"
    }],
    attrs: {
      "py": "3px",
      "px": ['16px', '0px'],
      "overflow": "hidden",
      "h": ['unset', 'calc(3px + 3px + 40px)'],
      "position": "relative",
      "mb": "10px"
    }
  }, [_c('CList', {
    ref: "list",
    attrs: {
      "d": "flex",
      "gap": "4px",
      "position": ['unset', 'absolute']
    }
  }, _vm._l(_vm.filterSpecialist, function (filter, idx) {
    return _c('CListItem', {
      key: filter,
      staticStyle: {
        "list-style": "none"
      }
    }, [_c('c-button', {
      attrs: {
        "variant-color": "primary",
        "variant": "outline",
        "bg": "#C7F9E3",
        "rounded": "1000px",
        "h": ['34px', '40px']
      },
      on: {
        "click": function click($event) {
          return _vm.handleDeleteFilterSpecialistByIndex(idx);
        }
      }
    }, [_vm._v(" " + _vm._s(filter) + " "), _c('c-box', {
      attrs: {
        "margin-left": "6px",
        "width": ['16px', '18px'],
        "height": ['16px', '18px']
      }
    }, [_c('inline-svg', {
      attrs: {
        "src": require('@/assets/icons/icon-circle-close.svg'),
        "height": "100%",
        "width": "100%",
        "fill": "#008C81"
      }
    })], 1)], 1)], 1);
  }), 1)], 1) : _vm._e(), _vm.isLoadingInit ? _c('c-grid', {
    attrs: {
      "w": "100%",
      "template-columns": ['repeat(1, 1fr)', 'repeat(auto-fit, minmax(400px, 1fr))'],
      "gap": "10px",
      "mb": "1rem"
    }
  }, _vm._l(4, function (index) {
    return _c('SkeletonCardNutritionist', {
      key: index
    });
  }), 1) : _vm._e(), !_vm.isLoadingInit && _vm.hasNutritionistRecommendation ? _c('c-box', {
    attrs: {
      "border-bottom": ['1px solid #F2F2F2', 'none']
    }
  }, [_c('c-box', {
    attrs: {
      "w": "100%",
      "d": "flex",
      "justify-content": ['start', 'center']
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "superDarkGray.900",
      "mb": ['10px', '16px']
    }
  }, [_vm._v(" Ahli gizi yang pernah dipilih ")])], 1), _c('c-box', [_vm.listNutritionistsRecommendation.length > 0 ? _c('CardNutritionist', {
    attrs: {
      "nutritionists": _vm.listNutritionistsRecommendation,
      "available-program": _vm.availableProgram,
      "is-disabled-choose-nutritionist": _vm.isDisabledChooseNutritionist,
      "universities": _vm.universities
    },
    on: {
      "on-choose-nutritionist": _vm.onChooseNutritionist,
      "on-profile-nutritionist": _vm.onOpenDetailNutritionist
    }
  }) : _vm._e()], 1)], 1) : _vm._e(), !_vm.isLoadingInit ? _c('c-box', {
    attrs: {
      "margin-bottom": "40px"
    }
  }, [_vm.hasNutritionist ? _c('c-box', {
    attrs: {
      "w": "100%",
      "d": "flex",
      "justify-content": ['start', 'center']
    }
  }, [_c('c-heading', {
    attrs: {
      "font-family": "Roboto",
      "font-size": ['14px', '18px'],
      "font-weight": "500",
      "color": "superDarkGray.900",
      "my": ['10px', '16px']
    }
  }, [_vm._v(" Ahli gizi tersedia ")])], 1) : _vm._e(), !_vm.isEmpty ? _c('c-box', [_vm.hasNutritionist ? _c('CardNutritionist', {
    attrs: {
      "nutritionists": _vm.listNutritionists,
      "available-program": _vm.availableProgram,
      "is-disabled-choose-nutritionist": _vm.isDisabledChooseNutritionist,
      "universities": _vm.universities
    },
    on: {
      "on-choose-nutritionist": _vm.onChooseNutritionist,
      "on-profile-nutritionist": _vm.onOpenDetailNutritionist
    }
  }) : _vm._e(), _c('c-flex', {
    attrs: {
      "justify-content": "center",
      "w": "100%",
      "margin-top": "40px"
    }
  }, [_c('c-flex', {
    attrs: {
      "justify-content": "center"
    }
  }, [_c('c-button', {
    attrs: {
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "background-color": "transparent",
      "is-disabled": _vm.currentPage <= 1
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.prevPage.apply(null, arguments);
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-left",
      "size": "24px"
    }
  })], 1), _vm._l(_vm.pages, function (page_) {
    return _c('c-button', {
      directives: [{
        name: "chakra",
        rawName: "v-chakra",
        value: {
          ':hover': {
            bg: 'rgba(0, 140, 129, 0.3)',
            color: 'primary.400'
          }
        },
        expression: "{\n                  ':hover': {\n                    bg: 'rgba(0, 140, 129, 0.3)',\n                    color: 'primary.400',\n                  },\n                }"
      }],
      key: page_,
      attrs: {
        "px": "0",
        "py": "4px",
        "w": "32px",
        "h": "32px",
        "mx": "4px",
        "background-color": "rgba(0, 140, 129, 0.3)",
        "color": "primary.400",
        "bg": page_ === _vm.currentPage ? ['rgba(0, 140, 129, 0.3)'] : ['transparent'],
        "as": "router-link",
        "to": {
          name: _vm.$route.name,
          query: Object.assign({}, _vm.$route.query, {
            page: page_
          })
        }
      }
    }, [_vm._v(" " + _vm._s(page_) + " ")]);
  }), _c('c-button', {
    attrs: {
      "px": "0",
      "py": "4px",
      "w": "32px",
      "h": "32px",
      "background-color": "transparent",
      "is-disabled": _vm.pages.length === _vm.currentPage
    },
    on: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.nextPage.apply(null, arguments);
      }
    }
  }, [_c('c-icon', {
    attrs: {
      "name": "chevron-right",
      "size": "24px"
    }
  })], 1)], 2)], 1)], 1) : _vm._e()], 1) : _vm._e(), _vm.isEmpty && !_vm.isLoadingInit ? _c('c-flex', {
    attrs: {
      "py": "80px",
      "px": "20px",
      "justify-content": "center"
    }
  }, [_c('c-box', [_c('c-image', {
    attrs: {
      "src": require('@/assets/empty-nutritionists.svg'),
      "alt": "empty"
    }
  }), _c('c-text', {
    attrs: {
      "color": "gray.900",
      "font-family": "Roboto",
      "font-size": "24px",
      "margin-top": "20px"
    }
  }, [_vm._v(" Ahli gizi tidak ditemukan ")])], 1)], 1) : _vm._e()], 1)], 1), _vm.isProgramFillQuestionnaire ? _c('c-box', {
    attrs: {
      "pos": "fixed",
      "bottom": ['0px', '20px'],
      "right": ['0px', '20px'],
      "max-width": "860px",
      "z-index": "11"
    }
  }, [_c('c-alert', {
    attrs: {
      "status": "warning"
    }
  }, [_c('c-alert-icon'), _c('c-alert-description', [_c('div', [_vm._v(" Kamu punya formulir gizi yang belum selesai, mohon untuk melengkapinya ")]), _c('c-button', {
    attrs: {
      "size": "sm",
      "variant": "ghost",
      "variant-color": "warning",
      "float": "right",
      "as": "router-link",
      "to": {
        name: 'client.kuisioner',
        params: {
          programId: (_vm$programFillQuesti = _vm.programFillQuestionnaire) === null || _vm$programFillQuesti === void 0 ? void 0 : _vm$programFillQuesti.id
        }
      }
    }
  }, [_vm._v(" Lengkapi ")])], 1)], 1)], 1) : _vm.hasProgramActive === false && _vm.availableProgram === false ? _c('c-box', {
    attrs: {
      "pos": "fixed",
      "bottom": ['0px', '20px'],
      "right": ['0px', '20px'],
      "max-width": "860px",
      "z-index": "11"
    }
  }, [_c('c-alert', {
    attrs: {
      "status": "warning"
    }
  }, [_c('c-alert-icon'), _c('c-alert-description', [_c('div', [_vm._v(" Kamu belum punya program, silakan beli program terlebih dahulu. ")]), _c('c-button', {
    attrs: {
      "size": "sm",
      "variant": "ghost",
      "variant-color": "warning",
      "float": "right",
      "as": "router-link",
      "to": {
        name: 'client.select-program'
      }
    }
  }, [_vm._v(" Beli Program ")])], 1)], 1)], 1) : _vm._e(), _c('ModalConfirm', {
    attrs: {
      "title": "Apa kamu yakin ingin memilih Ahli Gizi ini?",
      "is-open": _vm.isConfirm,
      "is-loading": _vm.isLoadingNutritionists
    },
    on: {
      "close": _vm.onCloseConfirm,
      "submit": function submit() {
        _vm.onSubmitNutritionist(_vm.selectedNutritionists);
      }
    }
  }), _c('ModalError', {
    attrs: {
      "is-open": _vm.isError,
      "image": require('@/assets/images/illustration-130323-raw.jpeg'),
      "title": "Tidak dapat memilih ahli gizi karena belum memiliki program",
      "note": "",
      "button-text": "Beli Program",
      "button-icon": false,
      "button-action": function buttonAction() {
        _vm.$router.push({
          name: 'client.select-program'
        });
      }
    },
    on: {
      "close": _vm.onCloseError
    }
  }), _c('ModalNutritionists', {
    attrs: {
      "is-show-skeleton": _vm.isShowSkeletonModalNutritionist,
      "is-open": _vm.isOpenModal,
      "str-number": _vm.nutritionistModal.str,
      "specializations": _vm.nutritionistModal.specialization,
      "service-hour": _vm.nutritionistModal.serviceHour,
      "rating": _vm.nutritionistModal.rating,
      "problem-handles": _vm.nutritionistModal.problemHandled,
      "photo-url": _vm.nutritionistModal.photoUrl,
      "languages": _vm.nutritionistModal.languages ? [_vm.nutritionistModal.languages] : [],
      "client-age-handles": _vm.nutritionistModal.clientAgeHandled ? [_vm.nutritionistModal.clientAgeHandled] : [],
      "fullname": _vm.getFullnameAndTitle("".concat(_vm.nutritionistModal.firstName || '', " ").concat(_vm.nutritionistModal.lastName || '').trim(), _vm.nutritionistModal.education),
      "educations": _vm.educationsParser(_vm.nutritionistModal.education),
      "work-experiences": _vm.workExperiencesParser(_vm.nutritionistModal.workExperiences),
      "chat-active-hours": _vm.chatActiveHoursParser(_vm.nutritionistModal.activeHour),
      "service-times": _vm.serviceTimesParser(_vm.nutritionistModal.serviceTime),
      "with-button-choose-nutritionist": "",
      "is-disabled-button-choose-nutritionist": _vm.isDisabledChooseNutritionist || !_vm.isHasRemainingQuota(((_vm$nutritionistModal = _vm.nutritionistModal) === null || _vm$nutritionistModal === void 0 ? void 0 : _vm$nutritionistModal.remainingQuota) || 0)
    },
    on: {
      "on-close": _vm.onCloseDetailNutritionist,
      "on-choose-nutritionist": function onChooseNutritionist() {
        return _vm.onChooseNutritionist(_vm.nutritionistModal.id);
      }
    }
  })], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }