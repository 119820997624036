<template>
  <c-box
    width="100%"
    mb="2rem"
  >
    <c-grid
      v-chakra="{
        '>:not(:first-child)': {
          borderLeftWidth: '1px',
          borderLeftStyle: 'solid',
          borderLeftColor: 'primary.400',
        },
        '>:first-child': {
          borderTopLeftRadius: '12px !important',
        },
        '>:last-child': {
          borderTopRightRadius: '12px !important',
        },
      }"
      w="100%"
      :template-columns="['repeat(3, 1fr)']"
      align-items="flex-end"
    >
      <c-flex
        v-for="tab in __tabs"
        :key="tab.value"
        width="100%"
        justify-content="center"
        align-items="center"
        padding="8px 16px"
        font-size="18px"
        font-weight="500"
        :background-color="activeTab === tab.value ? 'primary.400' : 'primary.50'"
        :color="
          activeTab === tab.value
            ? '#FFF'
            : tab.validation !== 1
              ? 'danger.400'
              : 'primary.400'
        "
        :height="activeTab === tab.value ? '60px' : '50px'"
        :border-top-left-radius="activeTab === tab.value ? '12px' : '0'"
        :border-top-right-radius="activeTab === tab.value ? '12px' : '0'"
        :cursor="tab.isDisabled ? 'not-allowed' : 'pointer'"
        :opacity="tab.isDisabled ? '0.6' : '1'"
        @click="onChangeTab(tab)"
      >
        <c-flex
          align-items="flex-start"
          gap="6px"
        >
          {{ tab.label }}
          <c-box
            v-if="tab.validation !== 1"
            width="8px"
            height="8px"
            border-radius="8px"
            :background-color="activeTab === tab.value ? '#FFF' : 'danger.400'"
          />
        </c-flex>
      </c-flex>
    </c-grid>
    <c-box
      :padding="padding"
      border-style="solid"
      border-width="1px"
      border-color="primary.400"
      border-bottom-left-radius="12px"
      border-bottom-right-radius="12px"
    >
      <slot />
    </c-box>
  </c-box>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'MealPlanTabs',
  props: {
    activeTab: {
      type: String,
      default: 'profile',
    },
    mode: {
      type: String,
      default: 'detail',
    },
    isProgramConsultationBasic: {
      type: Boolean,
      default: false,
    },
    tabs: {
      type: Array,
      default: () => [],
    },
    padding: {
      type: String,
      default: '16px',
    },
  },
  computed: {
    ...mapState({
      role: (s) => s.auth.user?.role,
    }),
    __tabs() {
      if (this.tabs.length === 0) {
        return [
          { label: 'Profil Gizi', value: 'profile', isDisabled: false, validation: 1 },
          {
            label: 'Rencana Makan',
            value: 'meal-plan',
            isDisabled: false,
            validation: 1,
          },
          {
            label: 'Rekomendasi Menu',
            value: 'recommendation',
            isDisabled: false,
            validation: 1,
          },
        ]
      }

      return this.tabs
    },
  },
  methods: {
    onChangeTab(tab) {
      if (tab?.isDisabled) return
      switch (tab?.value) {
        case 'profile':
          return this.$router.push({
            name:
              this.role === 'nutritionist'
                ? this.mode === 'detail'
                  ? 'nutri.meal-plan.profile.detail'
                  : 'nutri.meal-plan.profile.edit'
                : 'admin.clients.meal-plan.profile',
            params: {
              clientId: this.$route.params.clientId,
              programId: this.$route.params.programId,
              month: this.$route.params.month,
              savePosition: true,
            },
          })
        case 'meal-plan':
          return this.$router.push({
            name:
              this.role === 'nutritionist'
                ? this.mode === 'detail'
                  ? 'nutri.meal-plan.meal-plan-schedule.detail'
                  : 'nutri.meal-plan.meal-plan-schedule.edit'
                : 'admin.clients.meal-plan.meal-plan-schedule',
            params: {
              clientId: this.$route.params.clientId,
              programId: this.$route.params.programId,
              month: this.$route.params.month,
              savePosition: true,
            },
          })
        case 'recommendation':
          return this.$router.push({
            name:
              this.role === 'nutritionist'
                ? this.mode === 'detail'
                  ? 'nutri.meal-plan.menu-recommendation.detail'
                  : 'nutri.meal-plan.menu-recommendation.edit'
                : 'admin.clients.meal-plan.menu-recommendation',
            params: {
              clientId: this.$route.params.clientId,
              programId: this.$route.params.programId,
              month: this.$route.params.month,
              savePosition: true,
            },
          })
      }
    },
  },
}
</script>
