<template>
  <c-box width="100%">
    <BreadcrumbPath
      mt="1rem"
      pb="20px"
      :paths="[
        {
          label: 'Manajemen Panduan Diet',
          href: { name: 'admin.coaching-challenge' }
        },
        {
          label: `${id ? 'Ubah' : 'Tambah'} ${_getContentType}`,
          isCurrent: true,
        },
      ]"
    />

    <c-box
      position="relative"
      mx="auto"
      :mb="['0', '16px']"
      :p="['1rem', '2rem 4rem']"
      border="1px solid #f2f2f2"
      :box-shadow="['none', '2px 2px 10px rgba(0, 0, 0, 0.15)']"
      :border-radius="['0px', '16px']"
      width="100%"
    >
      <c-flex
        justify="space-between"
        align="center"
        mb="30px"
      >
        <c-heading
          as="h3"
          font-family="Roboto"
          font-weight="bold"
          font-size="24px"
          line-height="36px"
        >
          {{ id ? "Edit" : "Tambah" }} Konten
        </c-heading>
        <c-button
          v-if="id"
          variant="solid"
          variant-color="red"
          border-radius="60px"
          min-w="114px"
          h="auto"
          py="13px"
          px="18px"
          mr="20px"
          font-size="16px"
          font-weight="500"
          line-height="24px"
          @click="onOpenModalConfirmDelete"
        >
          <c-image
            :src="require('@/assets/icon-trash.svg')"
            alt="file program"
            mr="10px"
            border-radius="6px"
          />
          Hapus
        </c-button>
      </c-flex>

      <BaseInputSelect
        v-model="type"
        label="Tipe Konten"
        placeholder="Pilih Tipe Konten"
        is-required
        :is-disabled="!!id"
        full-width
        :options="types_"
      />

      <BaseInputText
        v-model="creator"
        label="Penulis"
        placeholder="Masukkan Penulis"
        :is-required="type == 'tips_gizi' ? true : false"
      />

      <BaseLabel
        :value="categories.length > 0 ? true : false"
        label="Kategori"
        is-required
      >
        <c-form-control pos="relative">
          <c-input-group>
            <c-input
              :value="categoriesText"
              type="text"
              :height="['48px', '62px']"
              :font-size="['14px', '18px']"
              color="primary.400"
              font-weight="500"
              placeholder="Pilih Filter"
              border-top-right-radius="8px"
              border-bottom-right-radius="8px"
              border-top-left-radius="8px"
              border-bottom-left-radius="8px"
              focus-border-color="primary.400"
              padding="0 20px"
              readonly
              @click.prevent="onOpenFilter"
            />
            <c-flex
              pos="absolute"
              right="0"
              height="100%"
              width="fit-content"
              color="#008C81"
              justify-content="center"
              align-items="center"
              margin-right="1rem"
              margin-left="1rem"
              cursor="pointer"
              z-index="2"
            > 
              <c-box
                transform="rotate(90deg)"
                @click.prevent="onOpenFilter"
              >
                <inline-svg
                  :src="require('@/assets/icon-chevron-right.svg')"
                  height="24px"
                  width="24px"
                />
              </c-box>
            </c-flex>
          </c-input-group>
        </c-form-control>
      </BaseLabel>

      <BaseLabel
        v-if="type && type != 'tips_gizi'"
        :value="showOnDay && monthGroup"
        label="Tampilkan Hari Ke"
        is-required
      >
        <SelectDateContent
          :day="showOnDay"
          :month="monthGroup"
          :attributes="listUsedContent"
          :content-id="id"
          @update:day="(value) => showOnDay = value"
          @update:month="(value) => monthGroup = value"
        />
      </BaseLabel>

      <BaseInputText
        v-model="title"
        label="Judul Konten"
        placeholder="Masukkan Judul Konten"
        is-required
      />

      <BaseLabel
        v-if="type == 'target_harian'"
        :value="isRestDay"
        label="Rest Day"
        is-required
      >
        <c-radio-group 
          v-model="isRestDay" 
          is-inline
          mt="8px"
          spacing="20"
        >
          <c-radio 
            size="lg" 
            value="1"
            variant-color="primary"
          >
            Ya
          </c-radio>
          <c-radio 
            size="lg" 
            value="0"
            variant-color="primary"
          >
            Tidak
          </c-radio>
        </c-radio-group>
      </BaseLabel>
      
      <BaseLabel
        :value="coverImage"
        label="Preview Cover Program"
        is-required
      >
        <label
          v-chakra="{
            bg: 'white',
            cursor: 'pointer',
            border: '1px solid #C4C4C4',
            boxSizing: 'border-box',
            borderRadius: '6px',
            w: '180px',
            h: '100%',
            minHeight: '180px',
            d: 'flex',
            p: '0',
            alignItems: 'center',
            justifyContent: 'center',
            overflow: 'hidden',
          }"
          for="img-preview"
        >
          <c-image
            v-if="coverImage"
            :src="getPreviewImage(coverImage)"
            :h="
              !getPreviewImage(coverImage).includes('icon-photo')
                ? '100%'
                : '94px'
            "
            alt="img program"
            mx="auto"
            border-radius="6px"
          />
          <inline-svg
            v-else
            :src="require('@/assets/icons/icon-image-preview.svg')"
            height="100px"
            width="100px"
            fill="#008C81"
          />
        </label>
        <c-input
          id="img-preview"
          style="display: none"
          type="file"
          accept=".jpg, .jpeg, .png"
          @change="onChangeCoverImage($event)"
        />
      </BaseLabel>

      <c-box
        v-show="contentSection.length > 0"
        border="1px solid #C4C4C4"
        border-radius="8px"
        mb="20px"
      >
        <draggable 
          v-model="contentSection"
          tag="transition-group"
          :component-data="{
            tag: 'ul',
            type: 'transition-group',
            name: !drag ? 'flip-list' : null
          }"
          v-bind="dragOptions"
          @start="drag = true"
          @end="drag = false"
        >
          <c-box
            v-for="(section, index) in contentSection"
            :key="`section-${index}`"
            p="1rem"
            :border-bottom="contentSection.length != (index + 1) ? '1px solid #C4C4C4' : 'none'"
          >
            <c-flex
              align-items="center"
              justify-content="space-between"
            >
              <c-flex
                align-items="center"
                justify-content="center"
                gap="8px"
              >
                <c-flex
                  d="flex"
                  align-items="center"
                  justify-content="center"
                  w="40px"
                  h="40px"
                  border-radius="8px"
                  cursor="move"
                >
                  <inline-svg
                    :src="require('@/assets/icon-hamburger.svg')"
                    height="24px"
                    width="24px"
                  />
                </c-flex>
                <c-heading
                  as="h4"
                  font-family="Roboto"
                  font-weight="bold"
                  font-size="18px"
                  line-height="27px"
                  color="#008C81"
                > 
                  Section {{ index+1 }} ({{ section?.contentSectionType?.sectionName }})
                </c-heading>
              </c-flex>
              <c-flex
                align-items="center"
                gap="1rem"
              >
                <BaseMenu
                  trigger="manual"
                  :wrapper-styles="{ display: 'flex', justifyContent: 'center' }"
                  :content-styles="{ minWidth: 'inherit' }"
                  :button-styles="{ width: '60%' }"
                  placement="left-end"
                >
                  <template #button="{ toggle }">
                    <c-button
                      variant="outline"
                      p="0"
                      @click.stop="toggle"
                    >
                      <inline-svg
                        :src="require('@/assets/icons/icon-option.svg')"
                        height="16"
                        width="16"
                        fill="#008C81"
                      />
                    </c-button>
                  </template>
                  <template #menu="{ close }">
                    <c-box
                      z-index="10"
                    >
                      <c-flex direction="column">
                        <c-button
                          v-for="contentSection_ in contentSections"
                          :key="contentSection_.id"
                          variant-color="primary"
                          variant="ghost"
                          size="lg"
                          @click="() => {
                            close()
                            updateSection(contentSection_, index)
                          }"
                        >
                          {{ contentSection_?.sectionName }}
                        </c-button>
                        <c-divider
                          m="0"
                          color="#C4C4C4"
                        />
                        <c-button
                          variant-color="danger"
                          variant="ghost"
                          size="lg"
                          justify-content="left"
                          @click="() => {
                            close()
                            deleteSection(index)
                          }"
                        >
                          Hapus Section
                        </c-button>
                      </c-flex>
                    </c-box>
                  </template>
                </BaseMenu>
                <c-button
                  variant="outline"
                  variant-color="primary"
                  p="0"
                  @click="onToggleSection(index)"
                >
                  <inline-svg
                    :src="require('@/assets/icon-chevron-right.svg')"
                    height="24px"
                    width="24px"
                    :style="{transform: listOpenSection.includes(index) ? 'rotate(-90deg)' : 'rotate(90deg)'}"
                  />
                </c-button>
              </c-flex>
            </c-flex>
            <c-divider
              v-show="listOpenSection.includes(index)"
              mt="10px"
              mb="22px"
              color="#C4C4C4"
            />
            <c-box v-show="listOpenSection.includes(index)">
              <SectionYoutube
                v-if="section.contentSectionType.sectionName === 'YouTube'"
                :description="section.description"
                :url="section.url"
                @change-description="(newValue) => section.description = newValue"
                @change-url="(newValue) => section.url = newValue"
              />
              <SectionDescription
                v-else-if="section.contentSectionType.sectionName === 'Description'"
                v-model="section.description"
                :idx="index"
              />
              <SectionImage
                v-else-if="section.contentSectionType.sectionName === 'Gambar'"
                :idx="index"
                :description="section.description"
                :image="section.url"
                @change-description="(newValue) => section.description = newValue"
                @change-image="(newValue) => testt(newValue, index)"
              />
              <SectionCarousel
                v-else-if="section.contentSectionType.sectionName === 'Carousel'"
                :description="section.description"
                :photos="section.contentCarouselImage"
                @change-description="(newValue) => section.description = newValue"
                @change-photos="(newValue) => section.contentCarouselImage = newValue"
              />
              <SectionUploadFile
                v-else-if="section.contentSectionType.sectionName === 'Document/File'"
                :idx="index"
                :description="section.description"
                :image="section.url"
                :max-size="20"
                @change-description="(newValue) => section.description = newValue"
                @change-image="(newValue) => section.url = newValue"
              />
            </c-box>
          </c-box>
        </draggable>
      </c-box>

      <BaseMenu
        trigger="manual"
        :wrapper-styles="{ display: 'flex', width: '100%', justifyContent: 'center' }"
        :content-styles="{ minWidth: 'inherit' }"
        :button-styles="{ width: '60%' }"
      >
        <template #button="{ toggle }">
          <BaseButton
            width="100%"
            size="medium"
            border-radius="1000px"
            :left-svg-icon="require('@/assets/icons/icon-plus.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            @click.stop="toggle"
          >
            Tambah Section
          </BaseButton>
        </template>
        <template #menu="{ close }">
          <c-box
            z-index="10"
          >
            <c-flex direction="column">
              <c-button
                v-for="section in contentSections"
                :key="section.id"
                variant-color="primary"
                variant="ghost"
                size="lg"
                @click="() => {
                  close()
                  addSection(section)
                }"
              >
                {{ section?.sectionName }}
              </c-button>
            </c-flex>
          </c-box>
        </template>
      </BaseMenu>

      <c-flex
        margin-top="28px"
        padding="0 40px"
        justify-content="space-between"
        gap="20px"
      >
        <BaseButton
          size="medium"
          width="100%"
          variant="outlined"
          @click="onCancel"
        >
          Batal
        </BaseButton>
        <BaseButton
          size="medium"
          width="100%"
          :disabled="isDisabledSubmit"
          @click="onOpenModalConfirmSave"
        >
          Simpan
        </BaseButton>
      </c-flex>
    </c-box>
    <c-alert
      v-if="isUploading"
      position="fixed"
      top="5px"
      left="50%"
      transform="translateX(-50%)"
      border-radius="3px"
      w="200px"
      status="info"
      font-size="14px"
      z-index="9999"
    >
      <c-alert-icon />
      Uploading...
    </c-alert>

    <ModalFilter 
      v-if="isOpenFilter"
      :is-open="isOpenFilter"
      :data="categoriesContent"
      :selected="selectedCategories"
      :categories="categories"
      :sub-categories="subCategories"
      @close="onCloseFilter"
      @save="onSaveFilter"
      @update:categories="(value) => categories = value"
      @update:subCategories="(value) => subCategories = value"
    />

    <BaseModal
      :is-open="isOpenModalConfirmSave"
      :close-on-overlay-click="false"
      :with-button-close="false"
    >
      <template #header>
        <c-box
          display="flex"
          justify-content="center"
          margin="24px 0 0 0"
        >
          <c-image
            height="120px"
            object-fit="cover"
            :src="require('@/assets/icon-confirm-update.svg')"
          />
        </c-box>
      </template>
      <template #body>
        <c-flex
          justify-content="center"
          padding="30px 24px"
          text-align="center"
        >
          <BaseText
            size-mobile="18px"
            size-desktop="20px"
            color="primary.400"
          >
            {{ id ? 'Apakah anda yakin ingin mengubah konten?' : 'Apakah anda yakin ingin melakukan penambahan konten?' }}
          </BaseText>
        </c-flex>
      </template>
      <template #footer>
        <c-flex
          gap="20px"
          width="100%"
          padding-left="24px"
          padding-right="24px"
          margin-bottom="24px"
        >
          <BaseButton
            :left-svg-icon="require('@/assets/icons/icon-circle-close.svg')"
            left-svg-icon-color="#008C81"
            variant="outlined"
            rounded="1000px"
            width="100%"
            :disabled="isLoading"
            @click="onCloseModalConfirmSave"
          >
            Batal
          </BaseButton>
          <BaseButton
            :right-svg-icon="require('@/assets/icons/icon-confirm.svg')"
            right-svg-icon-color="white"
            color="primary.400"
            rounded="1000px"
            width="100%"
            :is-loading="isLoading"
            @click="onSubmit"
          >
            Simpan
          </BaseButton>
        </c-flex>
      </template>
    </BaseModal>
  </c-box>
</template>

<script>
import _ from 'lodash'
import { mapGetters, mapActions } from 'vuex'
import generalMixin from '@/utils/general-mixins'
import BaseInputText from '@/components/elements/base-input-text.vue'
import BaseInputSelect from '@/components/elements/base-input-select.vue'
import BaseLabel from '@/components/elements/base-label.vue'
import BaseButton from '@/components/elements/base-button.vue'
import BreadcrumbPath from '@/components/elements/breadcrumb-path.vue'
import BaseMenu from '@/components/elements/base-menu.vue'
import SectionYoutube from '@/views/admin/coaching-challenge/_views/section-youtube.vue'
import SectionDescription from '@/views/admin/coaching-challenge/_views/section-description.vue'
import SectionImage from '@/views/admin/coaching-challenge/_views/section-image.vue'
import SectionCarousel from '@/views/admin/coaching-challenge/_views/section-carousel.vue'
import SectionUploadFile from '@/views/admin/coaching-challenge/_views/section-upload-file.vue'
import ModalFilter from '@/views/admin/coaching-challenge/_widgets/modal-filter.vue'
import SelectDateContent from '@/views/admin/coaching-challenge/_widgets/select-date-content.vue'
import BaseModal from '@/components/elements/base-modal.vue'
import BaseText from '@/components/elements/base-text.vue'
import draggable from 'vuedraggable'
import { required, requiredIf } from 'vuelidate/lib/validators'

export default {
  name: 'AdminCoachingChallengeForm',
  components: {
    SectionUploadFile,
    SectionCarousel,
    SectionImage,
    SectionDescription,
    SectionYoutube,
    BaseMenu,
    BreadcrumbPath, 
    BaseButton, 
    BaseLabel, 
    BaseInputSelect,
    BaseInputText,
    ModalFilter,
    SelectDateContent,
    BaseModal,
    BaseText,
    draggable,
  },
  mixins: [generalMixin],
  data() {
    return {
      type: this.$route.params.type ?? 'tips_gizi',
      categories: [],
      creator: null,
      title: null,
      description: null,
      showOnDay: null,
      subCategories: [],
      monthGroup: null,
      coverImage: null,
      contentSection: [],
      isRestDay: null,

      isOpenFilter: false,
      listUsedContent: [],
      listOpenSection: [],

      isOpenModalConfirmSave: false,
      isOpenModalConfirmDelete: false,
      isLoading: false,
      isUploading: false,
      drag: false,
    }
  },
  computed: {
    ...mapGetters({
      axios: 'axios',
      item: 'admContentChallenge/item',
      types: 'admContentChallenge/types',
      categoriesContent: 'admContentChallenge/category',
      contentSections: 'admContentChallenge/contentSections',
    }),
    dragOptions() {
      return {
        animation: 200,
        group: 'description',
        disabled: false,
        ghostClass: 'ghost',
      }
    },
    _getContentType() {
      switch (this.item.type) {
        case 'tips_gizi':
          return 'Tips Gizi'
        case 'materi_harian':
          return 'Materi Harian'
        case 'target_harian':
          return 'Target Harian'
        default:
          return ''
      }
    },
    id() {
      return this.$route.params.id
    },
    types_() {
      if (this.types.length > 0) {
        return this.types.map((it) => {
          return {
            value: it.type,
            label: it.name,
          }
        })
      }
      return []
    },
    typeId() {
      if (this.types.length > 0 && this.type) {
        return this.types.find((it) => it.type == this.type)?.id
      }
      return undefined
    },
    selectedCategories() {
      if (this.categories.length > 0) {
        let category = Object.fromEntries(this.categories.map((it) => [it, []]))
        if (this.subCategories && this.subCategories?.length > 0) {
          this.subCategories?.forEach((subId) => {
            this.categoriesContent?.forEach((it) => {
              const find = it?.subCategories.find((c) => c?.id == subId)
              if (find) {
                category[it?.id].push(find?.id)
                return false
              }
            })
          })
        }
        return category
      }
      return {}
    },
    categoriesText() {
      if (!_.isEmpty(this.selectedCategories) && this.categoriesContent?.length > 0) {
        let text = []
        for (let key in this.selectedCategories) {
          const cat = this.categoriesContent?.find((it) => it.id == key)
          let subCat = []
          if (this.subCategories && this.subCategories?.length > 0) {
            this.subCategories?.forEach((subId) => {
              if (cat?.subCategories?.length > 0) {
                const find = cat.subCategories?.find((it) => it?.id == subId)
                if (find) {
                  subCat.push(find?.subCategoryName)
                }
              } 
            })
          }

          if (cat) {
            text.push(`${cat?.categoryName}${subCat.length > 0 ? ': ' + subCat.join(', ') : ''}`)
          }
        }
        return text.join('; ')
      }
      return ''
    },
    isDisabledSubmit() {
      if (
        this.$v.$invalid ||
        this.contentSection.length == 0
      )
        return true
      return false
    },
  },
  watch: {
    type: {
      immediate: true,
      handler(val) {
        if (val !== 'tips_gizi') {
          this.loadContentDay()
        }
      },
    },
    typeId: {
      async handler(val) {
        if (val) {
          await this.loadContentCategories()
        }
      },
    },
  },
  validations: {
    type: { required },
    title: { required },
    creator: { required: requiredIf((model) => model.type == 'tips_gizi') },
    categories: { 
      required,
      minLength: 1,
    },
    coverImage: { required },
    contentSection: {
      required,
      $each: {
        url: { required: requiredIf((v) => ['Gambar', 'YouTube', 'Document/File'].includes(v.contentSectionType.sectionName)) },
        description: { required: requiredIf((v) => ['Description'].includes(v.contentSectionType.sectionName)) },
        contentCarouselImage: { 
          required: requiredIf((v) => ['Carousel'].includes(v.contentSectionType.sectionName)),
          minLength: 1,
          // $each: {
          //   url: { required },
          // }
        },
      },
    },
    isRestDay: { required: requiredIf((model) => model.type == 'target_harian') },
  },
  async created() {
    if (this.types.length == 0) {
      await this.getContentTypes()
    }
    this.getContentSectionType()
    if (!this.id) return
    this.detailContentChallenge(this.id).then((res) => {
      this.type = res.type
      this.categories = res.categories
      this.creator = res.creator
      this.title = res.title
      this.description= res.description
      this.showOnDay= res.showOnDay
      this.subCategories = res.subCategories
      this.monthGroup= res.monthGroup
      this.coverImage= res.coverImage
      this.contentSection= res.contentSection
      this.isRestDay = String(res.isRestDay)
    })
  },
  methods: {
    ...mapActions({
      getContentTypes: 'admContentChallenge/getContentTypes',
      getContentCategory: 'admContentChallenge/getContentCategory',
      getContentDay: 'admContentChallenge/getContentDay',
      getContentSectionType: 'admContentChallenge/getContentSectionType',
      createContentChallenge: 'admContentChallenge/createContentChallengeAdmin',
      detailContentChallenge: 'admContentChallenge/detailContentChallengeAdmin',
      updateContentChallenge: 'admContentChallenge/updateContentChallengeAdmin',
    }),
    onToggleSection(index) {
      if (this.listOpenSection.includes(index)) {
        this.listOpenSection = this.listOpenSection.filter((it) => it != index)
      } else {
        this.listOpenSection.push(index)
      }
    },
    loadContentCategories() {
      let params = new URLSearchParams()
      params.set('type', this.typeId)
      params.set('page', 1)
      params.set('perpage', 50)
      params.set('subCategories', 1)
      this.getContentCategory(params)
    },
    loadContentDay() {
      let params = new URLSearchParams()
      params.set('type', this.type)
      this.getContentDay(params)
        .then((res) => {
          this.listUsedContent = res
        })
    },
    addSection(section) {
      switch (section.sectionName) {
        case 'Description':
          this.contentSection.push({
            description: '',
            contentSectionType: section,
          })
          break
        case 'Gambar':
          this.contentSection.push({
            description: '',
            url: '',
            contentSectionType: section,
          })
          break
        case 'Carousel':
          this.contentSection.push({
            description: '',
            contentCarouselImage: [],
            contentSectionType: section,
          })
          break
        case 'YouTube':
          this.contentSection.push({
            description: '',
            url: '',
            contentSectionType: section,
          })
          break
        case 'Document/File':
          this.contentSection.push({
            description: '',
            url: '',
            contentSectionType: section,
          })
          break
      }
      this.listOpenSection.push(this.contentSection.length-1)
    },
    updateSection(section, index) {
      this.onToggleSection(index)
      switch (section.sectionName) {
        case 'Description':
          this.contentSection[index] = {
            description: '',
            contentSectionType: section,
          }
          break
        case 'Gambar':
          this.contentSection[index] = {
            description: '',
            url: '',
            contentSectionType: section,
          }
          break
        case 'Carousel':
          this.contentSection[index] = {
            contentCarouselImage: [],
            contentSectionType: section,
          }
          break
        case 'YouTube':
          this.contentSection[index] = {
            url: '',
            contentSectionType: section,
          }
          break
        case 'Document/File':
          this.contentSection[index] = {
            description: '',
            url: '',
            contentSectionType: section,
          }
          break
      }
      this.onToggleSection(index)
    },
    deleteSection(index) {
      this.contentSection.splice(index, 1)
    },
    getPreviewImage(url) {
      if (typeof url == 'string') {
        return url
      }
      if (url) {
        return URL.createObjectURL(url)
      }
      return require('@/assets/icon-photo.svg')
    },
    testt(newValue, index) {
      this.contentSection[index].url = newValue
    },
    onChangeCoverImage(input) {
      if (input.target.files && input.target.files[0]) {
        let file = input.target.files[0]
        if (file.type.includes('image') && file.size > 2097152) {
          return this.$toast({
            title: 'Failed',
            description: `This file can't be uploaded, because it (${this.formatBytes(
              file.size,
            )}) exceeds the maximum file size (2 MB).`,
            status: 'error',
            duration: 10000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        }
        this.coverImage = file
      }
    },
    onUploadFile(payload) {
      let formData = new FormData()
      let fileName = payload?.name || 'file'
      let file = new File([payload], fileName)
      formData.append('file', file, fileName)

      return this.axios
        .post('/v1/admin/content-and-challenge/upload', formData)
        .then((r) => r.data)
    },
    onOpenModalConfirmSave() {
      this.isOpenModalConfirmSave = true
    },
    onCloseModalConfirmSave() {
      if (!this.isLoading) {
        this.isOpenModalConfirmSave = false
      }
    },
    async onSubmit() {
      this.isLoading = true
      this.isUploading = true
      let cover = this.coverImage
      if (typeof cover !== 'string') {
        let res = await this.onUploadFile(cover)
        cover = res?.data?.url
      }

      let sections = this.contentSection
      for (let key in sections) {
        if (sections[key]?.contentCarouselImage) {
          let arr = []
          let order = 1
          for (let index in sections[key].contentCarouselImage) {
            if (!_.isEmpty(sections[key].contentCarouselImage[index])) {
              if (typeof sections[key].contentCarouselImage[index].url != 'string') {
                const res = await this.onUploadFile(sections[key].contentCarouselImage[index].url)
                arr.push({
                  url: res?.data?.url,
                  order: order,
                })
              } else {
                arr.push({
                  url: sections[key].contentCarouselImage[index].url,
                  order: order,
                })
              }
              order+=1
            }
          }
          sections[key].contentCarouselImage = arr
        }
        if (sections[key]?.url) {
          if (typeof sections[key]?.url != 'string') {
            const res = await this.onUploadFile(sections[key].url)
            if (sections[key].contentSectionType.sectionName === 'Document/File') {
              sections[key].description = JSON.stringify(res?.meta)
            }
            sections[key].url = res?.data?.url
          }
        }
      }
      sections = sections.map((it, idx) => {
        return {
          ...it,
          contentSectionTypeId: it.contentSectionType.id,
          order: (idx + 1),
        }
      })

      let data = {
        title: this.title,
        categories: this.categories,
        type: this.type,
        creator: this.creator,
        subCategories: this.subCategories,
        coverImage: cover,
        showOnDay: this.type == 'tips_gizi' ? null : this.showOnDay,
        monthGroup: this.type == 'tips_gizi' ? null : this.monthGroup,
        contentSection: sections,
      }

      if (this.type == 'target_harian') {
        data.isRestDay = Number(this.isRestDay)
      }

      if (this.id) {
        data.id = this.id
        const remapContentSection = data?.contentSection?.map((contentSection) => ({
          ...contentSection,
          contentAndChallengeId: data.id,
        }))
        return this.updateContentChallenge({
          ...data,
          contentSection: remapContentSection,
        })
          .then(() => {
            this.$toast({
              title: 'Success',
              description: 'Update content is successfully',
              status: 'success',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
            this.$router.push({
              name: 'admin.coaching-challenge.detail',
              params: { id: this.id },
            })
          })
          .catch((err) => {
            this.$toast({
              title: 'Failed',
              description: err.data.message ?? 'Ops! Something when wrong.',
              status: 'error',
              duration: 5000,
              position: 'bottom-right',
              variant: 'subtle',
            })
          })
          .finally(() => {
            this.isUploading = false
            this.isLoading = false
            this.onCloseModalConfirmSave()
          })
      }
      return this.createContentChallenge(data)
        .then(() => {
          this.$toast({
            title: 'Success',
            description: 'Create content is successfully',
            status: 'success',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
          this.$router.push({
            name: 'admin.coaching-challenge',
            query: { type: this.type },
          })
        })
        .catch((err) => {
          this.$toast({
            title: 'Failed',
            description: err.data.message ?? 'Ops! Something when wrong.',
            status: 'error',
            duration: 5000,
            position: 'bottom-right',
            variant: 'subtle',
          })
        })
        .finally(() => {
          this.isUploading = false
          this.isLoading = false
          this.onCloseModalConfirmSave()
        })
    },
    onCancel() {
      if (this.id) {
        this.$router.push({
          name: 'admin.coaching-challenge.detail',
          params: { id: this.id },
        })
      } else {
        this.$router.push({
          name: 'admin.coaching-challenge',
          query: { type: this.type },
        })
      }
    },
    onOpenFilter() {
      this.isOpenFilter = true
    },
    onCloseFilter() {
      this.isOpenFilter = false
    },
    onSaveFilter(data) {
      if (!_.isEmpty(data)) {
        let categories = []
        let subCategories = []
        for (let key in data) {
          if (data[key] && data[key].length > 0) {
            categories.push(key)
            subCategories = [...subCategories, ...data[key]]
          }
        }
        this.categories = categories
        this.subCategories = subCategories
      }
    },
    onOpenModalConfirmDelete() {
      this.isOpenModalConfirmDelete = true
    },
    onCloseModalConfirmDelete() {
      if (!this.isLoading) {
        this.isOpenModalConfirmDelete = false
      }
    },
    onSubmitModalConfirmDelete() {
      console.log('delete')
    },
  },
}
</script>

<style scoped>
.flip-list-move {
  transition: transform 0.5s;
}

.no-move {
  transition: transform 0s;
}

.ghost {
  opacity: 0.5;
  background: #C7F9E3;
}
</style>
