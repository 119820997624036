import { render, staticRenderFns } from "./modal-duplicate-menu-in-menu-recommendation.vue?vue&type=template&id=f8ba6d34&scoped=true&"
import script from "./modal-duplicate-menu-in-menu-recommendation.vue?vue&type=script&lang=js&"
export * from "./modal-duplicate-menu-in-menu-recommendation.vue?vue&type=script&lang=js&"
import style0 from "./modal-duplicate-menu-in-menu-recommendation.vue?vue&type=style&index=0&id=f8ba6d34&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8ba6d34",
  null
  
)

export default component.exports

/* chakra-loader */
const installChakraComponents = require("!../../../../node_modules/chakra-loader/lib/install-components.js")
installChakraComponents(component, {CFlex: require('@chakra-ui/vue').CFlex, CText: require('@chakra-ui/vue').CText, CBox: require('@chakra-ui/vue').CBox, CSpinner: require('@chakra-ui/vue').CSpinner, CCheckbox: require('@chakra-ui/vue').CCheckbox, CGrid: require('@chakra-ui/vue').CGrid, CButton: require('@chakra-ui/vue').CButton, CImage: require('@chakra-ui/vue').CImage})
